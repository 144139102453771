import { Button, Col, DatePicker, Form, Row, Select, Table } from "antd";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { exportToExcel } from "./excelUtils"; // Ensure correct path
import { searchItemsFromDb } from "../state/api";
import { AppDispatch, RootState } from "../state/store";
import React from "react";
import '../reports/stockSummary.scss';
import DashboardHeader from '../header/header';
import Footer from "../footer/footer";
import { useNavigate } from "react-router-dom";

interface MasterItem {
  pk: string;
  Item_Code: string;
  itemName: string;
  GST: number;
}

interface Item {
  id: number;
  slno: number;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  Amount: number;
  GST: string;
  GSTAmount: number;
  sk: string;
  voucherNo: string;
  category: string;
  openingStock: number;
  recieptStock: number;
  issuedStock: number;
  closingStock: number;
  cashpaid: number;
  cashreceived: number;
}

export interface searchFields {
  dateFromTo: string[];
  itemName: string;
}

const StockSummary = () => {
  const [fetchTableData, setFetchTableData] = useState<Item[]>([]);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const dispatch = useDispatch<AppDispatch>();
  const userMasterList = useSelector(
    (state: RootState) => state.main.userMasterListData
  );
  const convertDateFormat = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('/dashboard'); 
  };

  const columnsOfItems = [
    { title: "SlNo", dataIndex: "slno", key: "slno" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => convertDateFormat(date), // Convert date before displaying
    },
    { title: "Voucher No", dataIndex: "voucherNo", key: "voucherNo" },
    { title: "Category", dataIndex: "category", key: "category" },
    { title: "OpeningStock", dataIndex: "openingStock", key: "openingStock" },
    { title: "RecieptStock", dataIndex: "recieptStock", key: "recieptStock" },
    { title: "IssuedStock", dataIndex: "issuedStock", key: "issuedStock" },
    { title: "ClosingStock", dataIndex: "closingStock", key: "closingStock" },
    { title: "CashPaid", dataIndex: "cashpaid", key: "cashpaid" },
    { title: "CashReceived", dataIndex: "cashreceived", key: "cashreceived" },
  ];

  const totalRecieptStock = fetchTableData.reduce(
    (acc, item) => acc + item.recieptStock,
    0
  );
  const totalIssuedStock = fetchTableData.reduce(
    (acc, item) => acc + item.issuedStock,
    0
  );

  const handleSearch = (values: searchFields) => {
    console.log(values, 'values');
    dispatch(
      searchItemsFromDb(values, (data: Item[]) => {
        const dataWithSlNo = data.map((item, index) => {
          // Split sk to extract voucherNo
          const skParts = item.sk.split('#');
          const voucherNo = skParts[skParts.length - 1]; // Extract voucherNo
          
          const lastFiveDigits = voucherNo.slice(-5); 

          return {
            ...item,
            slno: index + 1,  // Add SlNo
            voucherNo: lastFiveDigits, // Add extracted voucherNo
          };
        });
        setFetchTableData(dataWithSlNo);
      })
    );
  };
  
  const handleDownloadExcel = () => {
    // Ensure that date range and item name are obtained from form values
    const { dateFromTo, itemName } = form.getFieldsValue();
    const [startDate, endDate] = dateFromTo || [];
    const startDateString = startDate.format("DD-MM-YYYY"); // Change format as needed
    const endDateString = endDate.format("DD-MM-YYYY");
    console.log(startDateString,'startDateString');
    console.log(endDateString,'endDateString');
    const data = {
      fetchTableData,
      startDateString,
      endDateString,
      itemName,
    };
    exportToExcel(data);
  };
  return (
    <div className="InventoryReport">
      <DashboardHeader/>
      <Button
      type="primary"
      onClick={handleBack}
      style={{
        marginTop: '20px',   
        marginBottom: '10px', 
        marginLeft: '5px',    
        marginRight: '5px',   
        padding: '10px 20px', 
      }}
    >
      Back
    </Button>
      <Form form={form} onFinish={handleSearch}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Date From To"
              name="dateFromTo"
              rules={[
                {
                  required: true,
                  message: "Please select Date From and To",
                },
              ]}
            >
              <RangePicker style={{ width: "60%" }} format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Item Name"
              name="itemName"
              rules={[
                {
                  required: true,
                  message: "Please select ItemName",
                },
              ]}
            >
              <Select placeholder="ItemName" style={{ width: 200 }}>
                {userMasterList.map((item) => (
                  <Option key={item.name} value={item.name}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button htmlType="submit">Show</Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={columnsOfItems}
        dataSource={fetchTableData}
        pagination={{ pageSize: 8, position: ["bottomLeft"] }}
        scroll={{ x: 1350 }}
      />
      <div className="inventorytotal">
        <strong>Total Receipt Stock:{totalRecieptStock}</strong>
        <strong>Total Issued Stock:{totalIssuedStock}</strong>
      </div>
      <div className="inventorybuttons">
        <Button onClick={handleDownloadExcel}>Download Excel</Button>
        <Button>Close</Button>
      </div>
      <Footer/>
    </div>
  );
};

export default StockSummary;
