import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Modal,
  Table,
  message,
  Col,
  Row,
  Popconfirm,
} from "antd";
import moment from "moment";
import SearchDocComponent from "../searchDocComponent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import {
  BASE_URL,
  fetchUserDetails,
  savePurchaseEntryData,
  saveUpdateInvoices,
  getSequenceNumber,
} from "../../state/api";
import AddUser from "../../profile/addUser";
import { DeleteFilled } from '@ant-design/icons';

interface Item {
  id: string;
  itemName: string;
  quantity: number;
  rate: number;
  uom: string;
  discount: number;
  key: string;
}

const PurchaseEntry: React.FC = () => {
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const docType = "PO"; 
  const [number, setNumber] = useState("");
  const [isAddUser, setAddUser] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSourceData, setDataSourceData] = useState<any>([]);
  const [modalData, setModalData] = useState<Item[]>([]);
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
  });
  const [userProfileData, setUserPorfileData] = useState({
    userCode: "",
    pincode: "",
    gstin: "",
    address: "",
  });
  const [isSearch, setIsSearch] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;
  const customersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.customers
  );
  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    console.log(userFormValues, "userFormValues inside useEffect");
    setDataSourceData(userFormValues.ItemListData);
  }, [isSearch]);

  
  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message); 
      }
    };

    fetchSequenceNumber();
  }, [orgId, docType, form]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleUserCancel = () => {
    setIsUserModalVisible(false);
  };
  const handleModalFormSubmit = (selectedItem: any) => {
    setDataSourceData(selectedItem.ItemListData);
    console.log(selectedItem.ItemListData, "selected Item");
    console.log(selectedItem, "selectedItem inside select");
    form.setFieldsValue({
      userCode: selectedItem.userCode,
      name: selectedItem.name,
      reference: selectedItem.Reference,
      supplierinvoiceno: selectedItem.SupplierInvoiceNo,
      supplierinvoicedate: selectedItem.supplierinvoicedate
        ? moment(selectedItem.supplierinvoicedate)
        : null,
    });
    setUserPorfileData({
      userCode: selectedItem.userCode,
      pincode: selectedItem.pincode,
      gstin: selectedItem.gstin,
      address: selectedItem.address,
    });
    setIsModalVisible(false);
    message.success("Item added successfully");
  };
  const columnsOfItems = [
    { title: "SlNo", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "hsncode", key: "hsncode" },
    { title: "Item Name", dataIndex: "name", key: "name" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Rate", dataIndex: "rate", key: "rate" },
    { title: "UOM", dataIndex: "uom", key: "uom" },
    { title: "Amount", dataIndex: "Amount", key: "Amount" },
    { title: "GST %", dataIndex: "gst", key: "gst" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    { title: "Total", dataIndex: "grossAmount", key: "grossAmount" },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => handleDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteFilled/>
        </Popconfirm>
      ),
    },
  ];
  const handleDelete = (key: React.Key) => {
    console.log(key, "key");
    const newData = dataSourceData.filter((item: Item) => item.id !== key);
    setDataSourceData(newData);
  };
  const totals = useMemo(() => {
    const totalQuantity = dataSourceData
      .reduce((sum: any, item: { quantity: any }) => sum + item.quantity, 0)
      .toFixed(2);
    const totalGross = dataSourceData
      .reduce((sum: any, item: { Amount: any }) => sum + item.Amount, 0)
      .toFixed(3);
    const totalGst = dataSourceData
      .reduce((sum: any, item: { GSTAmount: any }) => sum + item.GSTAmount, 0)
      .toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    return { totalQuantity, totalGross, totalGst, total };
  }, [dataSourceData]);

  const columsOfModalData = [
    {
      title: "Grn No",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "User code",
      dataIndex: "userCode",
      key: "userCode",
    },
    {
      title:"User Name",
      dataIndex:"name",
      key:"name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleModalFormSubmit(record)}>
          Select
        </Button>
      ),
    },
  ];
  const handleSelectItem = (record) => {
    console.log(record, "record inside select user");
    const { sk, userType, institutionSubType, user, ...restRecord } = record;
    setUserFormValues(restRecord);
    setIsUserModalVisible(false);
  };
  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Institution SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];
  const handleMasterCancel = () => {
    setIsUserModalVisible(false);
  };

  const handleSave = async (values: any) => {
    console.log(values, "values inside form");
    console.log(modalData, "modalData inside save");
    try {
      const payload = {
        pk: orgId,
        ...userProfileData,
        ...values,
        ItemListData: dataSourceData,
      };
      console.log(payload, "payLoad inside pe");
      isSearch
        ? dispatch(saveUpdateInvoices(payload))
        : dispatch(savePurchaseEntryData(payload));
    } catch (error: any) {
      message.error("Failed to save data", error);
    }
    form.resetFields();
    message.success("All data saved successfully");
  };

  const handleSelectGrn = async (record) => {
    console.log(record, "record inside select grn");
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getGrn?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getGrn?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    console.log(userDetails, "userDetails");
    setModalData(userDetails);
  };

  console.log(modalData, "modalData");
  console.log(dataSourceData, "dataSource");
  const handleAddUserClick = () => {
    setAddUser(true);
  };
  return (
    <div className="purchaseEntry">
      <SearchDocComponent
        docType={"PO"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form
        form={form}
        layout="horizontal"
        initialValues={userFormValues}
        onFinish={handleSave}
      >
        <Row gutter={16}>
          <Col span={8}>
          <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input
                placeholder="No"
                value={number}  
                readOnly={true} 
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input disabled placeholder="Customer Code" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Supplier Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please  Search by name",
                },
              ]}
            >
              <Input placeholder="Supplier Name" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Reference"
              name="reference"

            >
              <Input disabled placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice No"
              name="supplierinvoiceno"
              rules={[
                {
                  required: true,
                  message: "Please Enter Supplier Invoice No",
                },
              ]}
            >
              <Input disabled placeholder="Supplier Invoice No" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice Date"
              name="supplierinvoicedate"
              rules={[
                {
                  required: true,
                  message: "Please Enter Invoice Date",
                },
              ]}
            >
              <DatePicker disabled placeholder="Supplier Invoice Date" />
            </Form.Item>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
                <Input placeholder="User Code" />
                </Form.Item>
          </Col>
        </Row>

        <Button
          id="purchaseentry-button1"
          type="primary"
          className="ant-btn-primary"
          onClick={handleSelectGrn}
        >
          Select GRN
        </Button>
        <Button
          onClick={() => {
            setIsUserModalVisible(true);
          }}
        >
          Add User
        </Button>
        <div className="modal-wrapper">
          <Modal
            title="GRN Details of this Supplier"
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <div className="table-wrapper">
              <Table
                columns={columsOfModalData}
                dataSource={modalData}
                pagination={{ pageSize: 8, position: ["bottomLeft"] }}
                scroll={{ x: "max-content" }} // Responsive width
              />
            </div>
          </Modal>
          <Modal
          title="Select a User"
          open={isUserModalVisible}
          onCancel={handleMasterCancel}
          footer={null}
          width={900}
          style={{marginTop:'-80px'}}
       
        > 
         <div>
         <Button onClick={handleAddUserClick}>
          Add User
         </Button>
          </div>
        <Table
            dataSource={customersDetails}
            columns={columsOfUsers}
            rowKey="id"
            pagination={{ pageSize: 5 }}
            scroll={{ x: 600 }}
          />
            </Modal>
            </div>
        <Table
          columns={columnsOfItems}
          dataSource={dataSourceData}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="totals">
   <div className="total-item">
    <strong>Total Quantity:</strong>
    <span>{totals.totalQuantity}</span>
   </div>
   <div className="total-item">
    <strong>Total Gross:</strong>
    <span>{totals.totalGross}</span>
   </div>
   <div className="total-item">
    <strong>Total Gst:</strong>
    <span>{totals.totalGst}</span>
    </div>
     <div className="total-item">
    <strong>Total:</strong>
    <span>{totals.total}</span>
       </div>
      </div>
        <Button
          id="purchaseentry-button2"
          type="primary"
          className="ant-btn-primary"
          htmlType="submit"
          style={{ marginLeft: "10px" }}
        >
          Save
        </Button>
      </Form>
      <AddUser isAddUser={isAddUser} setAddUser={setAddUser}/>
    </div>
  );
};
export default PurchaseEntry;
