import { Button, Modal, Table } from "antd";
import React from "react";

const AddUserModal = ({
  isUserModalVisible,
  handleMasterCancel,
  customersDetails,
  handleSelectItem,
}) => {
  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "UserCode",
      dataIndex: "userCode",
      key: "userCode",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: " SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];
  return (
    <>
      <Modal
        title="Select a User"
        open={isUserModalVisible}
        onCancel={handleMasterCancel}
        footer={null}
        width={900}
        style={{ marginTop: "-80px" }}
      >
        <Table
          dataSource={customersDetails}
          columns={columsOfUsers}
          rowKey="id"
          pagination={{ pageSize: 5 }}
          scroll={{ x: 600 }}
        />
      </Modal>
    </>
  );
};

export default AddUserModal;
