import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "antd";
import { useSelector } from "react-redux";

const POData = ({ visible, onClose, onSelect, items }) => {
  const ItemListData = useSelector((state) => state.main.itemList);
  const [selectedKeys, setSelectedKeys] = useState([]);
  useEffect(() => {
    if (!visible) {
      setSelectedKeys([]);
    }
  }, [visible]);

  const handleSelect = (record) => {
    onSelect(record);
    onClose();
  };

  const columns = [
    { title: "Supplier Order No", dataIndex: "sk", key: "sk" },
    { title: "Supplier Name", dataIndex: "name", key: "name" },
    { title: "User Code", dataIndex: "userCode", key: "userCode" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => record.date.split("T")[0],
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelect(record)}>
          Select
        </Button>
      ),
    },
  ];

  return (
    <Modal title="Select Supplier Order" open={visible} onCancel={onClose}>
      <Table
        columns={columns}
        dataSource={items}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </Modal>
  );
};
export default POData;
