import React, { useEffect } from "react";
import { Button, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import Sales from "./salesAndpurchase/sales";
import "./salesAndpurchase/salesAndPurchase.scss";
import PurcahseTab from "./salesAndpurchase/purchaseTab";
import { fetchUserMasterData, getOrgDetails } from "./state/slices/mainSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./state/store";
import DashboardHeader from "./header/header";
import { useNavigate } from "react-router-dom";
function Invoices() {

  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;
  const sk = `items#${orgId.slice(-5)}`;
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/dashboard'); // Navigate to the dashboard route
  };

  useEffect(() => {
    dispatch(fetchUserMasterData({ id: orgId, sk }));
    dispatch(getOrgDetails(orgId));
  }, [dispatch]);
  
  return (
    <>
    
    <DashboardHeader/>
    <Tabs
      defaultActiveKey="1"
      type="card"
      className="salesAndPurchase"
      >
      <TabPane tab="Sales" key="1">
        <Sales />
      </TabPane>
      <TabPane tab="Purchase" key="2">
        <PurcahseTab />
      </TabPane>
      </Tabs>
    </>
  );
}

export default Invoices;
