import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

const SESSION_TIMEOUT_DURATION = 60 * 60 * 1000;

const SessionTimeoutHandler = ({ children }) => {
  const { signOut } = useAuthenticator();
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
  const navigate = useNavigate();

  const resetTimer = () => {
    setLastInteractionTime(Date.now());
  };

  useEffect(() => {
    const handleUserInteraction = () => {
      resetTimer();
    };
    window.addEventListener('mousemove', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);

    const checkSessionTimeout = async () => {
      if (Date.now() - lastInteractionTime > SESSION_TIMEOUT_DURATION) {
        localStorage.clear();
        await signOut(); 
        navigate("/auth"); 
      }
    };

    const interval = setInterval(checkSessionTimeout, 1000); 
    return () => {
      clearInterval(interval);
      window.removeEventListener('mousemove', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
    };
  }, [lastInteractionTime, navigate, signOut]);

  return <>{children}</>;
};

export default SessionTimeoutHandler;

