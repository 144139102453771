import React, { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import AddUser from "../profile/addUser";

const { SubMenu } = Menu;

const Sidebar: React.FC = () => {
  const [isAddUser, setAddUser] = useState<boolean>(false);

  const showAddUser = () => setAddUser(true);

  return (
    <>
      <Menu mode="inline" defaultSelectedKeys={["1"]} className="menu">
       
        <Menu.Item key="1" onClick={showAddUser}>
          Add User
        </Menu.Item>
        <Menu.Item key="3">
        <Link to="/item">Item</Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/invoices">Invoice</Link>
        </Menu.Item>
        <SubMenu key="report" title="Report">
          <Menu.Item key="5">
            <Link to="/report/invoice">Invoice Report</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="/stockSummary">Stock Summary</Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/report/aging-report">Aging Report</Link>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="8">
          <Link to="/userDetails">UserDetails</Link>
        </Menu.Item>
      </Menu>
      <AddUser
        isAddUser={isAddUser}
        setAddUser={setAddUser}
      />
    </>
  );
};

export default Sidebar;
