import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ToWords } from 'to-words';
import { orgloginDetails } from "../../state/slices/mainSlice";

export const handleBillOfSupplyPdf = (pdfData) => {
  const generateHTMLContent = (pdfData) => {
    console.log(pdfData,'pdfData inside pdf')
    const ItemListData = pdfData.ItemListData;
    const heading = pdfData.taxInvoice ? `TAX Invoice` : `BILL OF SUPPLY`;
    const toWords = new ToWords();
    let words = toWords.convert(pdfData.total);
    const tableRows = ItemListData.map(
      (item, index) => `
      <tr key="${index}">
        <td>${index + 1}</td>
        <td>${item.name}</td>
        <td>${item.rate}</td>
        <td>${item.quantity}</td>
        <td>${item.uom}</td>
        <td>${item.hsncode}</td>
        <td>${item.Amount}</td>
        <td>0</td>
        <td>${item.gst}</td>
        <td>500</td>
        <td>5000</td>
        <td>18%</td>
        <td>${item.grossAmount}</td>
      </tr>
    `
    ).join("");
    return `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Tax-Invoice-Template</title>
    <style>
        body {
            font-family: 'Arial', sans-serif;
            background-color: #fff;
            color: #333;
            border: 2px solid black;
        }

        .header {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-bottom: 3px solid black;
        }

        .headingComponent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .heading {
            font-size: 21px;
            border-bottom: 3px solid black;
            padding: 10px;
            width: 96.5vw;
            text-align: center;
            margin: 0 auto;
        }

        .subHeading li {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-weight: 530;
            font-size: 30px;
            padding: 5px;
        }

        #subHeading1 {
            font-size: 20px;
            padding: 6px;
            font-weight: 520;
        }

        .secondRow {
            display: flex;
            border-bottom: 3px solid black;
            margin: 0;
        }

        h6 {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 3px solid black;
            margin-top: 0;
            padding: 10px;
        }

        .secondRowList {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px;
            font-size: 13px;
            margin-top: -20px;
            margin-bottom: 0;
            font-weight: 500;
        }

        .secondRowList li {
            padding: 2px;
            margin: 2px;
        }

        .secondRowListDocument-Details {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px;
            margin-top: -20px;
            margin-bottom: 0;
            font-size: 10px;
        }

        .secondRowListDocument-Details li {
            padding: 4px;
            margin: 1px 0;
            font-size: 17px;
            display: block;
            width: 100%;
            box-sizing: border-box;
        }

        .secondFirstCol {
            flex: 1;
            font-size: 25px;
            font-weight: 500;
            border-right: 3px solid black;
        }

        .secondSecondCol {
            flex: 2;
            border-right: 3px solid black;
            font-size: 25px;
            font-weight: 500;
        }

        .secondThirdCol {
            flex: 1;
            font-size: 25px;
            font-weight: 500;
        }

        .thirdRow {
            margin-top: -10px;
            padding: 5px;
        }

        .thirdRow>p {
            font-size: 12px;
        }

        .thirdColList {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 0;
            margin-top: -14px;
            margin-bottom: 0;
            font-size: 10px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        th,
        td {
            border: 2px solid black;
            padding: 8px;
            text-align: left;
        }

        td {
            font-size: 13px;
            font-weight: 547;
        }

        th {
            background-color: #fff;
            font-size: 14px;
            font-weight: 700;
        }

        tr:nth-child(even) {
            background-color: #fff;
        }

        .total-row td {
            font-weight: bold;
            font-size: 15px;
            text-align: center;
            vertical-align: middle;
        }

        .Amount {
            font-weight: bold;
            font-size: 15px;
            padding: 5px;
            margin: 5px 0;
            width: 70%;
            display: block;
            box-sizing: border-box;
        }

        .amountText {
            font-size: 15px;
            font-weight: bold;
        }

        .container {
            display: flex;
        }

        .section {
            width: 48vw;
            font-size: 10px;
            margin-left: 200px;
            text-align: center;
        }

        .section h4 {
            margin-left: 10px;
        }

        .section h2,
        .section h3 {
            margin-bottom: 10px;
            font-size: 10px;
        }

        .section-row {
            display: flex;
            justify-content: space-between;
        }

        .section-col {
            width: 100%;
        }

        .two-cols {
            display: flex;
            justify-content: space-between;
        }

        .bottomRow {
            display: flex;
            justify-content: space-around;
            padding: 0;
        }

        .bottomFirstRow {
            width: 100%;
            border-top: 2px solid black;
        }

        .bottomSecondRow {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .bottomSecondRow>h4,
        strong {
            font-size: 10px;
        }

        .specBlock {
            width: 40vw;
            font-size: 10px;
            margin: 10px auto;
            text-align: center;
            padding: 10px;
        }

        .specBlock strong {
            font-size: 15px;
            padding: 1px;
            margin: 1px;
            font-weight: 500;
        }

        .mainsection {
            display: flex;
            border-bottom: 3px solid black;
            border-top: 3px solid black;
            margin: 0;
        }

        h6 {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 3px solid black;
            margin-top: 0;
            padding: 10px;
        }

        .middlesecondRowList h1 {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px;
            font-size: 13px;
            margin-top: 10px;
            margin-bottom: 0;
            font-weight: 600;
            margin-left: 100px;
        }

        .middlesecondRowList h3 {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px 10px;
            font-size: 13px;
            margin-top: 5px;
            margin-bottom: 0;
            font-weight: 600;
            margin-left: 200px;
        }

        .middlesecondRowList li {
            padding: 2px;
            margin: 2px;
        }

        .middleRowListDocument-Details {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px 10px;
            margin-top: -5px;
            margin-bottom: 0;
            font-size: 9px;
            margin-left: 30px;
        }

        .middlesecondRowListDocument-Details li {
            padding: 4px;
            margin: 1px 0;
            font-size: 17px;
            display: block;
            width: 100%;
            box-sizing: border-box;
        }

        .middlesecondFirstCol {
            flex: 1;
            font-size: 25px;
            font-weight: 500;
            border-right: 3px solid black;
        }

        .middlesecondSecondCol {
            flex: 2;
            border-right: 3px solid black;
            font-size: 10px;
            font-weight: 500;
        }

        .middlesecondSecondCol h6 {
            font-size: 15px;
            font-weight: 600;
            padding: 10px 10px;
        }

        .middlesecondThirdCol {
            flex: 2;
            font-size: 25px;
            font-weight: 500;
        }

        .middlesecondRowList {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .middlesecondRowList h6 {
            margin: 10px 0px;
            padding: 10px 10px;
            font-size: 1.5em;
            border-bottom: 2px solid #000;
        }

        .transport-details-columns {
            display: flex;
            width: 100%;
            border-bottom: none;
        }

        .column {
            flex: 1;
            border-right: 1px solid #000;

        }

        .column:last-child {
            border-right: none;
        }

        .column h6 {
            margin: 0px;
            font-weight: 900;
            padding: 10px 10px;
            font-size: 1em;
            border-bottom: 5px solid #000;
            width: 100%;
            box-sizing: border-box;
        }

        .middlesecondRowList h5 {
            font-weight: 700;
            padding: 5px 5px;
            font-size: 1.2em;
            text-align: center;
        }

        .column p {
            margin: 0;
            padding: 5px 0;
            border-bottom: 2px solid #000;
            width: 10;
            font-weight: 800;
        }
    </style>
</head>

<body>
    <div class='header'>
        <div class='headingComponent'>
            <h3 class='heading'>
                ${heading}
            </h3>
            <h3 class='subHeading'>
                <li>${pdfData.organizationName}</li>
                <li id="subHeading1">${pdfData.address}</li>
                <li id="subHeading1">${pdfData.gstin}
                </li>
            </h3>
        </div>
    </div>
    <div class='secondRow'>
        <div class='secondFirstCol'>
            <h6>Document Details</h6>
            <ul class="secondRowListDocument-Details">
                <li>Invoice Number:${pdfData.number}</li>
                <li>Invoice Date :${pdfData.date}</li>
                <li>PO No : ${pdfData.PoNo}</li>
                <li>PO Date : ${pdfData.PoDate}</li>
            </ul>
        </div>
        <div class='secondSecondCol'>
            <h6>Bill To</h6>
            <ul class="secondRowList">
                <li>Name : ${pdfData.name}</li>
                <li>Address :${pdfData.address}</li>
                <li>GST No : ${pdfData.gstin}</li>
                <li>Pan No :</li>
                <li>Buyer ID : ${pdfData.userCode}</li>
            </ul>
        </div>
        <div class='secondThirdCol'>
            <h6>Bank Details</h6>
            <ul class="secondRowList">
                <li>SAMPARKBINDHU SOLUTIONS PRIVATE LTD</li>
                <li>ccount No : 233105001472</li>
                <li>Bank : ICICI</li>
                <li>IFSC Code: ICIC0002331</li>
                <li>Branch: Hennur Road, Bengaluru</li>
            </ul>
        </div>
    </div>
    <table>
        <thead>
            <tr>
                <th>Sl. No</th>
                <th>Item Name</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>UOM</th>
                <th>Hsn code</th>
                <th>Amount</th>
                <th>Discount</th>
                <th>GST(%)</th>
                <th>CGST</th>
                <th>SGST</th>
                <th>IGST</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            ${tableRows}
            <tr class="total-row">
                <td colspan="6">Total Invoice value</td>
                <td></td>
                <td>${pdfData.totalGross}</td>
                <td></td>
                <td>${pdfData.totalGst}</td>
                <td>5450</td>
                <td>5450</td>
                <td>${pdfData.total}</td>
            </tr>
        </tbody>
    </table>
    <div class="AmountInWords">
        <p class="Amount">Rupees in Words:Rupees ${words}</p>
    </div>
    <div class='mainsection'>
        <div class='middlesecondFirstCol'>
            <div class="middleRowListDocument-Details">
                <h2>Customer Seal and Signature</h2>
            </div>
        </div>
        <div class="middlesecondSecondCol">
            <div class="middlesecondRowList">
                <h6>Transport Details</h6>
                <div class="transport-details-columns">
                    <div class="column">
                        <p>LR No:</p>
                        <p>Data 1</p>
                        <p>Data 2</p>
                    </div>
                    <div class="column">
                        <p>E-Way No:</p>
                        <p>Data 3</p>
                        <p>Data 4</p>
                    </div>
                    <div class="column">
                        <p>Transport Name:</p>
                        <p>Data 5</p>
                        <p>Data 6</p>
                    </div>
                    <div class="column">
                        <p>Vech No:</p>
                        <p>Data 5</p>
                        <p>Data 6</p>
                    </div>
                </div>
                <h5>Jurisdiction - Bengaluru</h5>
            </div>
        </div>
        <div class='middlesecondThirdCol'>
            <div class="middlesecondRowList">
                <h1>FOR SAMPARKBINDHU SOLUTIONS PRIVATE LIMITED</h1>
                <h3>Authorised Signatory</h3>
            </div>
        </div>
    </div>
    <div class="bottomRow">
        <div class="specBlock">
            <div>
                <strong>Registered Office Address : #617, "SHIVAM", ROYAL PARK RESIDENCY, J P NAGAR 9TH PHASE BENGALURU
                    , PIN:- 560062</strong>
                <strong>CIN# U72900KA2020PTC13512, Phone:+919845711600</strong>
                <strong>Visit Website: https://SamparkBindhu.in
                </strong>
            </div>
        </div>
    </div>
</body>
</html>
        `;
  };
  const htmlContent = generateHTMLContent(pdfData);

  const blob = new Blob([htmlContent], { type: "text/html" });
  const url = URL.createObjectURL(blob);

  const iframe = document.createElement("iframe");
  iframe.style.width = "320mm";
  iframe.style.height = "297mm";
  document.body.appendChild(iframe);

  iframe.onload = async () => {
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write(htmlContent);
    iframeDocument.close();

    const canvas = await html2canvas(iframeDocument.body, {
      scale: 2, // Increase scale to improve quality
      width: iframeDocument.body.scrollWidth,
      height: iframeDocument.body.scrollHeight,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(
      imgData,
      "PNG",
      0,
      0,
      210,
      (210 * canvas.height) / canvas.width
    );

    // Open PDF in new tab
    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);

    // Trigger download
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "form-data.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    document.body.removeChild(iframe);
    URL.revokeObjectURL(url);
    URL.revokeObjectURL(pdfUrl);
  };

  iframe.src = url;
};
