import React, { useState } from "react";
import { Layout, Button, Image, Drawer, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import "./landingpageheader.scss";
import Logo from "../assets/logo.jpg";

const { Header } = Layout;

const LandingPageHeader: React.FC = () => {
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleSigninClick = () => {
    navigate("/auth");
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Header className="landing-page-header">
      <div className="header-content">
        <div className="logo">
          <Image src={Logo} preview={false} alt="Logo" />
        </div>
        <div className="nav-links">
          <Button className="nav-Btn" href="#home">
            Home
          </Button>
          <Button className="nav-Btn" href="#aim">
            Aim
          </Button>
          <Button className="nav-Btn" href="#solutions">
            Solutions
          </Button>
        </div>
        <Button className="signin-button" onClick={handleSigninClick}>
          Sign In
        </Button>
        <div className="Mobile-device">
          <Button
            className="mobile-menu-button"
            icon={<MenuOutlined />}
            onClick={showDrawer}
          />

          <Drawer
            title="Welcome to EngageAll"
            placement="right"
            onClose={closeDrawer}
            open={drawerVisible}
            className="drawer"
          >
            <Menu mode="vertical">
              <Menu.Item key="1">
                <Button className="nav-Btn" href="#home">
                  Home
                </Button>
              </Menu.Item>
              <Menu.Item key="2">
                <Button className="nav-Btn" href="#aim">
                  Aim
                </Button>
              </Menu.Item>
              <Menu.Item key="3">
                <Button className="nav-Btn" href="#solutions">
                  Solutions
                </Button>
              </Menu.Item>
              <Menu.Item key="4" onClick={handleSigninClick}>
                <Button className="nav-Btn">Sign In</Button>
              </Menu.Item>
            </Menu>
          </Drawer>
        </div>
      </div>
    </Header>
  );
};

export default LandingPageHeader;
