import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Table,
  Popconfirm,
  message,
  Select,
  DatePicker,
} from "antd";
import POData from "./podata";
import { useDispatch, useSelector } from "react-redux";
import { DeleteFilled } from '@ant-design/icons';
import SearchDocComponent from "../searchDocComponent";
import {
  BASE_URL,
  fetchUserDetails,
  saveGrnEntryData,
  saveUpdateInvoices,
  getSequenceNumber
} from "../../state/api";
import { AppDispatch, RootState } from "../../state/store";
import { handleGrnPdf } from "../salesInvoices/generateGrnPdf";

interface Item {
  ItemListData: any[];
  orderNo: any;
  pk: string;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
}

const { Option } = Select;

const GRNEntry: React.FC = () => {
  const [number, setNumber] = useState("");
  const docType = "GRN";
  const [date, setDate] = useState("");
  const [reference, setReference] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierInvoiceDate, setSupplierInvoiceDate] = useState("");
  const [supplierInvoiceNo, setSupplierInvoiceNo] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPO, setSelectedPO] = useState<any[]>([]);
  const [itemSelected, setItemSelected] = useState<any>([]);
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
  });
  const [userDetails, setUserDetails] = useState<any>(null);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [pdfData, setPdfData]: any  = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const orgId: string = localStorage.getItem("orgId")!;
  const orgProfileData = useSelector((state: RootState) => state.main.orgProfileDetails);

  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    console.log(userFormValues, "userFormValues inside useEffect");
    setItemSelected(userFormValues.ItemListData);
  }, [isSearch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message); 
      }
    };

    fetchSequenceNumber();
  }, [orgId, docType, form]);
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setSelectedPO([]);
  };

  const handleSelectPO = (selectedKeys: React.Key[], selectedItems: Item[]) => {
    console.log(selectedItems, "selectedItems inside select");
    setSelectedPO(selectedItems);
    const items = selectedItems.flatMap((item) => item.ItemListData); // Assuming 'items' is an array inside each Item object
    setItemSelected(items);
    setIsModalVisible(false);
  };

  const cancel = () => {
    message.error("Click on No");
  };

  const handleInputChange = (e: any, record: any, column: any) => {
    const value = column === "Uom" ? e.target.value : Number(e.target.value);

    const newData = itemSelected.map((item: any) =>
      item.key === record.key
        ? {
          ...item,
          [column]: value,
          Amount:
            column !== "Uom"
              ? column === "quantity"
                ? value * item.rate
                : item.quantity * value
              : item.Amount,
          GSTAmount:
            column !== "Uom"
              ? column === "quantity"
                ? (value * item.rate * item.GST) / 100
                : (item.quantity * value * item.GST) / 100
              : item.GSTAmount,
        }
        : item
    );
    setItemSelected(newData);
  };
  const columns = [
    { title: "Sl No", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "hsncode", key: "hsncode" },
    { title: "Item Name", dataIndex: "name", key: "name" },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, record: any) => renderInput(text, record, "quantity"),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text: any, record: any) => renderInput(text, record, "rate"),
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
      render: (text: any, record: any) => renderInput(text, record, "Uom"),
    },
    { title: "Amount", dataIndex: "Amount", key: "Amount" },
    { title: "GST%", dataIndex: "gst", key: "gst" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    { title: "Total", dataIndex: "grossAmount", key: "grossAmount" },

    {
      title: "Action",
      key: "action",
      render: (text: string, record: Item) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => handleDelete(record)}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
        <DeleteFilled/>
        </Popconfirm>
      ),
    },
  ];

  const totals = useMemo(() => {
    const totalQuantity = itemSelected
      .reduce((sum: any, item: { quantity: any }) => sum + item.quantity, 0)
      .toFixed(2);
    const totalGross = itemSelected
      .reduce((sum: any, item: { Amount: any }) => sum + item.Amount, 0)
      .toFixed(3);
    const totalGst = itemSelected
      .reduce((sum: any, item: { GSTAmount: any }) => sum + item.GSTAmount, 0)
      .toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);
    return { totalQuantity, totalGross, totalGst, total };
  }, [itemSelected]);

  const renderInput = (text: any, record: any, column: any) => (
    <Input
      value={text}
      onChange={(e) => handleInputChange(e, record, column)}
    />
  );

  const handleDelete = (record: Item) => {
    const newData = [...itemSelected];
    const index = newData.findIndex((item) => record.pk === item.pk);
    if (index > -1) {
      newData.splice(index, 1);
      setItemSelected(newData);
    }
  };

  console.log(selectedPO, "selectedPO");
  const handleSave = (values: any) => {
    console.log("Form Values:", values);
    const userCode = selectedPO?.[0]?.userCode || "";
    const address = selectedPO?.[0]?.address || "";
    const pincode = selectedPO?.[0]?.pincode || "";
    const gstin = selectedPO?.[0]?.gstin || "";
    const phone = selectedPO?.[0]?.phone || "";
    const payload = {
      pk: orgId,
      ...userFormValues,
      ...values,
      ...(isSearch && { ...userFormValues }),
      ...(!isSearch && { userCode, address, pincode, gstin }),
      ItemListData: itemSelected,
      phone,
    };
    console.log("Saving all details:", payload);
    console.log(totals, 'totals')
    isSearch
      ? dispatch(saveUpdateInvoices(payload))
      : dispatch(saveGrnEntryData(payload, setPdfData));
    setShowPrintPdf(true);
    form.resetFields();
    message.success("All data saved successfully");
  };

  const printPdf = () => {
    console.log("im here items", pdfData);
    const payload2 = {
      ...pdfData, 
      ...orgProfileData, 
      totals
    };
    handleGrnPdf(payload2);
    form.resetFields();
  };

  const handleSelectSup = async () => {
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getSupplierPoData?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getSupplierPoData?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    console.log(userDetails, "userDetails");
    setUserDetails(userDetails);
  };

  return (
    <div className="grnEntry">
      <SearchDocComponent
        docType="GRN"
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form form={form} initialValues={userFormValues} onFinish={handleSave}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input
                placeholder="No"
                value={number}  
                readOnly={true} 
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: "Please select the date" }]}
            >
              <DatePicker format="DD-MM-YYYY HH:mm:ss" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Reference"
              name="Reference"
            >
              <Input
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                placeholder="Reference"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Supplier Name"
              name="name"
              rules={[
                { required: true, message: "Please input the supplier name" },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: "Please enter name only",
                },
              ]}
            >
              <Input
                value={supplierName}
                onChange={(e) => setSupplierName(e.target.value)}
                placeholder="Supplier Name"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice Date"
              name="supplierinvoicedate"
              rules={[
                { required: true, message: "Please input the invoice date" },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="SupplierInvoiceNo"
              label="Supplier Invoice No."
              rules={[
                { required: true, message: "Please input the invoice number" },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please enter invoice number only",
                },
              ]}
            >
              <Input
                value={supplierInvoiceNo}
                onChange={(e) => setSupplierInvoiceNo(e.target.value)}
                placeholder="Invoice No"
              />
            </Form.Item>
          </Col>
        
        </Row>
        <Button type="primary" id="select-po" onClick={handleSelectSup}>
          Select P.O.
        </Button>
  
        {isModalVisible && userDetails && (
          <POData
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            onSelect={handleSelectPO}
            items={userDetails.items}
          />
        )}
        <Table
          columns={columns}
          dataSource={itemSelected}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="totals">
   <div className="total-item">
    <strong>Total Quantity:</strong>
    <span>{totals.totalQuantity}</span>
   </div>
   <div className="total-item">
    <strong>Total Gross:</strong>
    <span>{totals.totalGross}</span>
   </div>
   <div className="total-item">
    <strong>Total Gst:</strong>
    <span>{totals.totalGst}</span>
    </div>
     <div className="total-item">
    <strong>Total:</strong>
    <span>{totals.total}</span>
       </div>
      </div>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button type="primary" disabled={!showPrintPdf} onClick={printPdf}>
          Download Pdf
        </Button>
      </Form>
    </div>
  );
};
export default GRNEntry;
