import React, { useState } from "react";
import { Button, Modal, Table, Typography } from "antd";
import {
  BASE_URL,
  fetchPaymentDetails,
  fetchUserDetails,
} from "../../state/api";
import { isEmpty } from "lodash";

const { Text } = Typography;

const PaymentDisplay = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPaymentReciept, setIsPaymentReciept] = useState([]);
  const [isSalesReturn, setIsSalesReturn] = useState([]);
  const orgId: string = localStorage.getItem("orgId")!;
  const [modalSrData, setModalSrData] = useState([]);

  const handleModalFormSubmit = async (record: any) => {
    const paymentDisplays = record.paymentNumber;
    const salesInvoiceNumbers = paymentDisplays.map((item: any) => item);
    const payload = {
      pk: orgId,
      salesInvoiceNumbers: salesInvoiceNumbers,
    };

    const payDetails = await fetchPaymentDetails(payload);
    const salesReturnData = payDetails?.filter((item: any) =>
      item.sk.includes("SR")
    );
    const paymentRecieptData = payDetails?.filter((item: any) =>
      item.sk.includes("PAYR")
    );
    setIsPaymentReciept(paymentRecieptData);
    setIsSalesReturn(salesReturnData);
    setIsModalVisible(false);
  };

  const handleSelectBs = async () => {
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getSupplierPoData?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getSales?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    const filteredData = userDetails.filter((item: any) =>
      Array.isArray(item.paymentNumber)
    );

    // Set the filtered data to modal state
    setModalSrData(filteredData);
  };

  const columnsOfItemsModal = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Invoice Number",
      dataIndex: "sk",
      key: "sk",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: any, record: any) => record.date.split("T")[0],
    },
    { title: "UserCode", dataIndex: "userCode", key: "userCode" },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Button type="primary" onClick={() => handleModalFormSubmit(record)}>
          Select
        </Button>
      ),
    },
  ];

  const displayColumns = [
    {
      title: "Sales invoice number",
      dataIndex: "salesInvoiceNo",
      key: "salesInvoiceNo",
    },
    {
      title: "Reciept Type",
      dataIndex: "salesInvoiceNo",
      key: "salesInvoiceNo",
      render: (text: any, record: any) =>
        record.salesInvoiceNo.includes("BOS")
          ? "Bill Of Supply"
          : "Tax Invoice",
    },
    {
      title: "Reciept No.",
      dataIndex: "sk",
      key: "sk",
    },
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Amount Paid",
      dataIndex: "amountPaid",
      key: "amountPaid",
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
    {
      title: "Remaining Amount",
      dataIndex: "remainingAmount",
      key: "remainingAmount",
    },
  ];

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="paymentDisplay">
      <Button onClick={handleSelectBs} type="primary">
        Select Invoice
      </Button>
      <Modal
        title="Search For Invoice"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Table
          columns={columnsOfItemsModal}
          dataSource={modalSrData}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: "max-content" }}
        />
      </Modal>
      <Text>Payment Reciept details</Text>
      <Table
        columns={displayColumns}
        dataSource={isPaymentReciept}
        pagination={{ pageSize: 8, position: ["bottomLeft"] }}
        // scroll={{ x: "max-content" }}
      />
      <Text>Sales Return Details</Text>
      <Table
        columns={displayColumns}
        dataSource={isSalesReturn}
        pagination={{ pageSize: 8, position: ["bottomLeft"] }}
        // scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default PaymentDisplay;
