import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  addUserProfileFun,
  fetchUserMasterDataFun,
  getOrgDetailsFun,
  getUserProfileFun,
  saveUserMasterDataFun,
  updateOrgProfile,
} from "../api";
import { dp } from "../../helper";

const isDev = false;

interface Item {
  id: string;
  itemName: string;
  quantity: number;
}

export const updateOrgProfileThunk = createAsyncThunk(
  "profile/updateOrgProfile",
  async (values: any, thunkAPI) => {
    try {
      const result = await updateOrgProfile(values);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrgDetails = createAsyncThunk<any, string>(
  "getOrgDetails",
  async (id: string) => {
    dp(":::Fetching FO Details::::");
    const res = await getOrgDetailsFun(id);
    if (res === undefined) throw new Error("Error while fetching fo details");
    dp("fo data fetched success");
    console.log(res.Item, getOrgDetails);
    return res.Item;
  }
);

export const addUserProfileThunk = createAsyncThunk(
  "dashBoard/updateOrgProfile",
  async (values: any, thunkAPI) => {
    try {
      const result = await addUserProfileFun(values);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserDetails = createAsyncThunk<any, string>(
  "getUserDetails",
  async (id: string) => {
    dp(":::Fetching FO Details::::");
    const res = await getUserProfileFun(id);
    if (res === undefined) throw new Error("Error while fetching fo details");
    dp("fo data fetched success");
    console.log(res, " result inside getUserDetails");
    return res;
  }
);

export const fetchUserMasterData = createAsyncThunk<
  any,
  { id: string; sk: string }
>("fetchUserMasterData", async ({ id, sk }) => {
  dp(":::Fetching FO Details::::");
  const res = await fetchUserMasterDataFun(id, sk);
  if (res === undefined) throw new Error("Error while fetching fo details");
  dp("fo data fetched success");
  console.log(res, " result inside getUserDetails");
  return res;
});

export const saveUserMasterData = createAsyncThunk(
  "item/saveUserMasterData",
  async (values: any, thunkAPI) => {
    try {
      const result = await saveUserMasterDataFun(values);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const loginDetailsType: any[] = [];

export interface User {
  email: string;
  email_verified: string;
  sub: string;
  "custom:is_New_Client": string;
  // Other properties...
}

export interface OrgProfile {
  organizationName: string;
  email: string;
  gstin: string;
  phoneNumber: string;
  address: string;
  pincode: string;
  profileImage: string;
  orgId: string;
  orgEmail: string;
}

interface Item {
  key: string;
  name: string;
  category: string;
  variety: string;
  grade: string;
  gst: string;
  hsncode: string;
  uom: string;
}

interface MainState {
  retryFO: number;
  masterListData: any[]; // Replace `any` with specific type if possible
  itemList: any[]; // Replace `any` with specific type if possible
  loginDetails: User[]; // Use the User type here
  orgProfileData: boolean;
  orgProfileDetails: any;
  userProfileData: boolean;
  userProfileDetails: any;
  userMasterListData: any[];
  loading: boolean;
  isEmpty: boolean;
  changedItems: any[];
}

const initialState: MainState = {
  retryFO: 0,
  masterListData: [],
  itemList: [],
  changedItems: [],
  loginDetails: [],
  orgProfileData: false,
  userProfileData: false,
  orgProfileDetails: {
    organizationName: "",
    email: "",
    gstin: "",
    phoneNumber: "",
    address: "",
    pincode: "",
    profileImage: "",
    orgId: "",
    orgEmail: "",
  },
  userProfileDetails: {
    customers: [],
    suppliers: [],
  },
  userMasterListData: [],
  loading: false,
  isEmpty: true, // Start with true, indicating no data
};

const mainSlice = createSlice({
  name: "main",
  initialState: initialState,
  reducers: {
    orgloginDetails: (state, action: PayloadAction<User[]>) => {
      state.loginDetails = action.payload;
    },
    updateMasterList: (state, action) => {
      state.masterListData = action.payload;
    },
    addItem: (state, action) => {
      console.log("im here inside reducer");
      const newItem = { ...action.payload, id: uuidv4() };
      console.log("newItem", newItem);
      state.itemList = [...state.itemList, newItem];
      console.log("state.itemList", state.itemList);
    },
    deleteItem: (state, action) => {
      state.itemList = state.itemList.filter(
        (item: Item) => item.id !== action.payload
      );
    },
    removeAllItems: (state) => {
      state.itemList = [];
    },
    addTableData: (state, action) => {
      if (!state.userMasterListData) {
        state.userMasterListData = [];
      }

      state.userMasterListData.push(action.payload);
      state.changedItems = state.changedItems || []; // Initialize if undefined
      state.changedItems.push({ ...action.payload, changeType: "add" });
    },
    updateTableData: (state, action) => {
      const index = state.userMasterListData.findIndex(
        (item) => item.key === action.payload.key
      );

      if (index !== -1) {
        state.userMasterListData[index] = action.payload;

        // Add or update in changedItems
        state.changedItems = state.changedItems || [];
        const changedIndex = state.changedItems.findIndex(
          (item) => item.key === action.payload.key
        );

        if (changedIndex === -1) {
          state.changedItems.push({ ...action.payload, changeType: "update" });
        } else {
          state.changedItems[changedIndex] = {
            ...action.payload,
            changeType: "update",
          };
        }
      }
    },
    deleteTableData: (state, action) => {
      state.userMasterListData = state.userMasterListData.filter(
        (item) => item.key !== action.payload
      );

      // Track deletion
      state.changedItems = state.changedItems || [];
      state.changedItems.push({ key: action.payload, changeType: "delete" });
    },
    clearChangedItems: (state) => {
      state.changedItems = [];
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getOrgDetails.rejected, (state) => {
      state.retryFO++;
    });
    builder.addCase(getOrgDetails.fulfilled, (state, action) => {
      state.orgProfileData = true;
      state.orgProfileDetails.organizationName =
        action.payload["organizationName"];
      state.orgProfileDetails.email = action.payload["email"];
      state.orgProfileDetails.gstin = action.payload["gstin"];
      state.orgProfileDetails.phoneNumber = action.payload["phoneNumber"];
      state.orgProfileDetails.address = action.payload["address"];
      state.orgProfileDetails.pincode = action.payload["pincode"];
      state.orgProfileDetails.profileImage = action.payload["profileImage"];
      state.orgProfileDetails.orgId = action.payload["orgId"];
      state.orgProfileDetails.orgEmail = action.payload["orgEmail"];
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.retryFO++;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.userProfileData = true;
      state.userProfileDetails.customers = action.payload["customers"];
      state.userProfileDetails.suppliers = action.payload["suppliers"];
    });
    builder.addCase(fetchUserMasterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserMasterData.fulfilled, (state, action) => {
      state.loading = false;
      state.userMasterListData = action.payload[0]?.items || [];
      state.isEmpty = (action.payload[0]?.items?.length ?? 0) === 0; // If no items are returned, it's empty
    });
    builder.addCase(fetchUserMasterData.rejected, (state) => {
      state.loading = false;
      state.isEmpty = true; // If the fetch fails, assume no data
    });
  },
});
export const {
  orgloginDetails,
  updateMasterList,
  addItem,
  deleteItem,
  removeAllItems,
  updateTableData,
  deleteTableData,
  addTableData,
  clearChangedItems
} = mainSlice.actions;
export default mainSlice.reducer;
