import React from "react";
import { Card, Row, Col, Typography } from "antd";
import "./landingPage.scss";
const { Title, Paragraph } = Typography;
import Group from "../assets/Group.png";
import idea from "../assets/idea.png";
const Solutions: React.FC = () => {
  return (
    <div id="solutions" className="solutions-container">
      <div className="image-container">
        <img src={idea} alt="logo image" />
      </div>
      <Title className="solnTitle">
        <span>
          <span>O</span>ur
        </span>{" "}
        Solution
      </Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <Card className="solution-card" hoverable>
            <Title level={3} className="solution-card-title"></Title>
            <p className="solnText">
              <span>"Agri Commodities Inventory Management Software"</span> This
              is an Ideal B2B CRM platform for Rural Industries (FPOs,
              Societies, Stockists, Godowns, Wholesalers, retailers, Logistics,
              etc) It handles the everyday needs of bulk inventory across
              multiple warehouses, godowns, and stores. Ensuring timely stock
              replenishment and inventory turnover, coupled with data-driven
              strategies for needed reports providing insights for efficiency
              and profitability It is driven by the ease of use approach proving
              Management of Godowns/Stores, Stocks, and Orders with the needed
              GST compliance
            </p>
          </Card>
          <img src={Group} />
        </Col>
      </Row>
    </div>
  );
};

export default Solutions;
