import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "antd";
import "./landingPage.scss";
import LandingImage from "../assets/Landingimage.jpg";
import Footer from "../footer/footer";
import LandingPageHeader from "../landingpageheader/landingpageHeader";
import Solutions from "./solutions";
import Aim from "./aim";
import Banner from "./banner";
const LandingPage = () => {
  const navigate = useNavigate();
  const handleRegister = () => {
    navigate("./auth");
  };
  return (
    <div className="landing-page" id="home">
      <header className="header-landingpage">
        <LandingPageHeader />
      </header>
      <Banner />
      <Row className="content-section" gutter={1}>
        <Col xs={24} md={12} className="text-section">
          <h2>Agri.Inventory.CRM</h2>
          <p>
            An B2B CRM platform for Rural Industries and Farmer Organizations to
            Handle the everyday needs of Bulk inventory across Warehouses,
            Stores, and Gowdowns
          </p>
          <Button className="register-button" onClick={handleRegister}>
            Register Today
          </Button>
        </Col>
        <Col xs={24} md={12} className="image-section">
          <img src={LandingImage} alt="Landing" />
        </Col>
      </Row>
      <Aim />
      <Solutions />
      <Footer />
    </div>
  );
};
export default LandingPage;
