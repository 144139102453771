import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "./barChart.scss";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import jsonData from "../data/data.json";
interface DataItem {
  month: string;
  price: number;
  category: string;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
}
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);
const BarChart = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>("Pepper");
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  useEffect(() => {
    const dataForCategory = (jsonData as DataItem[]).filter(
      (item) => item.category === selectedCategory
    );
    setFilteredData(dataForCategory);
  }, [selectedCategory]);
  const chartData: ChartData = {
    labels: filteredData.map((item) => item.month),
    datasets: [
      {
        label: "Price",
        data: filteredData.map((item) => item.price),
        backgroundColor: "lightblue",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Price",
        },
      },
    },
  };
  return (
    <>
      <div className="barchart">
        <div className="leftbarchart">
          <h1>Total Transaction Value</h1>
          <div className="chart-container">
            <Bar data={chartData} options={options} />
          </div>
        </div>
        <div className="rightbarchart">
          <h1>Total Per Produce Value</h1>
          <div className="chart-container">
            <Bar data={chartData} options={options} />
          </div>
        </div>
      </div>
      <select
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
        className="select"
      >
        <option value="Pepper">Pepper</option>
        <option value="Cashew">Cashew</option>
      </select>
    </>
  );
};
export default BarChart;
