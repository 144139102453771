import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Table,
  Modal,
  Select,
  InputNumber,
  Radio,
  message
} from "antd";
import SearchDocComponent from "../searchDocComponent";
import { AppDispatch, RootState } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../state/slices/mainSlice";
import AddUser from "../../profile/addUser";

interface PropsItem {
  taxInvoice: boolean;
}

interface ItemType {
  pk: string;
  itemName: string;
  sk: string;
  Amount: number;
  Category: string;
  Grade: string;
  grossAmount: number;
  GST: string;
  GSTAmount: number;
  HSN_Code: string;
  id: string;
  Item_Code: number;
  Produce: string;
  quantity: number;
  quantityInBags: string;
  rate: string;
  Source_Area: string;
  Uom: string;
  Variety: string;
}
const PaymentReceipt = (props: PropsItem) => {
  const { taxInvoice } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [formTwo] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;
  const [pdfData, setPdfData] = useState("");
  const [isAddUser, setAddUser] = useState<boolean>(false);
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
    name: "",
    taxInvoice: "",
    total: 0,
    remainingAmount: 0,
  });
  const [isSearch, setIsSearch] = useState(false);
  const customersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.customers
  );
  const saveAllDetails = async (values: any) => {
    console.log(userFormValues.customerCode, "customerCode");
    console.log(userFormValues.zip, "zip");
    console.log(values, "values");
    const remainingAmount = userFormValues.remainingAmount
      ? userFormValues.remainingAmount - values.amountPaid
      : userFormValues.total - values.amountPaid;
    console.log(userFormValues.remainingAmount, 'userFormValues.remainingAmount');
    console.log(userFormValues.total, 'userFormValues.total')
    console.log(remainingAmount, "remainingAmount");
    const payLoad = {
      ...values,
      ...userFormValues,
      total: userFormValues.total,
      remainingAmount,
    };
    console.log(payLoad, "payLoad");
    form.resetFields();
    setPdfData(payLoad);
    setIsSearch(false);
    message.success("All data saved successfully");
  };
  const printPdf = () => {
    console.log("im here pdf items", pdfData);
    form.resetFields();
  }

  useEffect(() => {
    dispatch(getUserDetails(orgId));
  }, [dispatch]);


  const handleCancel = () => {
    formTwo.resetFields();
    setIsModalVisible(false);
  };

  const handleMasterCancel = () => {
    setIsUserModalVisible(false);
  };

  const handleAddUserClick = () => {
    setAddUser(true);
  };

  const handleSelectItem = (record) => {
    console.log(record, "record inside select user");
    const { sk, userType, institutionSubType, user, ...restRecord } = record;
    form.setFieldsValue(restRecord);
    setUserFormValues(restRecord);
    setIsUserModalVisible(false);
  };

  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: " SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];

  console.log(pdfData, "inside payment");
  return (
    <div className="paymentReceipt">
      <SearchDocComponent
        docType={"BOSTI"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form layout="horizontal" onFinish={saveAllDetails} form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                { required: true, message: "Please enter the Phone Number!" },
              ]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sales Invoice No"
              name="sk"
              rules={[
                {
                  required: true,
                  message: "Please enter the Sales Invoice No!",
                },
              ]}
            >
              <Input placeholder="Enter Sales Invoice No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="PaymentDate"
              rules={[{ required: true, message: "Please select the Date!" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Sales Invoice Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the Sales Invoice Date!",
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="PO No"
              name="PoNo"
              rules={[{ required: true, message: "Please enter the PO No!" }]}
            >
              <Input placeholder="Enter PO No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Name"
              name="name"
              rules={[
                { required: true, message: "Please enter the Supplier Name!" },
              ]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Name"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Amount Paid"
              name="amountPaid"
              rules={[
                { required: true, message: "Please enter the Amount" },
              ]}
            >
              <InputNumber placeholder="Enter Amount Paid " />
            </Form.Item>
            <Button
              onClick={() => {
                setIsUserModalVisible(true);
              }}
            >
              Add User
            </Button>
            <Modal
              title="Select a User"
              open={isUserModalVisible}
              onCancel={handleMasterCancel}
              footer={null}
              width={900}
              style={{marginTop:'-80px'}}
            >
              <div>
                <Button onClick={handleAddUserClick}>Add User</Button>
              </div>
              <Table
                dataSource={customersDetails}
                columns={columsOfUsers}
                rowKey="id"
                pagination={{ pageSize: 5 }}
                scroll={{ x: 1350 }}
              />
            </Modal>
            
          </Col>
        </Row>
        <div className="totalamount">
          <strong>Total Amount:{userFormValues?.total}</strong>
          <strong>
            Remaining Amount:
            {userFormValues.remainingAmount || userFormValues?.total}
          </strong>
        </div>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={printPdf} style={{ width: '150px' }}>Download Receipt</Button>
      </Form>
      <AddUser isAddUser={isAddUser} setAddUser={setAddUser}/>
    </div>
  );
};
export default PaymentReceipt;


