import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Table,
  Popconfirm,
  Modal,
  message,
  PopconfirmProps,
  Row,
  Col,
  Radio,
} from "antd";
import SearchDocComponent from "../searchDocComponent";
import {
  addItem,
  getUserDetails,
  removeAllItems,
} from "../../state/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import {
  savePurchaseReturn,
  saveUpdateInvoices,
  getSequenceNumber,
} from "../../state/api";
import { handlePurchaseReturnPdf } from "./generatePurchaseReturnPdf";
import { DeleteFilled } from "@ant-design/icons";
import { deleteItem } from "../../state/slices/mainSlice";
import AddUser from "../../profile/addUser";
import CropItemsTable from "../cropItemsTable";
import { useTotals } from "../invoicesTotals";
import SettingDateComp from "../settingDateComp";
const { Option } = Select;
interface ItemType {
  key: string;
  name: string;
  category: string;
  variety: string;
  grade: string;
  gst: string;
  hsncode: string;
  uom: string;
}

const PurchaseReturn = () => {
  const [form] = Form.useForm();
  const [number, setNumber] = useState("");
  const orgId: string = localStorage.getItem("orgId")!;
  const docType = "PR";
  const [isAddUser, setAddUser] = useState<boolean>(false);
  const [itemName, setItemName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uomValue, setUomValue] = useState("");
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [pdfData, setPdfData]: any = useState("");
  const [showAddItem, setShowAddItem] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [formTwo] = Form.useForm();
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    zip: "",
    gstin: "",
    customerCode: "",
    ItemListData: [],
  });
  const [bsDate, setBsDate] = useState("");
  const orgProfileData = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  const dispatch = useDispatch<AppDispatch>();
  const suppliersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.suppliers
  );
  const ItemListData = useSelector((state: RootState) => state.main.itemList);
  const userMasterList = useSelector(
    (state: RootState) => state.main.userMasterListData
  );
  const { totals, totalsJSX } = useTotals(ItemListData);
  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    if (isSearch) {
      userFormValues.ItemListData.forEach(
        (item: any) =>
          !ItemListData.some((existingItem) => existingItem.key === item.key) &&
          dispatch(addItem(item))
      );
    }
  }, [isSearch]);

  useEffect(() => {
    dispatch(getUserDetails(orgId));
  }, [dispatch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSequenceNumber();
  }, [orgId, docType, form]);
  const addItemArray = [
    {
      name: "name",
      required: true,
      label: "Item Name",
      isDropdown: true,
    },
    {
      name: "quantity",
      required: true,
      label: "Quantity",
      rules: [
        {
          required: true,
          message: "Please input Quantity!",
        },
        {
          pattern: /^\d{1,5}(\.\d{1,2})?$/,
          message: "Please enter quantity up to 5 digits only",
        },
      ],
    },
    {
      name: "QtyInBags",
      required: true,
      label: "Qty In Bags",
    },
    {
      name: "rate",
      required: true,
      label: "Rate",
      rules: [
        {
          required: true,
          message: "Please input Rate!",
        },
        {
          pattern: /^\d{1,8}(\.\d{1,3})?$/,
          message: "Please enter the rate up to 8",
        },
      ],
    },
  ];

  const handleSelectItem = (record) => {
    const { sk, userType, institutionSubType, user, ...restRecord } = record;
    setUserFormValues(restRecord);
    setIsUserModalVisible(false);
  };

  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Institution SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];

  const handleChange = (value: string) => {
    setUomValue(value);
  };

  const handleSaveForm2 = (values: any) => {
    const itemFromList: any = userMasterList.find(
      (item: ItemType) => item.name === values.name
    );
    const itemAmount = values.quantity * parseFloat(values.rate);
    const GstAmount = (parseFloat(itemFromList?.gst) * itemAmount) / 100;
    const grossAmount = GstAmount + itemAmount;
    if (itemFromList) {
      const mergedItem = {
        ...itemFromList,
        slno: ItemListData.length + 1,
        quantity: parseFloat(values.quantity),
        Amount: itemAmount,
        quantityInBags: values.QtyInBags,
        rate: values.rate,
        GSTAmount: GstAmount,
        grossAmount: grossAmount,
        uom: values.uom,
      };
      dispatch(addItem(mergedItem));
      setShowAddItem(true);
      message.success("Item Added Successfully");
      formTwo.resetFields();
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    formTwo.resetFields();
    setIsModalVisible(false);
  };

  const handleMasterCancel = () => {
    setIsUserModalVisible(false);
  };

  const saveAllDetails = async (values: any) => {
    const payLoad = {
      ...userFormValues,
      ...values,
      ItemListData,
      ...totals,
      pk: orgId,
      date: bsDate,
    };
    form.resetFields();
    setPdfData({ totals, ...payLoad });
    isSearch
      ? dispatch(saveUpdateInvoices(payLoad))
      : dispatch(savePurchaseReturn(payLoad, setPdfData));
    setShowPrintPdf(true);
    dispatch(removeAllItems());
    setIsSearch(false);
    dispatch(removeAllItems());
    message.success("All data saved successfully");
  };

  const printPdf = () => {
    const payload2 = {
      ...pdfData, // Existing user form values
      orgProfileData, // Organization details fetched from Redux
    };
    handlePurchaseReturnPdf(payload2);
    form.resetFields();
  };

  const handleAddUserClick = () => {
    setAddUser(true);
  };
  return (
    <div className="purchaseReturn">
      <SearchDocComponent
        docType="PR"
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form
        form={form}
        initialValues={userFormValues}
        onFinish={saveAllDetails}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input placeholder="No" value={number} readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            {/* <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: "Please select the date" }]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item> */}
            <SettingDateComp setBsDate={setBsDate} />
          </Col>
          <Col span={8}>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input disabled placeholder="User Code" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="Reference" name="reference">
              <Input placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice No"
              name="supplierInvoiceNo"
              rules={[
                { required: true, message: "Please enter Supplier Invoice No" },
              ]}
            >
              <Input placeholder="Supplier Invoice No" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice Date"
              name="supplierInvoiceDate"
              rules={[{ required: true, message: "Please enter Invoice Date" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input the phone number",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please Enter 10 digit number only",
                },
              ]}
            >
              <Input placeholder="Enter phone number" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input the code" }]}
            >
              <Input placeholder="Name" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Button onClick={() => setIsModalVisible(true)} type="primary">
          Add Item
        </Button>
        <Button
          onClick={() => {
            setIsUserModalVisible(true);
          }}
        >
          Add User
        </Button>
        <Modal
          title="Select a User"
          open={isUserModalVisible}
          onCancel={handleMasterCancel}
          footer={null}
          width={800}
        >
          <div>
            <Button onClick={handleAddUserClick}>Add User</Button>
          </div>
          <Table
            dataSource={suppliersDetails}
            columns={columsOfUsers}
            rowKey="id"
            pagination={{ pageSize: 5 }}
            scroll={{ x: 1350 }}
          />
        </Modal>
        <Modal
          title="Add Item"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          className="modalContainer"
          width={300}
        >
          <Form
            form={formTwo}
            onFinish={handleSaveForm2}
            className="modalContainer"
          >
            {addItemArray.map((item) => (
              <Form.Item
                key={item.name}
                name={item.name}
                label={item.label}
                rules={item.rules}
              >
                {item.isDropdown ? (
                  <Select
                    placeholder={`Select ${item.label}`}
                    style={{ width: 150 }}
                  >
                    {userMasterList.map((item: ItemType) => (
                      <Option
                        key={item.key}
                        value={item.name}
                        scroll={{ x: "max-content" }}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    className="customInput"
                    placeholder={`Enter ${item.label}`}
                    id="modalInput"
                    style={{ width: 150 }}
                  />
                )}
              </Form.Item>
            ))}
            <Form.Item
              name="uom"
              label="UOM"
              rules={[{ required: true, message: "Please input UOM!" }]}
            >
              <Select
                defaultValue="Select UOM"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "kg", label: "Kg" },
                  { value: "ton", label: "Ton" },
                  { value: "quintal", label: "Quintal" },
                ]}
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button type="primary" htmlType="submit">
                Save Item
              </Button>
            </div>
          </Form>
        </Modal>
        <CropItemsTable
          dataSource={ItemListData}
          doctype={false}
          SetDataSource={null}
          handleFunc={confirm}
        />
        <div className="totals">{totalsJSX}</div>
        <Button htmlType="submit" type="default">
          Save
        </Button>
        <Button disabled={!showPrintPdf} onClick={printPdf} type="primary">
          Download PDF
        </Button>
      </Form>
      <AddUser isAddUser={isAddUser} setAddUser={setAddUser} />
    </div>
  );
};
export default PurchaseReturn;
