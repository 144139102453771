import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ToWords } from "to-words";
import moment from "moment";

export const handleBillOfSupplyPdf = async (pdfData) => {
  const generateHTMLContent = (printIndex, pdfData) => {
    const poDate = pdfData?.PoDate?.format("DD-MM-YYYY");
    const ItemListData = pdfData.ItemListData;
    console.log(ItemListData, "ItemListData inside pdf");
    const isIgst = "igst" in ItemListData[0];
    console.log(isIgst, "isIgst");
    const { organizationName, address, gstin } = pdfData.orgProfileData;
    const heading =
      pdfData.docType != "Purchase order"
        ? pdfData.taxInvoice
          ? `TAX Invoice`
          : `BILL OF SUPPLY`
        : "PURCHASE ORDER";

    const toWords = new ToWords();
    let words = toWords.convert(pdfData.total);

    let additionalText = "";
    if (printIndex === 0) {
      additionalText = `<h3 class="dynamicHead">Original For Recipient</h3>`;
    } else if (printIndex === 1) {
      additionalText = `<h3 class="dynamicHead">Duplicate For Transporter</h3>`;
    } else {
      additionalText = `<h3 class="dynamicHead">Triplicate For Assesse</h3>`;
    }
    const tableRows = ItemListData.map(
      (item, index) => `
      <tr key="${index}">
        <td>${index + 1}</td>
        <td>${item.name}</td>
        <td>${item.rate}</td>
        <td>${item.quantity}</td>
        <td>${item.uom}</td>
        <td>${item.hsncode}</td>
        <td>${item.Amount.toFixed(3)}</td>
        <td>${item.gst}</td>
        <td>${item.cgst.toFixed(3)}</td>
        ${
          !isIgst
            ? `<td>${item.sgst.toFixed(3)}</td>`
            : `<td>${item.igst.toFixed(3)}</td>`
        }
        <td>${item.grossAmount.toFixed(3)}</td>
      </tr>
    `
    ).join("");
    return `
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Tax-Invoice-Template</title>
    <style>
        body {
            font-family: 'Arial', sans-serif;
            background-color: #fff;
            color: #333;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        * {
            box-sizing: border-box;
        }

        #wrapper {
            border: 2px solid black;
            width: 90%;
        }

        #wrapper>* {
            width: 100%;
        }

        .header {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-bottom: 3px solid black;
        }

        .dynamicHead {
            position: absolute;
            top: -1px;
            left: 900px;
        }

        .headingComponent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .heading {
            width: 100%;
            font-size: 21px;
            border-bottom: 3px solid black;
            padding: 10px;
            text-align: center;
            margin: 0 auto;
        }

        .subHeading li {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-weight: 530;
            font-size: 30px;
            padding: 5px;
        }

        #subHeading1 {
            font-size: 20px;
            padding: 6px;
            font-weight: 520;
        }

        .secondRow {
            display: flex;
            border-bottom: 3px solid black;
            margin: 0;
        }

        h6 {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 3px solid black;
            margin-top: 0;
            padding: 10px;
        }

        .secondRowList {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px;
            font-size: 17px;
            margin-top: -20px;
            margin-bottom: 0;
            font-weight: 500;
        }

        .secondRowList li {
            padding: 2px;
            margin: 2px;
        }

        .secondRowListDocument-Details {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px;
            margin-top: -20px;
            margin-bottom: 0;
        }

        .secondRowListDocument-Details li {
            padding: 4px;
            margin: 1px 0;
            font-size: 17px;
            display: block;
            width: 100%;
            box-sizing: border-box;
        }

        .secondFirstCol {
            flex: 1;
            font-size: 25px;
            font-weight: 500;
            border-right: 3px solid black;
        }

        .secondSecondCol {
            flex: 1;
            border-right: 3px solid black;
            font-size: 25px;
            font-weight: 500;
        }

        .secondThirdCol {
            flex: 2;
            font-size: 25px;
            font-weight: 500;
        }

        .thirdRow {
            margin-top: -10px;
            padding: 5px;
        }

        .thirdRow>p {
            font-size: 12px;
        }

        .thirdColList {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 0;
            margin-top: -14px;
            margin-bottom: 0;
            font-size: 10px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        th,
        td {
            border: 2px solid black;
            padding: 8px;
            text-align: left;
        }

        td {
            font-size: 14px;
            font-weight: 547;
        }

        th {
            background-color: #fff;
            font-size: 14px;
            font-weight: 700;
        }

        tr:nth-child(even) {
            background-color: #fff;
        }

        .total-row td {
            font-weight: bold;
            font-size: 15px;
            text-align: center;
            vertical-align: middle;
        }

        .Amount {
            font-weight: bold;
            font-size: 15px;
            padding: 5px;
            margin: 5px 0;
            width: 70%;
            display: block;
            box-sizing: border-box;
        }

        .amountText {
            font-size: 15px;
            font-weight: bold;
        }

        .container {
            display: flex;
        }

        .section {
            width: 48vw;
            font-size: 10px;
            margin-left: 200px;
            text-align: center;
        }

        .section h4 {
            margin-left: 10px;
        }

        .section h2,
        .section h3 {
            margin-bottom: 10px;
            font-size: 10px;
        }

        .section-row {
            display: flex;
            justify-content: space-between;
        }

        .section-col {
            width: 100%;
        }

        .two-cols {
            display: flex;
            justify-content: space-between;
        }

        .bottomRow {
            display: flex;
            justify-content: space-around;
            padding: 0;
        }

        .bottomFirstRow {
            width: 100%;
            border-top: 2px solid black;
        }

        .bottomSecondRow {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }

        .bottomSecondRow>h4,
        strong {
            font-size: 10px;
        }

        .specBlock {
            width: 40vw;
            font-size: 10px;
            margin: 10px auto;
            text-align: center;
            padding: 10px;
        }

        .specBlock strong {
            font-size: 15px;
            padding: 1px;
            margin: 1px;
            font-weight: 500;
        }

        .mainsection {
            display: flex;
            border-bottom: 3px solid black;
            border-top: 3px solid black;
            margin: 0;
        }

        h6 {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 3px solid black;
            margin-top: 0;
            padding: 10px;
        }

        .middlesecondRowList h1 {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px;
            font-size: 13px;
            margin-top: 10px;
            margin-bottom: 0;
            font-weight: 600;
            margin-left: 100px;
        }

        .middlesecondRowList h3 {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px 10px;
            font-size: 13px;
            margin-top: 50px;
            margin-bottom: 0;
            font-weight: 600;
            margin-left: 200px;
        }

        .middlesecondRowList li {
            padding: 2px;
            margin: 2px;
        }

        .middleRowListDocument-Details {
            display: flex;
            flex-direction: column;
            list-style: none;
            /* padding: 10px 10px; */
            line-height: 10%;
            margin-top: -5px;
            margin-bottom: 0;
            font-size: 12px;
            margin-left: 30px;
        }

        .middlesecondRowListDocument-Details li {
            padding: 4px;
            margin: 1px 0;
            font-size: 17px;
            display: block;
            width: 100%;
            box-sizing: border-box;
        }

        .middlesecondFirstCol {
            flex: 2;
            font-size: 25px;
            font-weight: 500;
            border-right: 3px solid black;
        }

        .middlesecondSecondCol {
            flex: 2;
            border-right: 3px solid black;
            font-size: 10px;
            font-weight: 500;
        }

        .middlesecondSecondCol h6 {
            font-size: 15px;
            font-weight: 600;
            padding: 10px 10px;
        }

        .middlesecondThirdCol {
            flex: 2;
            font-size: 25px;
            font-weight: 500;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .middlesecondRowList {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        .middlesecondRowList h6 {
            margin: 10px 0px;
            padding: 10px 10px;
            font-size: 1.5em;
            border-bottom: 2px solid #000;
        }

        .transport-details-columns {
            display: flex;
            width: 100%;
            border-bottom: none;
        }

        .column {
            flex: 1;
            border-right: 1px solid #000;

        }

        .column:last-child {
            border-right: none;
        }

        .column h6 {
            margin: 0px;
            font-weight: 900;
            padding: 10px 10px;
            font-size: 1em;
            border-bottom: 5px solid #000;
            width: 100%;
            box-sizing: border-box;
        }

        .middlesecondRowList h5 {
            font-weight: 700;
            padding: 5px 5px;
            font-size: 1.2em;
            text-align: center;
        }

        .column p {
            margin: 0;
            padding: 5px 0;
            border-bottom: 2px solid #000;
            width: 10;
            font-weight: 800;
        }
    </style>
</head>

<body>
    <div id="wrapper">
        <div class='header'>
            <div class='headingComponent'>
                <h3 class='heading'>
                    ${heading}
                </h3>
                ${additionalText}
                <h3 class='subHeading'>
                    <li>${organizationName}</li>
                    <li id="subHeading1">${address}</li>
                    <li id="subHeading1">GST: ${gstin}</li>
                    <li id="subHeading1">FSSAI: 11222302001521</li>
                </h3>
            </div>
        </div>
        <div class='secondRow'>
            <div class='secondFirstCol'>
                <h6>Document Details</h6>
                <ul class="secondRowListDocument-Details">
                    <li>Invoice Number:${pdfData.sk}</li>
                    <li>Invoice Date :${moment(pdfData.date).format(
                      "DD-MM-YYYY"
                    )}</li>
                    ${
                      pdfData.docType != "Purchase order"
                        ? `<li>PO No : ${pdfData.PoNo}</li>`
                        : ``
                    }
                    ${
                      pdfData.docType != "Purchase order"
                        ? `<li>PO Date : ${poDate}</li>`
                        : ``
                    }
                </ul>
            </div>
            <div class='secondSecondCol'>
                <h6>Bill To</h6>
                <ul class="secondRowList">
                    <li>Name : ${pdfData.name}</li>
                    <li>Address :${pdfData.deliveryAddress}</li>
                    <li>GST No : ${pdfData.gstin ? pdfData.gstin : ``}</li>
                    <li>Pan No :</li>
                    <li>Buyer ID : ${pdfData.userCode}</li>
                </ul>
            </div>
            ${
              pdfData.docType != "Purchase order"
                ? `<div class='secondThirdCol'>
                <h6>Bank Details</h6>
                <ul class="secondRowList">
                    <li>SAMPARKBINDHU SOLUTIONS PRIVATE LTD</li>
                    <li>Account No : 233105001472</li>
                    <li>Bank : ICICI</li>
                    <li>IFSC Code: ICIC0002331</li>
                    <li>Branch: Hennur Road, Bengaluru</li>
                </ul>
            </div>`
                : ``
            }
        </div>
        <table>
            <thead>
                <tr>
                    <th>Sl. No</th>
                    <th>Item Name</th>
                    <th>Rate</th>
                    <th>Quantity</th>
                    <th>UOM</th>
                    <th>Hsn code</th>
                    <th>Amount</th>
                    <th>GST(%)</th>
                    <th>CGST</th>
                    ${isIgst ? `<th>IGST</th>` : `<th>SGST</th>`}
                    <th>Total</th>
                    
                </tr>
            </thead>
            <tbody>
                ${tableRows}
                <tr class="total-row">
                    <td colspan="6">Total Invoice value</td>
                    <td>${pdfData.totalGross}</td>
                    <td>${pdfData.totalGst}</td>
                    <td>${pdfData.totalCgstAmount}</td>
                    
                    ${
                      isIgst
                        ? `<td>${pdfData.totalIgstAmount}</td>`
                        : `<td>${pdfData.totalSgstAmount}</td>`
                    }
                    <td>${pdfData.total}</td>
                </tr>
            </tbody>
        </table>
        <div class="AmountInWords">
            <p class="Amount">Rupees in Words:Rupees ${words}</p>
        </div>
        <div class='mainsection'>
            <div class='middlesecondFirstCol'>
                <div class="middleRowListDocument-Details">
                    <h2>Terms</h2>
                    <p>1. Black Pepper Bold INR 715 per kg Excluding Transport and GST</p>
                    <p>2. Black Pepper should be Fully Cleaned and mositure less than 10% </p>
                    <p>3. Mention PO number in BILL</p>
                    <p>4. COA required per FSSAI Standards. Company FSSAI to be listed in invoice </p>
                    <p>5. Delivery by 28/03/2025</p>
                    <p>6. In Case the Pepper quality does not meet sample quality, It is liable to be rejected</p>
                    <p>7. It will need to be packed in 30 or 40 kgs plastic bags with double lining</p>
                </div>
            </div>
            <!-- <div class="middlesecondSecondCol">
                <div class="middlesecondRowList">
                    <h6>Transport Details</h6>
                    <div class="transport-details-columns">
                        <div class="column">
                            <p>LR No:</p>
                            <p></p>
                            <p></p>
                        </div>
                        <div class="column">
                            <p>E-Way No:</p>
                            <p></p>
                            <p></p>
                        </div>
                        <div class="column">
                            <p>Transport Name:</p>
                            <p></p>
                            <p></p>
                        </div>
                        <div class="column">
                            <p>Vech No:</p>
                            <p></p>
                            <p></p>
                        </div>
                    </div>
                    <h5>Jurisdiction - Bengaluru</h5>
                </div>
            </div> -->
            <div class='middlesecondThirdCol'>
                <div class="middlesecondRowList">
                    <h1>FOR ${organizationName}</h1>
                    <h3>Authorised Signatory</h3>
                </div>
            </div>
        </div>
        <div class="bottomRow">
            <div class="specBlock">
                <div>
                    <strong>Registered Office Address : #617, "SHIVAM", ROYAL PARK RESIDENCY, J P NAGAR 9TH PHASE
                        BENGALURU
                        , PIN:- 560062</strong>
                    <strong>CIN# U72900KA2020PTC13512, Phone:+919845711600</strong>
                    <strong>Visit Website: https://SamparkBindhu.in
                    </strong>
                </div>
            </div>
        </div>
    </div>
</body>
</html>
        `;
  };

  const generatePDF = async (printIndex) => {
    return new Promise(async (resolve) => {
      const htmlContent = generateHTMLContent(printIndex, pdfData);

      const iframe = document.createElement("iframe");
      iframe.style.width = "320mm";
      iframe.style.height = "297mm";
      document.body.appendChild(iframe);

      iframe.onload = async () => {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.open();
        iframeDocument.write(htmlContent);
        iframeDocument.close();

        // Convert HTML to Canvas
        const canvas = await html2canvas(iframeDocument.body, {
          scale: 2, // Higher scale for better quality
          width: iframeDocument.body.scrollWidth,
          height: iframeDocument.body.scrollHeight,
        });

        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(
          imgData,
          "PNG",
          0,
          0,
          210,
          (210 * canvas.height) / canvas.width
        );

        // Download PDF
        const pdfBlob = pdf.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = `${pdfData.sk}_Print${printIndex + 1}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Cleanup
        document.body.removeChild(iframe);
        URL.revokeObjectURL(pdfUrl);

        resolve();
      };

      iframe.src = "about:blank";
    });
  };

  // Generate PDFs sequentially to ensure all are downloaded
  for (let i = 0; i < 3; i++) {
    await generatePDF(i);
  }
};
