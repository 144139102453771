import React, { useState } from 'react';
import { Layout } from 'antd';
import Sidebar from "../sidebar/sideBar";  
import DashboardHeader from "../header/header"; 
import "../dashboardPage.scss"; 
import AddUser from "../profile/addUser";
const { Sider, Content } = Layout;
const ReportPage = () => {
const [isAddUser, setAddUser] = useState<boolean>(false);
const handleCancelAddUser = () => setAddUser(false);
const handleOkAddUser = () => setAddUser(false);
return (
    <Layout className="dashboard-layout">
      <Sider width={200} className="sidebar">
        <Sidebar 
          showAddUser={() => setAddUser(true)}
          />
      </Sider>
      <Layout className="content-layout">
        <DashboardHeader />
        <Content className="content">
          <h1>Report Page</h1>
          <div className="dashboard-container-report">
            <div className="button-group">
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default ReportPage;