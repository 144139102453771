import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  PopconfirmProps,
  Radio,
  Row,
  message,
} from "antd";
import { AppDispatch, RootState } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  deleteItem,
  getUserDetails,
  removeAllItems,
} from "../../state/slices/mainSlice";
import SearchDocComponent from "../searchDocComponent";
import {
  saveBillOfSupply,
  saveUpdateInvoices,
  getSequenceNumber,
  getIsIgstApplied,
} from "../../state/api";
import { handleBillOfSupplyPdf } from "./generateBillSupplyPdf";
import CropItemsTable from "../cropItemsTable";
import { useTotals } from "../invoicesTotals";
import SettingDateComp from "../settingDateComp";
import AddItemModal from "../addItemModal";
import AddUserModal from "../addUserModal";
import { InvoiceForm } from "../../utilsTypes";

interface propsItem {
  taxInvoice: boolean;
}

interface ItemType {
  key: string;
  name: string;
  category: string;
  variety: string;
  grade: string;
  gst: string;
  hsncode: string;
  uom: string;
}

const BillOfsupply = (props: propsItem) => {
  const customersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.customers
  );
  const userMasterList = useSelector(
    (state: RootState) => state.main.userMasterListData
  );
  const ItemListData = useSelector((state: RootState) => state.main.itemList);
  const orgProfileData = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [userSk, setUserSk] = useState("");
  const [pdfData, setPdfData]: any = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [userFormValues, setUserFormValues] = useState<InvoiceForm>({
    phone: "",
    date: "",
    deliveryAddress: "",
    PoDate: "",
    pincode: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
    name: "",
    taxInvoice: "",
  });
  const [bsDate, setBsDate] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [isAddItemVisible, setIsAddItemVisible] = useState(true);
  const [isIgst, setIsIgst] = useState(false);
  const { totals, totalsJSX } = useTotals(ItemListData);
  const [form] = Form.useForm();
  const [formTwo] = Form.useForm();
  const { taxInvoice } = props;
  const docType = taxInvoice ? "TI" : "BOS";
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;

  useEffect(() => {
    dispatch(getUserDetails(orgId));
  }, [dispatch]);

  useEffect(() => {
    setUserSk("");
    form.setFieldsValue(userFormValues);
    const payload = {
      userPincode: userFormValues.pincode,
      orgPincode: orgProfileData.pincode,
    };
    if (userFormValues.pincode && orgProfileData.pincode) {
      dispatch(getIsIgstApplied(payload, setIsIgst));
      setIsAddItemVisible(false);
    }
  }, [userFormValues]);

  useEffect(() => {
    if (isSearch) {
      userFormValues.ItemListData.forEach((item, index) => {
        setShowAddItem(true);
      });
    }
  }, [isSearch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSequenceNumber();
  }, [isReload]);

  const handleSelectItem = (record) => {
    const { sk, userType, institutionSubType, user, address, ...restRecord } =
      record;
    setUserFormValues(restRecord);
    setIsUserModalVisible(false);
  };

  const confirm: PopconfirmProps["onConfirm"] = (record: any) => {
    dispatch(deleteItem(record.id));
    message.success("Click on Yes");
  };

  const handleSaveForm2 = (values: any) => {
    const itemFromList: any = userMasterList.find(
      (item: ItemType) => item.name === values.name
    );
    const itemAmount = values.quantity * parseFloat(values.rate);
    const GstAmount = (parseFloat(itemFromList.gst) * itemAmount) / 100;
    const sgstPercent = parseFloat(itemFromList.gst) / 2;
    const cgstPercent = parseFloat(itemFromList.gst) / 2;
    const sgstAmount = GstAmount / 2;
    const cgstAmount = GstAmount / 2;
    const grossAmount = GstAmount + itemAmount;
    const igstPercent = parseFloat(itemFromList.gst) / 2;
    const igst = GstAmount / 2;
    if (itemFromList) {
      const mergedItem = {
        ...itemFromList,
        slno: ItemListData.length + 1,
        quantity: parseFloat(values.quantity),
        Amount: itemAmount,
        quantityInBags: values.QtyInBags,
        rate: values.rate,
        GSTAmount: GstAmount,
        grossAmount: grossAmount,
        uom: values.uom,
        ...(isIgst ? { igstPerc: igstPercent } : { sgstPerc: sgstPercent }),
        cgstPerc: cgstPercent,
        ...(isIgst ? { igst: igst } : { sgst: sgstAmount }),
        cgst: cgstAmount,
      };
      dispatch(addItem(mergedItem));
      setShowAddItem(true);
      message.success("Item Added Successfully");
      formTwo.resetFields();
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    formTwo.resetFields();
    setIsModalVisible(false);
  };

  const handleMasterCancel = () => {
    setIsUserModalVisible(false);
  };

  const saveAllDetails = async (values: any) => {
    const payLoad = {
      ...userFormValues,
      ...values,
      ItemListData,
      taxInvoice,
      ...totals,
      pk: orgId,
      date: bsDate ? bsDate : values.date,
    };
    message.success("All data saved successfully");
    let sk = payLoad.sk;
    if (!isSearch) {
      sk = await dispatch(saveBillOfSupply(payLoad));
    } else {
      dispatch(saveUpdateInvoices(payLoad));
    }
    printPdf({ ...payLoad, sk });
    setIsIgst(false);
    form.resetFields();
    dispatch(removeAllItems());
    setIsSearch(false);
    setIsReload(true);
  };

  const printPdf = (payLoad) => {
    const payload2 = {
      orgProfileData,
      ...payLoad,
      docType: "BillofSupply",
    };
    handleBillOfSupplyPdf(payload2);
    form.resetFields();
    setIsReload(false);
  };

  return (
    <div className="billOfSupply">
      <SearchDocComponent
        docType={taxInvoice ? "TI" : "BOS"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form form={form} onFinish={saveAllDetails}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input
                placeholder="No"
                readOnly={true}
                aria-label={isSearch ? "sk" : "number"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SettingDateComp setBsDate={setBsDate} />
          </Col>
          <Col span={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input the Name!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Name"}
                readOnly={true}
                aria-label="name"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="PoNo"
              label="PO No."
              rules={[{ required: true, message: "Please input the PO No.!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Po No"}
                style={{ width: 150 }}
                aria-label="PoNo"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="PoDate"
              label="PO Date"
              rules={[{ required: true, message: "Please input the PO Date!" }]}
            >
              <DatePicker format="DD-MM-YYYY" aria-label="PoDate" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="deliveryAddress"
              label="DeliveryAddress"
              rules={[{ required: true, message: "Please input the Address!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"deliveryAddress"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="gstin" label="GSTIN">
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Gstin"}
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="With Gst"
              name="Gst"
              rules={[{ required: true, message: "Please input the GST!" }]}
            >
              <Radio.Group
                className="custom-radio"
                name="Gst"
                defaultValue="Yes"
              >
                <Radio value={"Yes"}>Yes</Radio>
                <Radio value={"No"}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input placeholder="User Code" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input the phone number",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please Enter 10 digit number only",
                },
              ]}
            >
              <Input placeholder="Enter phone number" readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Button
          disabled={isAddItemVisible}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add Item
        </Button>
        <Button
          disabled={isSearch ? true : false}
          onClick={() => {
            setIsUserModalVisible(true);
          }}
        >
          Add User
        </Button>
        <AddItemModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleSaveForm2={handleSaveForm2}
          formTwo={formTwo}
          userMasterList={userMasterList}
        />
        <AddUserModal
          isUserModalVisible={isUserModalVisible}
          handleMasterCancel={handleMasterCancel}
          customersDetails={customersDetails}
          handleSelectItem={handleSelectItem}
        />
        <CropItemsTable
          dataSource={showAddItem ? ItemListData : []}
          doctype={false}
          SetDataSource={null}
          handleFunc={confirm}
        />
        <div className="totals">{totalsJSX}</div>
        <Button
          style={{ width: "150px" }}
          disabled={ItemListData.length > 0 ? false : true}
          htmlType="submit"
        >
          Save And DownLoad
        </Button>
        {/* <Button disabled={!showPrintPdf} onClick={printPdf}>
          DownLoad Pdf
        </Button> */}
      </Form>
    </div>
  );
};
export default BillOfsupply;
