import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Card,
  Layout,
  Modal,
  Form,
  Input,
  Select,
  message,
} from "antd";
import Sider from "antd/es/layout/Sider";
import Sidebar from "../sidebar/sideBar";
import DashboardHeader from "../header/header";
import { Content } from "antd/es/layout/layout";
import Footer from "../footer/footer";
import "./item.scss";
import { AppDispatch, RootState } from "../state/store";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserMasterData,
  saveUserMasterData,
  updateTableData,
  deleteTableData,
  addTableData,
  clearChangedItems,
} from "../state/slices/mainSlice";
import { getMasterListData } from "../state/api";

const Item: React.FC = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMasterVisible, setIsMasterVisible] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [handleDisable, setHandleDisable] = useState(true);

  const dispatch = useDispatch<AppDispatch>();
  const masterListData = useSelector(
    (state: RootState) => state.main.masterListData
  );
  const tableData = useSelector(
    (state: RootState) => state.main.userMasterListData
  ); // tableData from Redux
  const changedItems = useSelector(
    (state: RootState) => state.main.changedItems
  );
  const orgId: string = localStorage.getItem("orgId")!;

  useEffect(() => {
    dispatch(getMasterListData());
    dispatch(fetchUserMasterData({ id: orgId }));
  }, [dispatch]);

  const masterColumns = [
    {
      title: "Item Name",
      dataIndex: "pk",
      key: "pk",
    },
    {
      title: "Category",
      dataIndex: "Category",
      key: "Category",
    },
    {
      title: "Variety",
      dataIndex: "Variety",
      key: "Variety",
    },
    {
      title: "Grade",
      dataIndex: "Grade",
      key: "Grade",
    },
    {
      title: "GST",
      dataIndex: "GST",
      key: "GST",
    },
    {
      title: "HSN Code",
      dataIndex: "HSN_Code",
      key: "HSN_Code",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];

  const columns = [
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Variety",
      dataIndex: "variety",
      key: "variety",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "GST%",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "HSN Code",
      dataIndex: "hsncode",
      key: "hsncode",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div>
          <Button type="link" onClick={() => handleEdit(record.sk)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.sk)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleAddItem = () => {
    form.resetFields();
    setEditingKey("");
    setIsModalVisible(true);
  };

  const handleShowMaster = () => {
    setIsMasterVisible(true);
  };

  const handleEdit = (sk: string) => {
    const item = tableData.find((d) => d.sk === sk);
    if (item) {
      form.setFieldsValue(item);
      setEditingKey(sk);
      setIsModalVisible(true);
    }
  };

  const handleDelete = (key: string) => {
    dispatch(deleteTableData(key)); // Dispatch Redux action to delete
    message.success("Item deleted successfully");
  };

  const handleSave = () => {
    const payload = {
      ...changedItems,
      pk: orgId,
    };
    dispatch(saveUserMasterData(payload));
    dispatch(clearChangedItems());
    setHandleDisable(!handleDisable);
    message.success("All data saved successfully");
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (editingKey) {
          dispatch(
            updateTableData({
              sk: editingKey,
              Item_Code: editingKey,
              ...values,
            })
          ); // Update Redux store
        } else {
          const isItemExists = tableData.some(
            (item) => item.name === values.name
          );
          if (isItemExists) {
            message.warning(
              `Item ${values.name} is already present in the table`
            );
            return;
          }
          dispatch(
            addTableData({ key: (tableData.length + 1).toString(), ...values })
          ); // Add new item to Redux store
        }
        setIsModalVisible(false);
        form.resetFields();
        setEditingKey("");
        setHandleDisable(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleMasterCancel = () => {
    setIsMasterVisible(false);
  };

  const handleSelectItem = (record) => {
    const isItemExists = tableData.some((item) => item.name === record.pk);
    if (isItemExists) {
      message.warning(`Item ${record.pk} is already present in the table`);
      return;
    }
    const newItem = {
      key: (tableData.length + 1).toString(),
      name: record.pk,
      category: record.Category,
      variety: record.Variety,
      grade: record.Grade,
      gst: record.GST,
      hsncode: record.HSN_Code,
      uom: "kg",
    };
    dispatch(addTableData(newItem)); // Add selected item to Redux store
    setIsMasterVisible(false);
    setHandleDisable(false);
    message.success(`Item ${record.pk} added successfully`);
  };

  return (
    <Layout className="dashboard-layout">
      <Sider width={200} className="sider">
        <Sidebar />
      </Sider>
      <Layout className="content-layout">
        <DashboardHeader />
        <Content className="content">
          <div className="dashboard-container">
            <Card className="card-container">
              <div className="button-group">
                <Button type="primary" onClick={handleAddItem}>
                  Add Item
                </Button>
                <Button type="primary" onClick={handleShowMaster}>
                  Reference
                </Button>
                <Modal
                  title="Select an Item"
                  open={isMasterVisible}
                  onCancel={handleMasterCancel}
                  footer={null}
                  width={800}
                >
                  <Table
                    dataSource={masterListData}
                    columns={masterColumns}
                    rowKey="id"
                    pagination={{ pageSize: 5 }}
                  />
                </Modal>
              </div>
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={{ pageSize: 5 }}
                scroll={{ y: 400 }}
                className="ant-table"
              />

              <Button
                disabled={handleDisable}
                type="primary"
                className="save-button-container"
                onClick={handleSave}
              >
                Save
              </Button>
            </Card>
          </div>
          <Modal
            title={editingKey ? "Edit Item" : "Add Item"}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="Save"
            cancelText="Cancel"
            width="350px"
            footer={[
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  key="cancel"
                  onClick={handleCancel}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button key="save" type="primary" onClick={handleOk}>
                  Save
                </Button>
              </div>,
            ]}
          >
            <Form form={form} layout="horizontal">
              <Form.Item
                label="Item Name"
                name="name"
                rules={[
                  { required: true, message: "Please enter the item name" },
                  {
                    max: 50,
                    message: "Item name cannot be longer than 50 characters",
                  },
                ]}
                required={false}
              >
                <Input
                  placeholder="Enter item name"
                  style={{ width: "200px" }}
                />
              </Form.Item>
              <Form.Item label="Category" name="category" required={false}>
                <Input
                  placeholder="Enter Category"
                  style={{ width: "200px" }}
                />
              </Form.Item>
              <Form.Item label="Variety" name="variety" required={false}>
                <Input placeholder="Enter variety" style={{ width: "200px" }} />
              </Form.Item>
              <Form.Item label="Grade" name="grade" required={false}>
                <Input placeholder="Enter grade" style={{ width: "200px" }} />
              </Form.Item>
              <Form.Item
                label="HSN Code"
                name="hsncode"
                rules={[
                  { required: true, message: "Please enter HSN Code" },
                  { pattern: /^\d{1,8}$/, message: "Hsn must be 1-8 digits" },
                ]}
              >
                <Input
                  placeholder="Enter HSN code"
                  style={{ width: "200px" }}
                />
              </Form.Item>
              <Form.Item
                label="GST%"
                name="gst"
                rules={[
                  { required: true, message: "Please enter GST" },
                  {
                    pattern: /^(100(\.00?)?|[1-9]?[0-9](\.[0-9]{1,2})?)$/,
                    message: "please Enter Gst in %",
                  },
                ]}
                required={false}
                initialValue={0}
              >
                <Input placeholder="Enter GST" style={{ width: "200px" }} />
              </Form.Item>
            </Form>
          </Modal>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};
export default Item;
