import { configureStore } from "@reduxjs/toolkit";
import mainSliceReducer, { resetState } from "./slices/mainSlice";

const rootReducer = (state: any, action: any) => {
  if (action.type === resetState.type) {
    return { main: mainSliceReducer(undefined, action) }; // Ensure initial state
  }
  return {
    main: mainSliceReducer(state?.main, action),
  };
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
