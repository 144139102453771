import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";

export const PrivateRoute = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  // If user is not authenticated, redirect to /auth with state
  return user ? <Outlet /> : <Navigate to="/auth" />;
};
