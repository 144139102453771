import { Button, Modal, Table } from "antd";
import React from "react";

const SelectInvoiceBtnSales = ({
  isModalVisible,
  handleModalFormSubmit,
  handleCancel,
  modalSrData,
}) => {
  const columnsOfItemsModal = [
    {
      title: "Invoice Number",
      dataIndex: "sk",
      key: "sk",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: any, record: any) => record.date.split("T")[0],
    },
    {
      title: "Category",
      render: (text: any, record: any) =>
        record.sk.includes("BOS") ? "Bill Of Supply" : "Tax Invoice",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Button type="primary" onClick={() => handleModalFormSubmit(record)}>
          Select
        </Button>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Search For Invoice"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Table
          columns={columnsOfItemsModal}
          dataSource={modalSrData}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: "max-content" }}
        />
      </Modal>
    </>
  );
};

export default SelectInvoiceBtnSales;
