import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Modal,
  Table,
  message,
  Col,
  Row,
} from "antd";
import moment from "moment";
import SearchDocComponent from "../searchDocComponent";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import {
  BASE_URL,
  fetchUserDetails,
  savePurchaseEntryData,
  saveUpdateInvoices,
  getSequenceNumber,
} from "../../state/api";
import AddUser from "../../profile/addUser";
import { removeAllItems } from "../../state/slices/mainSlice";
import CropItemsTable from "../cropItemsTable";
import { useTotals } from "../invoicesTotals";
import SettingDateComp from "../settingDateComp";

interface Item {
  id: string;
  itemName: string;
  quantity: number;
  rate: number;
  uom: string;
  discount: number;
  key: string;
}

const PurchaseEntry: React.FC = () => {
  const docType = "PO";
  const [isAddUser, setAddUser] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataSourceData, setDataSourceData] = useState<any>([]);
  const [modalData, setModalData] = useState<Item[]>([]);
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
  });
  const [userProfileData, setUserPorfileData] = useState({
    userCode: "",
    pincode: "",
    gstin: "",
    address: "",
  });
  const [isSearch, setIsSearch] = useState(false);
  const [bsDate, setBsDate] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;
  const { totals, totalsJSX } = useTotals(dataSourceData);
  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    setDataSourceData(userFormValues.ItemListData);
  }, [isSearch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSequenceNumber();
  }, [isReload]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleModalFormSubmit = (selectedItem: any) => {
    setDataSourceData(selectedItem.ItemListData);
    form.setFieldsValue({
      userCode: selectedItem.userCode,
      name: selectedItem.name,
      reference: selectedItem.reference,
      supplierInvoiceNo: selectedItem.supplierInvoiceNo,
      supplierInvoiceDate: selectedItem.supplierInvoiceDate
        ? moment(selectedItem.supplierInvoiceDate)
        : null,
    });
    setUserPorfileData({
      userCode: selectedItem.userCode,
      pincode: selectedItem.pincode,
      gstin: selectedItem.gstin,
      address: selectedItem.address,
    });
    setIsModalVisible(false);
    message.success("Item added successfully");
  };

  const handleDelete = (key: any) => {
    const newData = dataSourceData.filter((item: Item) => item.id !== key.id);
    setDataSourceData(newData);
  };

  const columsOfModalData = [
    {
      title: "Grn No",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "User code",
      dataIndex: "userCode",
      key: "userCode",
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleModalFormSubmit(record)}>
          Select
        </Button>
      ),
    },
  ];

  const handleSave = async (values: any) => {
    try {
      const payload = {
        pk: orgId,
        ...userProfileData,
        ...values,
        ItemListData: dataSourceData,
        date: bsDate,
      };

      isSearch
        ? dispatch(saveUpdateInvoices(payload))
        : dispatch(savePurchaseEntryData(payload));
      form.resetFields();
      dispatch(removeAllItems());
      setIsReload(true);
      setDataSourceData([]);
      message.success("All data saved successfully");
    } catch (error: any) {
      message.error("Failed to save data", error);
    }
  };

  const handleSelectGrn = async (record) => {
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getGrn?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getGrn?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    setModalData(userDetails);
  };

  return (
    <div className="purchaseEntry">
      <SearchDocComponent
        docType={"PO"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form
        form={form}
        layout="horizontal"
        initialValues={userFormValues}
        onFinish={handleSave}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input placeholder="No" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SettingDateComp setBsDate={setBsDate} />
          </Col>
          <Col span={8}>
            <Form.Item
              label="Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input readOnly={true} placeholder="User Code" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please  Search by name",
                },
              ]}
            >
              <Input placeholder="Name" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Reference" name="reference">
              <Input readOnly={true} placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice No"
              name="supplierInvoiceNo"
              rules={[
                {
                  required: true,
                  message: "Please Enter Supplier Invoice No",
                },
              ]}
            >
              <Input readOnly={true} placeholder="Supplier Invoice No" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice Date"
              name="supplierInvoiceDate"
              rules={[
                {
                  required: true,
                  message: "Please Enter Invoice Date",
                },
              ]}
            >
              <DatePicker disabled placeholder="Supplier Invoice Date" />
            </Form.Item>
          </Col>
        </Row>

        <Button
          id="purchaseentry-button1"
          type="primary"
          className="ant-btn-primary"
          onClick={handleSelectGrn}
        >
          Select GRN
        </Button>
        <div className="modal-wrapper">
          <Modal
            title="GRN Details of this Supplier"
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <div className="table-wrapper">
              <Table
                columns={columsOfModalData}
                dataSource={modalData}
                pagination={{ pageSize: 8, position: ["bottomLeft"] }}
                scroll={{ x: "max-content" }} // Responsive width
              />
            </div>
          </Modal>
        </div>
        <CropItemsTable
          dataSource={dataSourceData}
          doctype={false}
          SetDataSource={null}
          handleFunc={handleDelete}
        />
        <div className="totals">{totalsJSX}</div>
        <Button
          id="purchaseentry-button2"
          type="primary"
          className="ant-btn-primary"
          htmlType="submit"
          style={{ marginLeft: "10px" }}
        >
          Save
        </Button>
      </Form>
      <AddUser isAddUser={isAddUser} setAddUser={setAddUser} />
    </div>
  );
};
export default PurchaseEntry;
