import React from "react";
import "./landingPage.scss";
const Banner = () => {
  return (
    <div className="banner">
      <ul className="banner-content">
        <li>🎉(Pilot Stage(Alpha))</li>
      </ul>
    </div>
  );
};
export default Banner;
