import React from "react";
import { Route, Routes } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import DashboardPage from "./dashboardPage";
import ReportPage from "./reports/reportPage";
import LandingPage from "./landingPage/landingPage";
import AuthenticatorPage from "./authenticatorPage";
import Invoices from "./Invoices";
import Profile from "./profile";
import Item from "./profile/item";
import UserDetails from "./user-details/userDetails";
import StockSummary from "./reports/stockSummary";
import SessionTimeoutHandler from "./sessionTimeout";
import { PrivateRoute } from "./privateRoute";

const App = () => {
  return (
    <Authenticator.Provider>
      <SessionTimeoutHandler>
        <Routes>
          {/* Public Route */}
          <Route path="/auth" element={<AuthenticatorPage />} />
          <Route path="/" element={<LandingPage />} />
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/report" element={<ReportPage />} />
            <Route path="/item" element={<Item />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/UserDetails" element={<UserDetails />} />
            <Route path="/stockSummary" element={<StockSummary />} />
          </Route>
        </Routes>
      </SessionTimeoutHandler>
    </Authenticator.Provider>
  );
};

export default App;
