import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Table,
  message,
  Popconfirm,
  Modal,
  Col,
  Row,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import {
  BASE_URL,
  fetchUserDetails,
  saveSalesReturnData,
  saveUpdateInvoices,
  getSequenceNumber,
  getRemainingAmount,
} from "../../state/api";
import SearchDocComponent from "../searchDocComponent";
import { handleSrPdf } from "./generateSalesReturnPdf";
import { getUserDetails, removeAllItems } from "../../state/slices/mainSlice";
import { DeleteFilled } from "@ant-design/icons";
import CropItemsTable from "../cropItemsTable";
import { useTotals } from "../invoicesTotals";
import SettingDateComp from "../settingDateComp";
import ReturnCropTable from "../returnCropTable";
import SelectInvoiceBtnSales from "./selectInvoiceBtnSales";
interface Item {
  items: any[];
  orderNo: any;
  pk: string;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  sk: string;
  id: string;
}

const SalesReturn = () => {
  const [dataSourceSrData, setDataSourceSrData] = useState<any>([]);
  const [modalSrData, setModalSrData] = useState<Item[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [number, setNumber] = useState("");
  const docType = "SR";
  const [isSearch, setIsSearch] = useState(false);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    gstin: "",
    deliveryAddress: "",
    pincode: "",
    phone: "",
  });
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    name: "",
    deliveryAddress: "",
    pincode: "",
    gstin: "",
    userCode: "",
    ItemListData: [],
    salesInvoiceNo: "",
    returnAmount: 0,
  });
  const [pdfData, setPdfData]: any = useState();
  const [userSk, setUserSk] = useState();
  const [bsDate, setBsDate] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [isCalcRemAmount, setIsCalcRemAmount] = useState(0);
  const [showPaymentNumber, setShowPaymentNumber] = useState(0);
  const [showRemainingAmount, setShowRemainingAmount] = useState(0);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const orgProfileData = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  const orgId: string = localStorage.getItem("orgId")!;
  const { totals, totalsJSX } = useTotals(dataSourceSrData);
  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    dispatch(getUserDetails(orgId));
  }, [dispatch]);

  useEffect(() => {
    setDataSourceSrData(userFormValues.ItemListData);
    const fetchRemainingAmount = async () => {
      try {
        await getRemainingAmount(
          orgId,
          userFormValues.salesInvoiceNo,
          setIsCalcRemAmount,
          setShowPaymentNumber,
          setShowRemainingAmount
        );
      } catch (error) {
        console.error("Error in fetchRemainingAmount:", error);
      }
    };

    if (isSearch) {
      fetchRemainingAmount();
    }
  }, [isSearch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSequenceNumber();
  }, [isReload]);

  const handleModalFormSubmit = (selectedItem: any) => {
    const calcRemAmount = selectedItem.remainingAmount ?? selectedItem.total;
    setIsCalcRemAmount(calcRemAmount);
    if (
      selectedItem &&
      selectedItem.ItemListData &&
      selectedItem.ItemListData.length > 0
    ) {
      setDataSourceSrData(selectedItem.ItemListData);
      form.setFieldsValue({
        userCode: selectedItem.userCode,
        name: selectedItem.name,
        phone: selectedItem.phone,
        salesInvoiceNo: selectedItem.sk,
      });
      setAdditionalData({
        gstin: selectedItem.gstin,
        deliveryAddress: selectedItem.deliveryAddress,
        pincode: selectedItem.pincode,
        phone: selectedItem.phone,
      });
      setIsModalVisible(false);
    } else {
      console.error(
        "Selected item or ItemList is undefined or empty",
        selectedItem
      );
    }
  };

  const handleDelete = (key: Item) => {
    const newData = dataSourceSrData.filter((item: Item) => item.id !== key.id);
    setDataSourceSrData(newData);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSave = async (values: any) => {
    const remainingAmount =
      isCalcRemAmount + userFormValues.returnAmount - Number(totals.total);
    const toCalc = isSearch ? remainingAmount : isCalcRemAmount;
    if (toCalc >= Number(totals.total)) {
      const payload = {
        pk: orgId,
        ...userFormValues,
        ...values,
        ItemListData: dataSourceSrData,
        ...(!isSearch && { ...additionalData }),
        date: bsDate ? bsDate : values.date,
        returnAmount: Number(totals.total),
        update: isSearch ? true : false,
        ...(isSearch && { remainingAmount }),
      };
      let sk = payload.sk;
      if (!isSearch) {
        sk = await dispatch(saveSalesReturnData(payload));
      } else {
        dispatch(saveUpdateInvoices(payload));
      }
      printPdf({ ...payload, sk, totals });
      message.success("All data saved successfully");
      form.resetFields();
      dispatch(removeAllItems());
      setIsReload(true);
      setDataSourceSrData([]);
    } else {
      message.error(
        "return amount cannot be more than remaining amount or equal to Zero"
      );
    }
  };

  const printPdf = (payload) => {
    const payload2 = {
      ...payload,
      orgProfileData,
    };
    handleSrPdf(payload2);
    form.resetFields();
    setIsReload(false);
  };

  const handleCancelTotal = () => {
    form.resetFields();
    setDataSourceSrData([]);
    setUserFormValues({
      phone: "",
      date: "",
      name: "",
      deliveryAddress: "",
      pincode: "",
      gstin: "",
      userCode: "",
      ItemListData: [],
      salesInvoiceNo: "",
      returnAmount: 0,
    });
    setIsReload(true);
  };

  const handleSelectBs = async () => {
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getSupplierPoData?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getSales?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    setModalSrData(userDetails);
  };

  return (
    <div className="salesReturn">
      <SearchDocComponent
        docType={"SR"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form form={form} initialValues={userFormValues} onFinish={handleSave}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input placeholder="No" value={number} readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input the data number",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please Enter 10 digit number only",
                },
              ]}
            >
              <Input placeholder="Enter phone number" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SettingDateComp setBsDate={setBsDate} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input placeholder="Customer Code" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input the Supplier Name",
                },
              ]}
            >
              <Input placeholder="Name" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label=" Sales Invoice No."
              name={"salesInvoiceNo"}
              rules={[
                {
                  required: true,
                  message: "Please input the Supplier Name",
                },
              ]}
            >
              <Input placeholder="Sales Invoice number" readOnly={true} />
            </Form.Item>
          </Col>
        </Row>
        <Button
          disabled={isSearch ? true : false}
          onClick={handleSelectBs}
          type="primary"
        >
          Select Invoice
        </Button>
        <SelectInvoiceBtnSales
          isModalVisible={isModalVisible}
          handleModalFormSubmit={handleModalFormSubmit}
          handleCancel={handleCancel}
          modalSrData={modalSrData}
        />
        <ReturnCropTable
          dataSource={dataSourceSrData}
          doctype={true}
          SetDataSource={setDataSourceSrData}
          handleFunc={handleDelete}
          isSearch={isSearch}
          invType="SR"
        />
        <div className="totalamount">
          <strong> Remaining Amount::{isCalcRemAmount}</strong>
          <strong> Return Amount::{totals.total}</strong>
        </div>
        <Button style={{ width: "150px" }} onClick={form.submit} type="default">
          Save And Download
        </Button>
        <Button type="primary" onClick={handleCancelTotal}>
          Cancel
        </Button>
      </Form>
    </div>
  );
};
export default SalesReturn;
