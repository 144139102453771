import { Layout, Table, Tabs } from "antd";
import Sider from "antd/es/layout/Sider";
import React, { useEffect } from "react";
import Sidebar from "../sidebar/sideBar";
import DashboardHeader from "../header/header";
import { Content } from "antd/es/layout/layout";
import Footer from "../footer/footer";
import { getUserDetails } from "../state/slices/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../state/store";

const { TabPane } = Tabs;

const userDetails = () => {
  const customersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.customers
  );
  const suppliersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.suppliers
  );
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;

  useEffect(() => {
    dispatch(getUserDetails(orgId));
  }, [dispatch]);

  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: " SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
  ];

  return (
    <Layout className="dashboard-layout">
      <Sider width={200} className="sider">
        <Sidebar />
      </Sider>
      <Layout className="content-layout">
        <DashboardHeader />

        <Content className="content">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Supplier" key="1">
              <Table
                columns={columsOfUsers}
                dataSource={suppliersDetails}
                pagination={{ position: ["bottomRight"] }}
                scroll={{ y: 300 }}
                />
            </TabPane>
            <TabPane tab="Customer" key="2">
              <Table
                columns={columsOfUsers}
                dataSource={customersDetails}
                pagination={{ position: ["bottomRight"] }}
              />
            </TabPane>
          </Tabs>
    
        </Content>
        <Footer />
      </Layout>
    
    </Layout>
  );
};

export default userDetails;
