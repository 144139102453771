import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Table,
  Popconfirm,
  message,
  Select,
  DatePicker,
} from "antd";
import POData from "./podata";
import { useDispatch, useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import SearchDocComponent from "../searchDocComponent";
import {
  BASE_URL,
  fetchUserDetails,
  saveGrnEntryData,
  saveUpdateInvoices,
  getSequenceNumber,
} from "../../state/api";
import { AppDispatch, RootState } from "../../state/store";
import { handleGrnPdf } from "../salesInvoices/generateGrnPdf";
import { removeAllItems } from "../../state/slices/mainSlice";
import CropItemsTable from "../cropItemsTable";
import { useTotals } from "../invoicesTotals";
import SettingDateComp from "../settingDateComp";
import moment from "moment";
import ReturnCropTable from "../returnCropTable";

interface Item {
  ItemListData: any[];
  orderNo: any;
  pk: string;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  id: string;
}

const { Option } = Select;

const GRNEntry: React.FC = () => {
  const docType = "GRN";
  const [reference, setReference] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierInvoiceNo, setSupplierInvoiceNo] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPO, setSelectedPO] = useState({
    userCode: "",
    address: "",
    pincode: "",
    gstin: "",
    phone: "",
  });
  const [itemSelected, setItemSelected] = useState<any>([]);
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    PoDate: "",
    zip: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
  });
  const [userDetails, setUserDetails] = useState<any>(null);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [pdfData, setPdfData]: any = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [bsDate, setBsDate] = useState("");
  const orgId: string = localStorage.getItem("orgId")!;
  const orgProfileData = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  const { totals, totalsJSX } = useTotals(itemSelected);

  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    setItemSelected(userFormValues.ItemListData);
  }, [isSearch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSequenceNumber();
  }, [isReload]);

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setSelectedPO({
      userCode: "",
      address: "",
      pincode: "",
      gstin: "",
      phone: "",
    });
  };

  const handleSelectPO = (selectedItems: any) => {
    const selectItemsArr = selectedItems;
    setSelectedPO({
      userCode: selectedItems.userCode,
      address: selectedItems.address,
      pincode: selectedItems.pincode,
      gstin: selectedItems.gstin,
      phone: selectedItems.phone,
    });
    form.setFieldsValue({
      userCode: selectItemsArr.userCode,
      name: selectItemsArr.name,
      reference: selectItemsArr.reference,
      supplierInvoiceNo: selectItemsArr.sk,
      supplierInvoiceDate: selectItemsArr.date
        ? moment(selectItemsArr.date)
        : null,
    });
    const items = [selectItemsArr].flatMap((item: any) => item.ItemListData); // Assuming 'items' is an array inside each Item object
    setItemSelected(items);
    setIsModalVisible(false);
  };

  const handleDelete = (record: Item) => {
    const newData = [...itemSelected];
    const index = newData.findIndex((item) => record.id === item.id);
    if (index > -1) {
      newData.splice(index, 1);
      setItemSelected(newData);
    }
  };

  const handleSave = async (values: any) => {
    const userCode = selectedPO?.userCode || "";
    const address = selectedPO?.address || "";
    const pincode = selectedPO?.pincode || "";
    const gstin = selectedPO?.gstin || "";
    const phone = selectedPO?.phone || "";
    const payload = {
      pk: orgId,
      ...values,
      ...(isSearch && { ...userFormValues }),
      ...(!isSearch && { userCode, address, pincode, gstin }),
      ItemListData: itemSelected,
      phone,
      date: bsDate ? bsDate : values.date,
      totals,
    };
    let pdfLoad = payload;
    if (!isSearch) {
      pdfLoad = await dispatch(saveGrnEntryData(payload));
    } else {
      dispatch(saveUpdateInvoices(payload));
    }
    printPdf(pdfLoad);
    form.resetFields();
    dispatch(removeAllItems());
    message.success("All data saved successfully");
    setItemSelected([]);
    setIsReload(true);
    setIsSearch(false);
  };

  const printPdf = (pdfData) => {
    const payload2 = {
      ...pdfData,
      orgProfileData,
    };
    handleGrnPdf(payload2);
    form.resetFields();
    setIsReload(false);
  };

  const handleSelectSup = async () => {
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getSupplierPoData?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getSupplierPoData?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    setUserDetails(userDetails);
  };

  return (
    <div className="grnEntry">
      <SearchDocComponent
        docType="GRN"
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form form={form} initialValues={userFormValues} onFinish={handleSave}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SettingDateComp setBsDate={setBsDate} />
          </Col>
          <Col span={8}>
            <Form.Item label="Reference" name="reference">
              <Input
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                placeholder="Reference"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please input the supplier name" },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: "Please enter name only",
                },
              ]}
            >
              <Input
                value={supplierName}
                onChange={(e) => setSupplierName(e.target.value)}
                placeholder="Name"
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Supplier Invoice Date"
              name="supplierInvoiceDate"
              rules={[
                { required: true, message: "Please input the invoice date" },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" disabled />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="supplierInvoiceNo"
              label="Supplier Invoice No."
              rules={[
                { required: true, message: "Please input the invoice number" },
              ]}
            >
              <Input
                value={supplierInvoiceNo}
                onChange={(e) => setSupplierInvoiceNo(e.target.value)}
                placeholder="Invoice No"
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input readOnly={true} placeholder="User Code" />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" id="select-po" onClick={handleSelectSup}>
          Select S.O.
        </Button>

        {isModalVisible && userDetails && (
          <POData
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            onSelect={handleSelectPO}
            items={userDetails.items}
          />
        )}
        {/* <CropItemsTable
          dataSource={itemSelected}
          doctype={true}
          SetDataSource={setItemSelected}
          handleFunc={handleDelete}
        /> */}
        <ReturnCropTable
          dataSource={itemSelected}
          doctype={true}
          SetDataSource={setItemSelected}
          handleFunc={handleDelete}
          isSearch={isSearch}
          invType="GRN"
        />
        <div className="totals">{totalsJSX}</div>
        <Button
          disabled={itemSelected.length > 0 ? false : true}
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
        {/* <Button type="primary" disabled={!showPrintPdf} onClick={printPdf}>
          Download Pdf
        </Button> */}
      </Form>
    </div>
  );
};
export default GRNEntry;
