import { DatePicker, Form } from "antd";
import moment from "moment";
import React from "react";
//this component is
const SettingDateComp = ({ setBsDate }) => {
  const onDateChange = (date: moment.Moment | null) => {
    if (date) {
      const currentTime = moment();
      const formattedDate = `${date.format("YYYY-MM-DD")}T${currentTime.format(
        "HH:mm:ss"
      )}.000Z`;
      setBsDate(formattedDate);
    }
  };
  return (
    <>
      <Form.Item
        name="date"
        label="Date"
        rules={[{ required: true, message: "Please input the Date!" }]}
      >
        <DatePicker
          format="DD-MM-YYYY"
          onChange={onDateChange}
          aria-label="date"
        />
      </Form.Item>
    </>
  );
};

export default SettingDateComp;
