import {
  Input,
  InputNumber,
  message,
  Popconfirm,
  PopconfirmProps,
  Table,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { debounce, isUndefined } from "lodash";

const ReturnCropTable = ({
  dataSource,
  doctype,
  SetDataSource,
  handleFunc,
  isSearch,
  invType,
}) => {
  const [storeRemainingQn, setStoreRemaining] = useState(0);
  const [FirstTime, setFirstTime] = useState(true);
  const cancel: PopconfirmProps["onCancel"] = (e) => {
    message.error("Click on No");
  };

  useEffect(() => {
    if (FirstTime) {
      const allRemainingQuantities = dataSource.reduce(
        (acc: any, item: any) => {
          acc[item.id] = item.remainingQuantity ?? item.quantity; // Map itemKey to remainingQuantity
          return acc;
        },
        {}
      );

      setStoreRemaining(allRemainingQuantities); // Save as an object with itemKey mappings
    }
  }, [FirstTime, dataSource]);

  const columnsOfItems = [
    { title: "SL.No", dataIndex: "slno", key: "slno" },
    { title: "Hsn Code", dataIndex: "hsncode", key: "hsncode" },
    { title: "ItemName", dataIndex: "name", key: "name" },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
      // render: (text: any, record: any) =>
      //   doctype ? renderInput(text, record, "Uom") : record.uom,
    },
    {
      title: `${invType === "GRN" ? "AcceptedQuantity" : "ReturnQuantity"}`,
      dataIndex: `${invType === "GRN" ? "acceptedQuantity" : "returnQuantity"}`,
      key: `${invType === "GRN" ? "acceptedQuantity" : "returnQuantity"}`,
      render: (text: any, record: any) =>
        doctype
          ? renderInput(
              text ? text : 0,
              record,
              `${invType === "GRN" ? "acceptedQuantity" : "returnQuantity"}`
            )
          : invType === "GRN"
          ? record.acceptedQuantity
          : record.returnQuantity,
    },
    {
      title: "RemainingQuantity",
      dataIndex: "remainingQuantity",
      key: "remainingQuantity",
      render: (text: any, record: any) =>
        record.remainingQuantity ?? record.quantity,
    },
    {
      title: "QtyInBags",
      dataIndex: "quantityInBags",
      key: "quantityInBags",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    { title: "GST %", dataIndex: "gst", key: "gst" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    {
      title: "Total",
      dataIndex: "grossAmount",
      key: "grossAmount",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <>
          <Popconfirm
            title="Delete the task"
            onConfirm={() => handleFunc(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled />
          </Popconfirm>
        </>
      ),
    },
  ];

  const debouncedHandleInputChange = useCallback(
    debounce((value, record, column) => {
      const newData = dataSource.map((item: any) =>
        item.id === record.id
          ? (() => {
              const amount =
                column !== "Uom"
                  ? column ===
                    `${
                      invType === "GRN" ? "acceptedQuantity" : "returnQuantity"
                    }`
                    ? value * Number(item.rate)
                    : (isSearch
                        ? item.quantity
                        : item.remainingQuantity ?? item.quantity) * value
                  : item.Amount;

              const gstAmount =
                column !== "Uom"
                  ? column ===
                    `${
                      invType === "GRN" ? "acceptedQuantity" : "returnQuantity"
                    }`
                    ? (value * Number(item.rate) * Number(item.gst)) / 100
                    : ((isSearch
                        ? item.quantity
                        : item.remainingQuantity ?? item.quantity) *
                        value *
                        item.gst) /
                      100
                  : item.GSTAmount;

              const remainingQuantity =
                column ===
                `${invType === "GRN" ? "acceptedQuantity" : "returnQuantity"}`
                  ? isSearch
                    ? item.quantity - value
                    : storeRemainingQn[record.id] - value
                  : item.remainingQuantity ?? item.quantity;

              const grossAmount = amount + gstAmount; // ✅ Calculating Gross Amount
              const sgst = gstAmount / 2;
              const cgst = gstAmount / 2;
              const igst = gstAmount / 2;

              return {
                ...item,
                [column]: value,
                Amount: amount,
                GSTAmount: gstAmount,
                remainingQuantity,
                grossAmount, // ✅ Setting Gross Amount
                ...(item.sgst ? { sgst } : { igst }),
                cgst,
              };
            })()
          : item
      );

      setFirstTime(false);
      SetDataSource(newData);
    }, 300), // Debounce delay
    [dataSource, storeRemainingQn]
  );

  const renderInput = (text: any, record: any, column: any) => (
    <InputNumber
      value={text}
      step={0.1}
      onChange={(e) => {
        const newValue = Number(e);
        const remainingAmount = isSearch
          ? record.quantity
          : storeRemainingQn[record.id];
        if (newValue <= remainingAmount) {
          handleInputChange(e, record, column);
        } else {
          message.warning(`Value cannot exceed ${remainingAmount}`);
        }
      }}
    />
  );

  const handleInputChange = (e: number | null, record, column) => {
    const value = column === "Uom" ? e : Number(e);
    debouncedHandleInputChange(value, record, column);
  };

  return (
    <>
      <Table
        columns={columnsOfItems}
        dataSource={dataSource}
        pagination={{ pageSize: 8, position: ["bottomLeft"] }}
        scroll={{ x: 1350 }}
      />
    </>
  );
};

export default ReturnCropTable;
