import React, { useEffect, useState } from "react";
import "./dashboardPage.scss";
import { Layout, notification } from "antd";
import Sidebar from "./sidebar/sideBar";
import DashboardHeader from "./header/header";
import AddUser from "./profile/addUser";
import BarChart from "./chart/barChart";
import Footer from "./footer/footer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./state/store";
import { fetchUserMasterData, getOrgDetails } from "./state/slices/mainSlice";
const { Sider, Content } = Layout;
const DashboardPage = () => {
const dispatch = useDispatch<AppDispatch>();
const orgId: string = localStorage.getItem("orgId")!;
const sk = `items#${orgId.slice(-5)}`;
 useEffect(() => {
    dispatch(getOrgDetails(orgId));
    dispatch(fetchUserMasterData({ id: orgId, sk }));
  }, []);
  return (
    <Layout className="dashboard-layout">
      <Sider width={200} className="sider">
        <Sidebar />
      </Sider>
      <Layout className="content-layout">
        <DashboardHeader />
        <Content className="content">
          <div className="dashboard-container">
            <BarChart />
          </div>
          <Footer />
        </Content>
      </Layout>
    </Layout>
  );
};
export default DashboardPage;
