import React, { useEffect, useState, useMemo } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Table,
  message,
  Popconfirm,
  Modal,
  Col,
  Row,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import {
  BASE_URL,
  fetchUserDetails,
  saveSalesReturnData,
  saveUpdateInvoices,
  getSequenceNumber,
} from "../../state/api";
import SearchDocComponent from "../searchDocComponent";
import { handleSrPdf } from "./generateSalesReturnPdf";
import { getUserDetails } from "../../state/slices/mainSlice";
import { DeleteFilled } from '@ant-design/icons';
interface Item {
  items: any[];
  orderNo: any;
  pk: string;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  sk: string;
  id: string;
}

const SalesReturn = () => {
  const [dataSourceSrData, setDataSourceSrData] = useState<any>([]);
  const [modalSrData, setModalSrData] = useState<Item[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [number, setNumber] = useState("");
  const docType = "SR";
  const [isSearch, setIsSearch] = useState(false);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [additionalData, setAdditionalData] = useState({
    gstin: "",
    address: "",
    pincode: "",
    phone: "",
  });
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    name: "",
    address: "",
    pincode: "",
    gstin: "",
    userCode: "",
    ItemListData: [],
  });
  const [pdfData, setPdfData]: any = useState();
  const [userSk, setUserSk] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const orgProfileData = useSelector((state: RootState) => state.main.orgProfileDetails);
  const orgId: string = localStorage.getItem("orgId")!;
  useEffect(() => {
    
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    dispatch(getUserDetails(orgId));
  }, [dispatch]);
  
  useEffect(() => {
    console.log(userFormValues, "userFormValues inside useEffect");
    setDataSourceSrData(userFormValues.ItemListData);
  }, [isSearch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message); 
      }
    };

    fetchSequenceNumber();
  }, [orgId, docType, form]);

  const handleModalFormSubmit = (selectedItem: any) => {
    console.log(selectedItem, "selectedItem inside");
    if (
      selectedItem &&
      selectedItem.ItemListData &&
      selectedItem.ItemListData.length > 0
    ) {
      console.log(selectedItem.ItemListData, "selectedItem.ItemListData");
      setDataSourceSrData(selectedItem.ItemListData);
      console.log(dataSourceSrData, "dataSourceSrData inside");
      console.log(selectedItem, "selectedItem");
      form.setFieldsValue({
        userCode: selectedItem.userCode,
        name: selectedItem.name,
        phone: selectedItem.phone,
      });
      setAdditionalData({
        gstin: selectedItem.gstin,
        address: selectedItem.address,
        pincode: selectedItem.pincode,
        phone: selectedItem.phone,
      });
      setIsModalVisible(false);
    } else {
      console.error(
        "Selected item or ItemList is undefined or empty",
        selectedItem
      );
    }
  };

  const renderInput = (text: any, record: any, column: any) => (
    <Input
      value={text}
      onChange={(e) => handleInputChange(e, record, column)}
    />
  );

  const handleInputChange = (e: any, record: any, column: any) => {
    const value = column === "uom" ? e.target.value : Number(e.target.value);
    const newData = dataSourceSrData.map((item: any) =>
      item.key === record.key
        ? {
            ...item,
            [column]: value,
            Amount:
              column !== "uom"
                ? value * (column === "quantity" ? item.rate : item.quantity)
                : item.Amount,
            GSTAmount:
              column !== "uom"
                ? (value *
                    (column === "quantity" ? item.rate : item.quantity) *
                    item.gst) /
                  100
                : item.GSTAmount,
            Total:
              column !== "uom"
                ? value * (column === "quantity" ? item.rate : item.quantity) +
                  (value *
                    (column === "quantity" ? item.rate : item.quantity) *
                    item.gst) /
                    100
                : item.Amount + item.GSTAmount,
          }
        : item
    );
    console.log("Updated Data: ", newData);
    setDataSourceSrData(newData);
  };

  const columnsOfItems = [
    { title: "SlNo", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "hsncode", key: "hsncode" },
    {
      title: "ItemName",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, record: any) => renderInput(text, record, "quantity"),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text: any, record: any) => renderInput(text, record, "rate"),
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
      render: (text: any, record: any) => renderInput(text, record, "Uom"),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "GST%",
      dataIndex: "gst",
      key: "gst",
    },
    {
      title: "GST Amount",
      dataIndex: "GSTAmount",
      key: "GSTAmount",
    },
    {
      title: "Total",
      dataIndex: "grossAmount",
      key: "grossAmount",
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Popconfirm
          title="Are you sure to delete this item?"
          onConfirm={() => handleDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
           <DeleteFilled/>
        </Popconfirm>
      ),
    },
  ];
  const totals = useMemo(() => {
    const totalQuantity = dataSourceSrData
      .reduce((sum: any, item: { quantity: any }) => sum + item.quantity, 0)
      .toFixed(2);
    const totalGross = dataSourceSrData
      .reduce((sum: any, item: { Amount: any }) => sum + item.Amount, 0)
      .toFixed(3);
    const totalGst = dataSourceSrData
      .reduce((sum: any, item: { GSTAmount: any }) => sum + item.GSTAmount, 0)
      .toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);
    return { totalQuantity, totalGross, totalGst, total };
  }, [dataSourceSrData]);

  const handleDelete = (key: React.Key) => {
    console.log(key, "key");
    const newData = dataSourceSrData.filter((item: Item) => item.id !== key);
    setDataSourceSrData(newData);
  };
  const columnsOfItemsModal = [
    {
      title: "Sales Return No",
      dataIndex: "number",
      key: "number",
    },
    { title: "Date", dataIndex: "date", key: "date" },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Button type="primary" onClick={() => handleModalFormSubmit(record)}>
          Select
        </Button>
      ),
    },
  ];

  console.log(modalSrData, "modalSrData");

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleSave = (values: any) => {
    console.log("Form Values:", values);
    
    console.log(additionalData, "additionalData");
    const payload = {
      pk: orgId,
      ...userFormValues,
      ...values,
      ItemListData: dataSourceSrData,
      ...(!isSearch && { ...additionalData }),
    };
    console.log("Saving all details:", payload);
    isSearch
      ? dispatch(saveUpdateInvoices(payload))
      : dispatch(saveSalesReturnData(payload, setUserSk));
    setShowPrintPdf(true);
    setPdfData({ totals, ...payload });
    message.success("All data saved successfully");
  };
  console.log(userSk, "userSk");
  const printPdf = () => {
    const payload2 = {
      ...pdfData,
      userSk,
      ...orgProfileData,
    };
    console.log("im here items", pdfData);
    handleSrPdf(payload2); 
    form.resetFields();
  };

  console.log(additionalData, "additionalData");

  const handleCancelTotal = () => {
    form.resetFields();
    setDataSourceSrData("");
    setUserFormValues({
      phone: "",
      date: "",
      name: "",
      address: "",
      pincode: "",
      gstin: "",
      userCode: "",
      ItemListData: [],
    });
  };
  console.log(userFormValues, "userFormValues");

  const handleSelectBs = async () => {
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getSupplierPoData?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getSales?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    console.log(userDetails, "userDetails");
    setModalSrData(userDetails);
  };

  return (
    <div className="salesReturn">
      <SearchDocComponent
        docType={"SR"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form form={form} initialValues={userFormValues} onFinish={handleSave}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input
                placeholder="No"
                value={number} 
                readOnly={true} 
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input the data number",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please Enter 10 digit number only",
                },
              ]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Date"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please select the date",
                },
              ]}
            >
              <DatePicker format="YYYY-MM-DD HH:mm:ss"  />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input placeholder="Customer Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label=" Supplier Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input the Supplier Name",
                },
              ]}
            >
              <Input placeholder="Supplier Name" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          disabled={isSearch ? true : false}
          onClick={handleSelectBs}
          type="primary"
        >
          Select Invoice
        </Button>
        <Modal
          title="Search For Invoice"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Table
            columns={columnsOfItemsModal}
            dataSource={modalSrData}
            pagination={{ pageSize: 8, position: ["bottomLeft"] }}
            scroll={{ x: "max-content" }}
          />
        </Modal>
        <Table
          columns={columnsOfItems}
          dataSource={dataSourceSrData}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: "max-content" }}
        />
     
        <Button onClick={form.submit} type="default">
          Save
        </Button>
        <Button type="primary" disabled={!showPrintPdf} onClick={printPdf}>
          Download Pdf
        </Button>
        <Button type="primary" onClick={handleCancelTotal}>
          Cancel
        </Button>
      </Form>
    </div>
  );
};
export default SalesReturn;
