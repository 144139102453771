import { Button, Form, Input, Modal, Select } from "antd";
import React from "react";


interface ItemType {
    key: string;
    name: string;
    category: string;
    variety: string;
    grade: string;
    gst: string;
    hsncode: string;
    uom: string;
  }

const AddItemModal = ({
  isModalVisible,
  handleCancel,
  handleSaveForm2,
  formTwo,
  userMasterList
}) => {
    const { Option } = Select;
  const addItemArray = [
    {
      name: "name",
      required: true,
      label: "Item Name",
      isDropdown: true,
    },
    {
      name: "quantity",
      required: true,
      label: "Quantity",
      rules: [
        {
          required: true,
          message: "Please input Quantity!",
        },
        {
          pattern: /^\d{1,5}(\.\d{1,4})?$/,
          message:
            "Please enter quantity upto 5 digits and 4 decimal places only",
        },
      ],
    },
    {
      name: "QtyInBags",
      label: "Qty In Bags",
    },
    {
      name: "rate",
      required: true,
      label: "Rate",
      rules: [
        {
          required: true,
          message: "Please input Rate!",
        },
        {
          pattern: /^\d{1,8}(\.\d{1,3})?$/,
          message: "Please enter the rate up to 8 digits",
        },
      ],
    },
  ];

  return (
    <>
      <Modal
        title={"Add Item"}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={350}
      >
        <Form form={formTwo} onFinish={handleSaveForm2}>
          {addItemArray.map((item) => (
            <Form.Item
              key={item.name}
              name={item.name}
              label={item.label}
              rules={
                item.rules || [
                  {
                    required: item.required,
                    message: `Please input ${item.label}!`,
                  },
                ]
              }
            >
              {item.isDropdown ? (
                <Select
                  placeholder={`Select ${item.label}`}
                  style={{ width: 150 }}
                >
                  {userMasterList.map((item: ItemType) => (
                    <Option
                      key={item.name}
                      value={item.name}
                      scroll={{ x: "max-content" }}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Input
                  className="customInput"
                  placeholder={`Enter ${item.label}`}
                  style={{ width: 150 }}
                />
              )}
            </Form.Item>
          ))}
          <Form.Item
            name="uom"
            label="UOM"
            rules={[
              {
                required: true,
                message: "Please input UOM!",
              },
            ]}
          >
            <Select
              defaultValue="UOM"
              style={{ width: 120 }}
              options={[
                { value: "kg", label: "Kg" },
                { value: "ton", label: "Ton" },
                { value: "quintal", label: "Quintal" },
              ]}
              // mode='tags'//added
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button type="primary" htmlType="submit">
              Save Item
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddItemModal;
