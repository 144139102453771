import React, { useEffect } from "react";
import { Layout, Button, Tooltip, Avatar } from "antd";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { BellFilled, LogoutOutlined } from "@ant-design/icons";
const { Header } = Layout;

import { AppDispatch, RootState } from "../state/store";
import { useDispatch, useSelector } from "react-redux";

import "./header.scss";
import { getOrgDetails } from "../state/slices/mainSlice";

const DashboardHeader: React.FC = () => {
  const { signOut } = useAuthenticator();
  const location = useLocation();
  const orgProfile = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  console.log(orgProfile, "orgProfile");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const orgId: string = localStorage.getItem("orgId")!;
    dispatch(getOrgDetails(orgId));
  }, []);

  const showBackButton = location.pathname !== "/dashboard";

  const handleBackToDashboard = () => {
    navigate("/dashboard"); // Navigate to the dashboard
  };
  const handleSignOut = async () => {
    try {
      signOut();
      navigate("/");
      localStorage.clear();
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const organizationName = orgProfile?.organizationName || "Organization Name";
  const organisationInitial = orgProfile?.organizationName
    ? orgProfile.organizationName.charAt(0).toUpperCase()
    : "O";

  const truncateName = (organizationName: string) => {
    const maxLength = 8;
    console.log("maximum length", maxLength);
    console.log("organizationName", organizationName);
    if (organizationName.length > maxLength) {
      return `${organizationName.substring(0, maxLength)}...`;
    }
    return organizationName;
  };

  return (
    <Header className="header">
      <div className="header-content">
        {showBackButton && (
          <Button className="button-85" onClick={handleBackToDashboard}>Back to Dashboard</Button>
        )}
        <div className="buttons-container">
          <Tooltip title="Notifications">
            <Button
              shape="circle"
              size="large"
              icon={<BellFilled style={{ fontSize: "large" }} />}
            />
          </Tooltip>
          <Tooltip title="LogOut">
            <Button
              icon={<LogoutOutlined style={{ fontSize: "large" }} />}
              size="large"
              shape="circle"
              onClick={handleSignOut}
            />
          </Tooltip>
        </div>
        <div className="profile-container">
          <div className="profile-info">
            <div className="organization-name">
              <Tooltip title={organizationName}>
                <span>{truncateName(organizationName)}</span>
              </Tooltip>
            </div>
            <Tooltip title="profile">
              <Link to="/profile">
                <Avatar
                  className="profile-avatar-button"
                  size="large"
                  style={{ backgroundColor: "#87d068" }}
                  src={orgProfile?.profileImage || undefined}
                >
                  {!orgProfile?.profileImage && organisationInitial}
                </Avatar>
              </Link>
            </Tooltip>
          </div>
        </div>
      </div>
    </Header>
  );
};

export default DashboardHeader;
