import React, { useState } from "react";
import {
  Modal,
  Input,
  Button,
  Form,
  Select,
  Radio,
  message,
  notification,
} from "antd";
import { addUserProfileThunk } from "../state/slices/mainSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../state/store";
const { Option } = Select;
interface userDetails {
  name: string;
  user: string;
  userType: string;
  institutionSubType?: string;
  gstin?: string;
  phone: string;
  deliveryAddress: string;
  address: string;
  pincode: string;
}
interface addUserProps {
  isAddUser: boolean;
  setAddUser: Function;
}
function AddUser(props: addUserProps) {
  const { isAddUser, setAddUser } = props;
  const [form] = Form.useForm();
  const [userType, setUserType] = useState(null);
  const [individual, setIndividual] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;
  const [gstin, setGstin] = useState("");
  const handleCancelAddUser = () => setAddUser(false);
  const handleSubmit = (values: userDetails) => {
    console.log("Form values:", values);
    const payload = {
      ...values,
      pk: orgId,
    };
    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to save?",
      onOk: async () => {
        try {
          await dispatch(addUserProfileThunk(payload)).unwrap();
          form.resetFields();
          message.success("Details saved successfully");
        } catch (error) {
          message.error("Failed to save details");
        }
      },
      onCancel: () => {
        message.info("Changes were not saved");
      },
    });
  };
  const handleUserTypeChange = (value) => {
    setUserType(value);
    if (value === "individual") {
      setIndividual(true);
      form.setFieldsValue({ gstin: null });
    } else {
      setIndividual(false);
    }
  };
  const handleChange = (e) => {
    // Convert input value to uppercase
    const value = e.target.value.toUpperCase();
    console.log("Updated GSTIN Value:", value);
    setGstin(value);
  };
  const handleOk = () => {
    console.log('Submitted');
    setAddUser(true);
  };
  return (
    <Modal
      title="Add User"
      open={isAddUser}
      onCancel={handleCancelAddUser}
      footer={null}
      onOk={handleOk}
      width={400}
      style={{ top: '50%', transform: 'translateY(-50%)', margin: '0 auto' }} 
      
    >
      <Form form={form} layout="horizontal" onFinish={handleSubmit}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: "Please enter your name" },
            {
              pattern: /^[A-Za-z\s]+$/,
              message: "Name can only contain letters and spaces",
            },
          ]}
        >
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item
          label="User"
          name="user"
          rules={[{ required: true, message: "Please select user type" }]}
        >
          <Radio.Group onChange={(e) => setUserType(e.target.value)}>
            <Radio value="customer">Customer</Radio>
            <Radio value="supplier">Supplier</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="User Type"
          name="userType"
          rules={[{ required: true, message: "Please select user type" }]}
        >
          <Select
            placeholder="Select user type"
            onChange={handleUserTypeChange}
          >
            <Option value="institution">Institution</Option>
            <Option value="individual">Individual</Option>
          </Select>
        </Form.Item>
        {userType === "institution" && (
          <Form.Item
            label="Institution Subtype"
            name="institutionSubType"
            rules={[
              { required: true, message: "Please select institution subtype" },
            ]}
          >
            <Select placeholder="Select institution subtype">
              <Option value="fpo">FPO</Option>
              <Option value="agent">Agent</Option>
              <Option value="wholesaler">Wholesaler</Option>
              <Option value="retailer">Retailer</Option>
              <Option value="mills">Mills</Option>
              <Option value="dealer">Dealer</Option>
            </Select>
          </Form.Item>
        )}
        {!individual && (
          <Form.Item
            label="GSTIN"
            name="gstin"
            rules={[
              { required: true, message: "Please enter GSTIN" },
              {
                pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/,
                message: "Invalid GSTIN format",
              },
            ]}
          >
            <Input
              value={gstin}
              onChange={handleChange}
              placeholder="Enter GSTIN"
              style={{ width: "160px" }}
              // style={{ textTransform: 'uppercase' }}
            />
          </Form.Item>
        )}
        <Form.Item label="Phone" name="phone">
          <Input placeholder="Enter phone number" style={{ width: "120px" }} />
        </Form.Item>
        <Form.Item
          label="Address"
          name="address"
          rules={[
            {
              required: true,
              message: "Please enter your address!",
            },
            {
              pattern: /^.{1,200}$/,
              message: "Please enter a valid address!",
            },
          ]}
        >
          <Input placeholder="Enter Address"/>
        </Form.Item>
        <Form.Item
          label="Pincode"
          name="pincode"
          rules={[
            { required: true, message: "Please enter pincode" },
            {
              pattern: /^[1-9][0-9]{5}$/,
              message: "Please enter a valid pincode",
            },
          ]}
        >
          <Input placeholder="Enter pincode" style={{ width: "80px" }} />
        </Form.Item>

        <Form.Item
          label="Delivery Address"
          name="deliveryAddress"
          required={false}
        >
          <Input placeholder="Enter delivery address" />
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ width: "150px" }}
            >
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default AddUser;
