import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ToWords } from "to-words";

export const handleSrPdf = (pdfData) => {
  const generateHTMLContent = (pdfData) => {
    const ItemListData = pdfData.ItemListData;
    const { totals } = pdfData;
    const { organizationName, address, gstin } = pdfData.orgProfileData;
    const heading = `Credit Note`;
    const toWords = new ToWords();
    let words = toWords.convert(totals.total);
    const tableRows = ItemListData.map(
      (item, index) => `
        <tr>
          <td>${index + 1}</td>
          <td>${item.name}</td>
          <td>${item.returnQuantity}</td>
       
          <td>${item.rate}</td>
          <td>${totals.total}</td>
        </tr>
      `
    ).join("");

    return `
     <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Credit-Note-Template</title>
    <style>
        body {
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    color: #333;
    border: 2px solid black;
}
.header {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 3px solid black;
}
.headingComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.heading {
    font-size: 15px;
    border-bottom: 3px solid black;
    padding: 10px;
    width: 96.5vw;
    text-align: center;
    margin: 0 auto;
}
.subHeading li {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 530;
    font-size: 30px;
    padding: 5px;
}
#subHeading1 {
    font-size: 20px;
    padding: 6px;
    font-weight: 520;
}
.secondRow {
    display: flex;
    border-bottom: 3px solid black;
    margin: 0;
}
.secondRowList {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px;
    font-size: 13px;
    margin-top: -20px;
    margin-bottom: 0;
    font-weight: 500;
}
.secondRowList li {
    padding: 2px;
    margin: 2px;
    font-weight:300;
}
.secondFirstCol {
    flex: 1;
    font-size: 25px;
    font-weight: 500;
    border-right: 3px solid black;
}
.secondFirstCol h6{
        display: flex;
        justify-content:left;
        align-items: left;
        border-bottom: 3px solid black;
        margin-top: 0;
        padding: 10px;
}
.secondSecondCol {
    flex: 1;
    border-right: 3px solid black;
    font-size: 25px;
    font-weight:700;
}
.secondSecondCol h6{
    display: flex;
    justify-content:right;
    align-items:right;
    border-bottom: 3px solid black;
    margin-top: 0;
    padding: 10px;
}
table{
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 2px solid black;
    padding: 8px;
    text-align: left;
    font-weight: 900;
}
td {
    font-size: 13px;
    font-weight:700;
}
th {
    background-color: #fff;
    font-size: 14px;
    font-weight:900;
}
.specBlock {
    width: 40vw;
    font-size: 10px;
    margin: 10px auto;
    text-align: center;
    padding: 10px;
}
.specBlock strong {
    text-align: right;
    font-weight: 700;
    font-size:15px;
}
#firstcol{
    width:53.6%;
}
#secondcol{
    width:27%;
}
.secondRowList #secondRowname{
    font-weight: bold;
}
#total {
    text-align: right;
}
</style>
</head>
<body>
    <div class='header'>
       <div class='headingComponent'>
            <h3 class='heading'>
                ${heading}
            </h3>
            <h3 class='subHeading'>
                <li>${organizationName}</li>
                <li id="subHeading1">${address}</li>
                <li id="subHeading1">${gstin}
                </li>
            </h3>
        </div>
    </div>
    <div class='secondRow'>
        <div class='secondFirstCol'>
            <h6>No:${pdfData.sk}</h6>
            <ul class="secondRowList">
                <li >From:${pdfData.name}</li>
                <li>Address:${pdfData.deliveryAddress}</li>
                <li>Zip:${pdfData.pincode}</li>
                <li>GSTIN:${pdfData.gstin}</li>
            </ul>
        </div>
        <div class='secondSecondCol'>
            <h6>Invoice Date:${pdfData.date}</h6>
            <ul class="secondRowList">
                <li>Refernce :</li>
                <li>Reason for Return:</li>
            </ul>
        </div>
  </div>
  <div class="table-container1">
    <table>
        <thead>
            <tr>
                <th>Sl. No</th>
                <th>Description</th>
                <th>Qty</th>
                <th>Rate</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
           ${tableRows}
           <tr class="total-row">
                <td id="total" colspan="2">Total:</td>
                <td>${totals.totalQuantity}</td>
                <td></td>
                <td>${totals.total}</td>
            </tr>
        </tbody>
    </table>
  </div>
  <div class="table-container2">
    <table>
        <tbody>
            <tr>
                <td id="firstcol"></td>
                <td  id="secondcol">Discount</td>
                <td >0.00</td>
            </tr>
            <tr><td></td>
                <td >Freight</td>
                <td >0.00</td>
            </tr>
            <tr class="total-row">
                <td id="firstcol"></td>
                <td colspan="1">Total:</td>
                <td>${totals.total}</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="bottomRow">
    <div class="specBlock">
        <div>
           <strong>For SAMPARKBINDHU SOLUTIONS PRIVATE LIMITED</strong>
        </div>
    </div>
</div>
</body>
</html>

`;
  };
  const htmlContent = generateHTMLContent(pdfData);
  const blob = new Blob([htmlContent], { type: "text/html" });
  const url = URL.createObjectURL(blob);
  const iframe = document.createElement("iframe");
  iframe.style.width = "320mm";
  iframe.style.height = "297mm";
  document.body.appendChild(iframe);
  iframe.onload = async () => {
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write(htmlContent);
    iframeDocument.close();
    const canvas = await html2canvas(iframeDocument.body, {
      scale: 2, // Increase scale to improve quality
      width: iframeDocument.body.scrollWidth,
      height: iframeDocument.body.scrollHeight,
    });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(
      imgData,
      "PNG",
      0,
      0,
      210,
      (210 * canvas.height) / canvas.width
    );
    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `${pdfData.sk}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    document.body.removeChild(iframe);
    URL.revokeObjectURL(url);
    URL.revokeObjectURL(pdfUrl);
  };

  iframe.src = url;
};
