import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  PopconfirmProps,
  Radio,
  Row,
  Select,
  Table,
  message,
} from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { AppDispatch, RootState } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import {
  addItem,
  deleteItem,
  getUserDetails,
  removeAllItems,
} from "../../state/slices/mainSlice";
import SearchDocComponent from "../searchDocComponent";
import {
  saveBillOfSupply,
  saveUpdateInvoices,
  getSequenceNumber,
} from "../../state/api";
import { handleBillOfSupplyPdf } from "./generateBillSupplyPdf";
import AddUser from "../../profile/addUser";

interface propsItem {
  taxInvoice: boolean;
}

interface ItemType {
  key: string;
  name: string;
  category: string;
  variety: string;
  grade: string;
  gst: string;
  hsncode: string;
  uom: string;
}

const BillOfsupply = (props: propsItem) => {
  const [number, setNumber] = useState("");
  const [isAddUser, setAddUser] = useState<boolean>(false);
  const { taxInvoice } = props;
  const docType = taxInvoice ? "TI" : "BOS";
  const customersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.customers
  );
  const userMasterList = useSelector(
    (state: RootState) => state.main.userMasterListData
  );
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [uomValue, setUomValue] = useState("");
  const [showAddItem, setShowAddItem] = useState(false);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const [userSk, setUserSk] = useState();
  const [pdfData, setPdfData]: any = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [form] = Form.useForm();
  const [formTwo] = Form.useForm();
  const { Option } = Select;
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    address: "",
    PoDate: "",
    pincode: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
    name: "",
    taxInvoice: "",
  });

  const ItemListData = useSelector((state: RootState) => state.main.itemList);
  const orgProfileData = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  //   const BASE_URL = process.env.REACT_APP_BASE_URL;
  //   const STAGE = process.env.REACT_APP_ENV;

  useEffect(() => {
    dispatch(getUserDetails(orgId));
  }, [dispatch]);

  useEffect(() => {
    console.log(userFormValues, "before set");

    form.setFieldsValue(userFormValues);
    console.log(userFormValues, "after set");
  }, [userFormValues]);

  useEffect(() => {
    console.log(userFormValues, "userFormValues inside useEffect");
    if (isSearch) {
      userFormValues.ItemListData.forEach((item, index) => {
        setShowAddItem(true);
      });
    }
  }, [isSearch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSequenceNumber();
  }, [orgId, docType, form]);

  const addItemArray = [
    {
      name: "name",
      required: true,
      label: "Item Name",
      isDropdown: true,
    },
    {
      name: "quantity",
      required: true,
      label: "Quantity",
      rules: [
        {
          required: true,
          message: "Please input Quantity!",
        },
        {
          pattern: /^\d{1,5}(\.\d{1,4})?$/,
          message:
            "Please enter quantity upto 5 digits and 4 decimal places only",
        },
      ],
    },
    {
      name: "QtyInBags",
      label: "Qty In Bags",
    },
    {
      name: "rate",
      required: true,
      label: "Rate",
      rules: [
        {
          required: true,
          message: "Please input Rate!",
        },
        {
          pattern: /^\d{1,8}(\.\d{1,3})?$/,
          message: "Please enter the rate up to 8 digits",
        },
      ],
    },
  ];

  console.log(userFormValues, "userFormValues");

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    console.log(e);
    message.error("Click on No");
  };

  const confirm: PopconfirmProps["onConfirm"] = (record: any) => {
    dispatch(deleteItem(record.id));
    message.success("Click on Yes");
  };

  const handleSelectItem = (record) => {
    console.log(record, "record inside select user");
    const { sk, userType, institutionSubType, user, ...restRecord } = record;
    setUserFormValues(restRecord);
    setIsUserModalVisible(false);
  };

  const columsOfUsers = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "UserCode",
      dataIndex: "userCode",
      key: "userCode",
    },
    {
      title: "UserType",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: " SubType",
      dataIndex: "institutionSubType",
      key: "institutionSubType",
    },
    {
      title: "GSTIN",
      dataIndex: "gstin",
      key: "gstin",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Pincode",
      dataIndex: "pincode",
      key: "pincode",
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];

  const columnsOfItems = [
    { title: "SL.No", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "hsncode", key: "hsncode" },
    { title: "ItemName", dataIndex: "name", key: "name" },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "Rate", dataIndex: "rate", key: "rate" },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
    },
    {
      title: "QtyInBags",
      dataIndex: "quantityInBags",
      key: "quantityInBags",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    { title: "GST %", dataIndex: "gst", key: "gst" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    { title: "Total", dataIndex: "grossAmount", key: "grossAmount" },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <>
          <Popconfirm
            title="Delete the task"
            onConfirm={() => confirm(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled />
          </Popconfirm>
        </>
      ),
    },
  ];
  const totals = useMemo(() => {
    const totalQuantity = ItemListData.reduce(
      (sum, item) => sum + item.quantity,
      0
    ).toFixed(2);
    const totalGross = ItemListData.reduce(
      (sum, item) => sum + item.Amount,
      0
    ).toFixed(3);
    const totalGst = ItemListData.reduce(
      (sum, item) => sum + item.GSTAmount,
      0
    ).toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);

    return { totalQuantity, totalGross, totalGst, total };
  }, [ItemListData]);

  const handleChange = (value: string, record: any) => {
    console.log(`selected ${value}`);
    setUomValue(value);
  };

  const handleSaveForm2 = (values: any) => {
    console.log("values", values);
    const itemFromList: any = userMasterList.find(
      (item: ItemType) => item.name === values.name
    );
    console.log(itemFromList, "itemFromList");
    const itemAmount = values.quantity * parseFloat(values.rate);
    const GstAmount = (parseFloat(itemFromList.gst) * itemAmount) / 100;
    const grossAmount = GstAmount + itemAmount;
    if (itemFromList) {
      const mergedItem = {
        ...itemFromList,
        slno: ItemListData.length + 1,
        quantity: parseFloat(values.quantity),
        Amount: itemAmount,
        quantityInBags: values.QtyInBags,
        rate: values.rate,
        GSTAmount: GstAmount,
        grossAmount: grossAmount,
      };
      console.log(mergedItem, "mergedItem");
      dispatch(addItem(mergedItem));
      setShowAddItem(true);
      message.success("Item Added Successfully");
      formTwo.resetFields();
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    formTwo.resetFields();
    setIsModalVisible(false);
  };
  const handleUserCancel = () => {
    formTwo.resetFields();
    setIsUserModalVisible(false);
  };

  const handleMasterCancel = () => {
    setIsUserModalVisible(false);
  };

  const saveAllDetails = async (values: any) => {
    console.log(userFormValues.customerCode, "customerCode");
    console.log(userFormValues.pincode, "zip");
    console.log(values, "values");
    const payLoad = {
      ...userFormValues,
      ...values,
      ItemListData,
      taxInvoice,
      ...totals,
      pk: orgId,
    };
    message.success("All data saved successfully");
    isSearch
      ? dispatch(saveUpdateInvoices(payLoad))
      : dispatch(saveBillOfSupply(payLoad, setUserSk));
    console.log("im here after save", payLoad);
    const sk = isSearch ? null : userSk;
    setPdfData(payLoad, sk);
    setShowPrintPdf(true);
    form.resetFields();
    dispatch(removeAllItems());
    setIsSearch(false);
  };

  const printPdf = () => {
    const payload2 = {
      ...pdfData,
      ...orgProfileData,
    };
    handleBillOfSupplyPdf(payload2);
    form.resetFields();
  };

  const handleAddUserClick = () => {
    setAddUser(true);
  };

  return (
    <div className="billOfSupply">
      <SearchDocComponent
        docType={taxInvoice ? "TI" : "BOS"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form form={form} onFinish={saveAllDetails}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input placeholder="No" value={number} readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true, message: "Please input the Date!" }]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input the Name!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Name"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="PoNo"
              label="PO No."
              rules={[{ required: true, message: "Please input the PO No.!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Po No"}
                style={{ width: 150 }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="PoDate"
              label="PO Date"
              rules={[{ required: true, message: "Please input the PO Date!" }]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: "Please input the Address!" }]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Address"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="gstin" label="GSTIN">
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Gstin"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="With Gst"
              name="Gst"
              rules={[{ required: true, message: "Please input the GST!" }]}
            >
              <Radio.Group
                className="custom-radio"
                name="Gst"
                defaultValue="Yes"
              >
                <Radio value={"Yes"}>Yes</Radio>
                <Radio value={"No"}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input placeholder="User Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input the phone number",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Please Enter 10 digit number only",
                },
              ]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add Item
        </Button>
        <Button
          onClick={() => {
            setIsUserModalVisible(true);
          }}
        >
          Add User
        </Button>
        <Modal
          title={"Add Item"}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={350}
        >
          <Form form={formTwo} onFinish={handleSaveForm2}>
            {addItemArray.map((item) => (
              <Form.Item
                key={item.name}
                name={item.name}
                label={item.label}
                rules={
                  item.rules || [
                    {
                      required: item.required,
                      message: `Please input ${item.label}!`,
                    },
                  ]
                }
              >
                {item.isDropdown ? (
                  <Select
                    placeholder={`Select ${item.label}`}
                    style={{ width: 150 }}
                  >
                    {userMasterList.map((item: ItemType) => (
                      <Option
                        key={item.key}
                        value={item.name}
                        scroll={{ x: "max-content" }}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Input
                    className="customInput"
                    placeholder={`Enter ${item.label}`}
                    style={{ width: 150 }}
                  />
                )}
              </Form.Item>
            ))}
            <Form.Item
              name="uom"
              label="UOM"
              rules={[
                {
                  required: true,
                  message: "Please input UOM!",
                },
              ]}
            >
              <Select
                defaultValue="UOM"
                style={{ width: 120 }}
                onChange={handleChange}
                options={[
                  { value: "kg", label: "Kg" },
                  { value: "ton", label: "Ton" },
                  { value: "quintal", label: "Quintal" },
                ]}
                // mode='tags'//added
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button type="primary" htmlType="submit">
                Save Item
              </Button>
            </div>
          </Form>
        </Modal>
        <Modal
          title="Select a User"
          open={isUserModalVisible}
          onCancel={handleMasterCancel}
          footer={null}
          width={900}
          style={{ marginTop: "-80px" }}
        >
          <div>
            <Button onClick={handleAddUserClick}>Add User</Button>
          </div>

          <Table
            dataSource={customersDetails}
            columns={columsOfUsers}
            rowKey="id"
            pagination={{ pageSize: 5 }}
            scroll={{ x: 600 }}
          />
        </Modal>
        <Table
          columns={columnsOfItems}
          dataSource={showAddItem ? ItemListData : []}
          pagination={{ pageSize: 8, position: ["bottomLeft"] }}
          scroll={{ x: 1350 }}
        />
        <div className="totals">
          <div className="total-item">
            <strong>Total Quantity:</strong>
            <span>{totals.totalQuantity}</span>
          </div>
          <div className="total-item">
            <strong>Total Gross:</strong>
            <span>{totals.totalGross}</span>
          </div>
          <div className="total-item">
            <strong>Total Gst:</strong>
            <span>{totals.totalGst}</span>
          </div>
          <div className="total-item">
            <strong>Total:</strong>
            <span>{totals.total}</span>
          </div>
        </div>
        <Button htmlType="submit">Save</Button>
        <Button disabled={!showPrintPdf} onClick={printPdf}>
          DownLoad Pdf
        </Button>
      </Form>
      <AddUser isAddUser={isAddUser} setAddUser={setAddUser} />
    </div>
  );
};
export default BillOfsupply;
