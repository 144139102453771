import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Input, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "./state/store";
import { updateOrgProfileThunk, getOrgDetails } from "./state/slices/mainSlice";
import "./profile.scss";
import Footer from "./footer/footer";
import { fetchUserAttributes, updateUserAttributes } from "@aws-amplify/auth";
import DashboardHeader from "./header/header";

const ProfileForm: React.FC = () => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [isEditable, setIsEditable] = useState<boolean>(true);
  const orgProfile = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  const orgProfileData = useSelector(
    (state: RootState) => state.main.orgProfileData
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const getToKnowUser = async () => {
    try {
      const userAttributes: any = await fetchUserAttributes();
      const isNewClient = userAttributes["custom:is_New_Client"];
      return isNewClient;
    } catch (error) {
      console.error("Error fetching user attributes:", error);
      return null;
    }
  };

  useEffect(() => {
    const checkUserStatus = async () => {
      const isNewClient = await getToKnowUser();
      const orgId: string = localStorage.getItem("orgId")!;
      if (isNewClient !== "yesIam" && orgId) {
        setIsEditable(false); // The user is not new
        dispatch(getOrgDetails(orgId));
      }
    };

    checkUserStatus();
  }, [dispatch]);

  useEffect(() => {
    if (orgProfileData) {
      form.setFieldsValue({
        organizationName: orgProfile.organizationName,
        email: orgProfile.email,
        gstin: orgProfile.gstin,
        phoneNumber: orgProfile.phoneNumber,
        address: orgProfile.address,
        pincode: orgProfile.pincode,
      });
      setProfileImage(orgProfile.profileImage);
    }
  }, [orgProfile, orgProfileData]);

  const [form] = Form.useForm();

  const handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target) {
          setProfileImage(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = () => {
    setProfileImage(null);
  };

  const handleCancel = () => {
    navigate("/");
  };

  const onFinish = async (values: any) => {
    const payload = {
      ...values,
      profileImage,
      orgId: localStorage.getItem("orgId"),
      orgEmail: localStorage.getItem("orgEmail"),
    };

    Modal.confirm({
      title: "Confirm",
      content: "Are you sure you want to save?",
      onOk: async () => {
        try {
          await updateUserAttributes({
            userAttributes: {
              "custom:is_New_Client": "noIamNot",
            },
          });
          await dispatch(updateOrgProfileThunk(payload)).unwrap();

          message.success("Details saved successfully");
          navigate("/dashboard");
        } catch (error) {
          message.error("Failed to save details");
        }
      },
      onCancel: () => {
        message.info("Changes were not saved");
      },
    });
  };

  return (
    <>
      <div className="profileWrapper">
        <DashboardHeader />
        <h1 className="profile-heading">My Profile</h1>
        <div className="profile-form-container">
          <div className="profile-upload">
            <input
              type="file"
              id="profile-input"
              accept="image/*"
              onChange={handleProfileChange}
              style={{ display: "none" }}
            />
            <label htmlFor="profile-input" className="profile-label">
              {profileImage ? (
                <div className="profile-image-container">
                  <img
                    src={profileImage}
                    alt="Profile"
                    className="profile-image"
                  />
                  <div className="profile-actions">
                    <Button
                      className="delete-button"
                      type="link"
                      danger
                      onClick={handleDeleteImage}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="add-profile">Add</div>
              )}
            </label>
          </div>
          {profileImage && (
            <Button
              className="edit-button"
              type="link"
              onClick={() => {
                document.getElementById("profile-input")?.click();
                setIsEditable(true);
              }}
            >
              Edit
            </Button>
          )}
          <Form
            className="profile-form"
            form={form}
            onFinish={onFinish}
            initialValues={{
              organizationName: "",
              email: "",
              gstin: "",
              phoneNumber: "",
              address: "",
              pincode: "",
            }}
          >
            <Form.Item
              label="Organization Name"
              name="organizationName"
              rules={[
                {
                  required: true,
                  message: "Please enter your organization name!",
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message:
                    "Organization Name should contain only letters and spaces.",
                },
              ]}
            >
              <Input disabled={!isEditable} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter your email!" },
                {
                  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: "Please enter a valid email address.",
                },
              ]}
            >
              <Input disabled={!isEditable} />
            </Form.Item>

            <Form.Item
              label="GSTIN"
              name="gstin"
              rules={[
                { required: true, message: "Please enter your GSTIN!" },
                {
                  pattern: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/,
                  message: "Please enter a valid GSTIN number.",
                },
              ]}
            >
              <Input disabled={!isEditable} />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true, message: "Please enter your phone number!" },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Phone Number should contain exactly 10 digits.",
                },
              ]}
            >
              <Input disabled={!isEditable} />
            </Form.Item>

            <Form.Item
              label="Address"
              name="address"
              rules={[
                { required: true, message: "Please enter your address!" },
              ]}
            >
              <Input.TextArea disabled={!isEditable} />
            </Form.Item>

            <Form.Item
              label="Pincode"
              name="pincode"
              rules={[
                { required: true, message: "Please enter your pincode!" },
                {
                  pattern: /^[0-9]{6}$/,
                  message: "Pincode should contain exactly 6 digits.",
                },
              ]}
            >
              <Input disabled={!isEditable} />
            </Form.Item>

            {!isEditable ? (
              <Button type="primary" onClick={() => setIsEditable(true)}>
                Change
              </Button>
            ) : (
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: "10px" }}
                  onClick={() => setIsEditable(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProfileForm;
