import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const convertDateFormat = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const exportToExcel = (data) => {
  const { fetchTableData, startDateString, endDateString, itemName } = data;

  if (!Array.isArray(fetchTableData)) {
    console.error("fetchTableData is not an array");
    return;
  }

  // Prepare header rows and data rows
  const headerRows = [
    ["Sampark Bindhu"],
    [`Stock Book - From ${startDateString} To ${endDateString}`],
    [`For Item ${itemName}`],
    ["Value based on: Weighted Average"],
    [],
    [
      "Sl No.",
      "Date",
      "Voucher No.",
      "Opening Stock",
      "Receipt Stock",
      "Issued Stock",
      "Closing Stock",
      "CashPaid",
      "CashReceived",
    ],
  ];

  // Format the data rows
  const dataRows = fetchTableData.map((item) => [
    item.slno,
    convertDateFormat(item.date),
    item.voucherNo,
    item.openingStock,
    item.recieptStock,
    item.issuedStock,
    item.closingStock,
    item.cashpaid,
    item.cashreceived,
  ]);
  const totalReceiptStock = fetchTableData.reduce((acc, item) => acc + item.recieptStock, 0);
  const totalIssuedStock = fetchTableData.reduce((acc, item) => acc + item.issuedStock, 0);
  const totalsRow = [
    "",
    "",
    "",
    "",
    `Total: ${totalReceiptStock}`,
    `Total: ${totalIssuedStock}`,
    "",
    "",
  ];

  // Combine header rows, data rows, and totals row
  const combinedRows = headerRows.concat(dataRows, [totalsRow]);

  // Creating worksheet from combined rows
  const worksheet = XLSX.utils.aoa_to_sheet(combinedRows);

  // Adjust column widths
  worksheet["!cols"] = [
    { wch: 10 }, // Sl No.
    { wch: 12 }, // Date
    { wch: 12 }, // Voucher No.
    { wch: 15 }, // Opening Stock
    { wch: 15 }, // Receipt Stock
    { wch: 15 }, // Issued Stock
    { wch: 15 }, // Closing Stock
    { wch: 10 }, // CashPaid
    { wch: 15 }, // CashReceived
  ];

  // Created a new workbook and append the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Inventory Data");

  // To Generate buffer and save the file
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "InventoryData.xlsx");
};
