import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import {
  addUserProfileFun,
  BASE_URL,
  fetchUserMasterDataFun,
  getOrgDetailsFun,
  getUserProfileFun,
  saveUserMasterDataFun,
  updateOrgProfile,
} from "../api";
import { dp } from "../../helper";
import { invoiceAddItem } from "../../utilsTypes";

const isDev = false;

interface Item {
  id: string;
  itemName: string;
  quantity: number;
}

export const updateOrgProfileThunk = createAsyncThunk(
  "profile/updateOrgProfile",
  async (values: any, thunkAPI) => {
    try {
      const result = await updateOrgProfile(values);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getOrgDetails = createAsyncThunk<any, string>(
  "getOrgDetails",
  async (id: string) => {
    dp(":::Fetching FO Details::::");
    const res = await getOrgDetailsFun(id);
    if (res === undefined) throw new Error("Error while fetching fo details");
    dp("fo data fetched success");
    return res.Item;
  }
);

export const addUserProfileThunk = createAsyncThunk(
  "dashBoard/updateOrgProfile",
  async (values: any, thunkAPI) => {
    try {
      const result = await addUserProfileFun(values);
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUserDetails = createAsyncThunk<any, string>(
  "getUserDetails",
  async (id: string) => {
    dp(":::Fetching FO Details::::");
    const res = await getUserProfileFun(id);
    if (res === undefined) throw new Error("Error while fetching fo details");
    dp("fo data fetched success");
    return res;
  }
);

export const fetchUserMasterData = createAsyncThunk<any, { id: string }>(
  "fetchUserMasterData",
  async ({ id }) => {
    dp(":::Fetching FO Details::::");
    const res = await fetchUserMasterDataFun(id);
    if (res === undefined) throw new Error("Error while fetching fo details");
    dp("fo data fetched success");
    return res;
  }
);

// export const saveUserMasterData = createAsyncThunk(
//   "item/saveUserMasterData",
//   async (values: any, thunkAPI) => {
//     try {
//       const result = await saveUserMasterDataFun(values);
//       return result;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const saveUserMasterData = createAsyncThunk(
  "item/saveUserMasterData",
  async (values: any, thunkAPI) => {
    const addUserProfileApi = `${BASE_URL}/dev/saveUserMasterItems`;
    // const addUserProfileApi = `http://localhost:4000/dev/saveUserMasterItems`;

    try {
      const response = await fetch(addUserProfileApi, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      const result = await response.json();

      // ✅ Dispatch another action inside thunkAPI (not directly inside payload)
      thunkAPI.dispatch(fetchUserMasterData({ id: values.pk }));

      return result; // ✅ Only return serializable data
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.message || "Failed to save user data"
      );
    }
  }
);

const loginDetailsType: any[] = [];

export interface User {
  email: string;
  email_verified: string;
  sub: string;
  "custom:is_New_Client": string;
  // Other properties...
}

export interface OrgProfile {
  organizationName: string;
  email: string;
  gstin: string;
  phoneNumber: string;
  address: string;
  pincode: string;
  profileImage: string;
  orgId: string;
  orgEmail: string;
}

interface Item {
  key: string;
  name: string;
  category: string;
  variety: string;
  grade: string;
  gst: string;
  hsncode: string;
  uom: string;
}

interface MainState {
  retryFO: number;
  masterListData: any[]; // Replace `any` with specific type if possible
  itemList: invoiceAddItem[]; // Replace `any` with specific type if possible
  loginDetails: User[]; // Use the User type here
  orgProfileData: boolean;
  orgProfileDetails: any;
  userProfileData: boolean;
  userProfileDetails: any;
  userMasterListData: any[];
  loading: boolean;
  isEmpty: boolean;
  changedItems: any[];
}

const initialState: MainState = {
  retryFO: 0,
  masterListData: [],
  itemList: [],
  changedItems: [],
  loginDetails: [],
  orgProfileData: false,
  userProfileData: false,
  orgProfileDetails: {
    organizationName: "",
    email: "",
    gstin: "",
    phoneNumber: "",
    address: "",
    pincode: "",
    profileImage: "",
    orgId: "",
    orgEmail: "",
  },
  userProfileDetails: {
    customers: [],
    suppliers: [],
  },
  userMasterListData: [],
  loading: false,
  isEmpty: true, // Start with true, indicating no data
};

const mainSlice = createSlice({
  name: "main",
  initialState: initialState,
  reducers: {
    orgloginDetails: (state, action: PayloadAction<User[]>) => {
      state.loginDetails = action.payload;
    },
    updateMasterList: (state, action) => {
      state.masterListData = action.payload;
    },
    addItem: (state, action) => {
      const newItem = { ...action.payload, id: uuidv4() };
      state.itemList = [...state.itemList, newItem];
    },
    deleteItem: (state, action) => {
      state.itemList = state.itemList.filter(
        (item: any) => item.id !== action.payload
      );
    },
    removeAllItems: (state) => {
      state.itemList = [];
    },
    addTableData: (state, action) => {
      if (!state.userMasterListData) {
        state.userMasterListData = [];
      }

      state.userMasterListData.push(action.payload);
      state.changedItems = state.changedItems || []; // Initialize if undefined
      state.changedItems.push({ ...action.payload, changeType: "add" });
    },
    updateTableData: (state, action) => {
      // Remove the old item first
      state.userMasterListData = state.userMasterListData.filter(
        (item) => item.sk !== action.payload.sk
      );

      // Add the edited item
      state.userMasterListData.push(action.payload);

      // Ensure changedItems array exists
      state.changedItems = state.changedItems || [];

      // Find index in changedItems
      const changedIndex = state.changedItems.findIndex(
        (item) => item.sk === action.payload.sk
      );

      if (changedIndex === -1) {
        // If not already in changedItems, add it
        state.changedItems.push({ ...action.payload, changeType: "update" });
      } else {
        // If already in changedItems, update it
        state.changedItems[changedIndex] = {
          ...action.payload,
          changeType: "update",
        };
      }
    },
    deleteTableData: (state, action) => {
      state.userMasterListData = state.userMasterListData.filter(
        (item) => item.key !== action.payload
      );

      // Track deletion
      state.changedItems = state.changedItems || [];
      state.changedItems.push({ key: action.payload, changeType: "delete" });
    },
    clearChangedItems: (state) => {
      state.changedItems = [];
    },
    resetState: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(getOrgDetails.rejected, (state) => {
      state.retryFO++;
    });
    builder.addCase(getOrgDetails.fulfilled, (state, action) => {
      state.orgProfileData = true;
      state.orgProfileDetails.organizationName =
        action?.payload?.organizationName;
      state.orgProfileDetails.email = action?.payload?.email;
      state.orgProfileDetails.gstin = action?.payload?.gstin;
      state.orgProfileDetails.phoneNumber = action?.payload?.phoneNumber;
      state.orgProfileDetails.address = action?.payload?.address;
      state.orgProfileDetails.pincode = action?.payload?.pincode;
      state.orgProfileDetails.profileImage = action?.payload?.profileImage;
      state.orgProfileDetails.orgId = action?.payload?.orgId;
      state.orgProfileDetails.orgEmail = action?.payload?.orgEmail;
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.retryFO++;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.userProfileData = true;
      state.userProfileDetails.customers = action.payload["customers"];
      state.userProfileDetails.suppliers = action.payload["suppliers"];
    });
    builder.addCase(fetchUserMasterData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserMasterData.fulfilled, (state, action) => {
      state.loading = false;
      state.userMasterListData = action.payload || [];
      state.isEmpty = (action.payload.length ?? 0) === 0; // If no items are returned, it's empty
    });
    builder.addCase(fetchUserMasterData.rejected, (state) => {
      state.loading = false;
      state.isEmpty = true; // If the fetch fails, assume no data
    });
  },
});
export const {
  orgloginDetails,
  updateMasterList,
  addItem,
  deleteItem,
  removeAllItems,
  updateTableData,
  deleteTableData,
  addTableData,
  clearChangedItems,
  resetState,
} = mainSlice.actions;
export default mainSlice.reducer;
