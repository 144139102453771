import React, { useState } from "react";
import { Input, Modal, Table, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { addItem } from "../state/slices/mainSlice";
import { BASE_URL } from "../state/api";

interface propsItem {
  docType: string;
  docData: Function;
  setIsSearch: Function;
}

const SearchDocComponent = (props: propsItem) => {
  const { docType, docData, setIsSearch } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  //   const BASE_URL = process.env.REACT_APP_BASE_URL;
  //   const STAGE = process.env.REACT_APP_ENV;
  const orgId: string = localStorage.getItem("orgId")!;
  const handleSearch = async () => {
    const api = `${BASE_URL}/dev/fetchDocData?orgId=${encodeURIComponent(orgId)}&docType=${encodeURIComponent(docType)}`;
    // const api = `http://localhost:4000/dev/fetchDocData?orgId=${encodeURIComponent(orgId)}&docType=${encodeURIComponent(docType)}`;

    try {
      const response = await axios.get(api);
      console.log(response, "response inside search component");
      setData(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSelectItem = (record: any) => {
    setIsSearch(true);
    docData({
      ...record,
      ...(record.date && { date: moment(record.date) }),
      ...(record.PoDate && { PoDate: moment(record.PoDate) }),
      ...(record.deliveryDueBy && {
        deliveryDueBy: moment(record.deliveryDueBy),
      }),
      ...(record.revisedOn && { revisedOn: moment(record.revisedOn) }),
      ...(record.supplierinvoicedate && {
        supplierinvoicedate: moment(record.supplierinvoicedate),
      }),
    });
    record.ItemListData.forEach((item: any, index: number) => {
      dispatch(addItem(item));
    });
    setIsModalVisible(false);
    // Further processing with the selected item
    console.log("Selected Item:", record);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
        Search
      </Button>
      <Modal
        title="Select an Item"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          pagination={false}
        />
      </Modal>
    </div>
  );
};

export default SearchDocComponent;
