import { Tabs } from "antd";
import BillOfSupply from "./salesInvoices/billOfSupply";
import SalesReturn from "./salesInvoices/salesReturn";
import PaymentReceipt from "../salesAndpurchase/salesInvoices/paymentReceipt";
import React from "react";
import PaymentDisplay from "./salesInvoices/paymentDisplay";

const { TabPane } = Tabs;
const Sales = () => {
  return (
    <div className="action">
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Bill Of Supply" key="1">
          <BillOfSupply taxInvoice={false} />
        </TabPane>
        <TabPane tab="Tax Invoice" key="2">
          <BillOfSupply taxInvoice={true} />
        </TabPane>
        <TabPane tab="Payment Receipt" key="3">
          <PaymentReceipt />
        </TabPane>
        <TabPane tab="Sales Return" key="4">
          <SalesReturn />
        </TabPane>
        <TabPane tab="Payment Details" key="5">
          <PaymentDisplay />
        </TabPane>
      </Tabs>
    </div>
  );
};
export default Sales;
