import React from "react";
import { Card, Typography, Row, Col } from "antd";
import "./landingPage.scss";
import mission from "../assets/mission.png"
import vision from "../assets/vision.png"
import aim from "../assets/aim.png"
const { Title, Paragraph } = Typography;
const Aim: React.FC = () => {
  return (
    <div id="aim" className="aim-container">
      <Title className="whyEngageAllTitle">
        <span>Why</span>EngageAll?
      </Title>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8}>
        <Card className="aim-card" hoverable>
          <img src={aim}/>
            <Title level={2} className="aim-title">
              Aim
            </Title>
            <Paragraph className="aim-text">
              An AgriTech company developing affordable and effective B2B
              Solutions for Rural Bharat.
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card className="mission-card" hoverable>
          <img src={mission}/>
            <Title level={2} className="mission-title">
              Mission
            </Title>
            <Paragraph className="mission-text">
              Ensure a fairer share of the consumer’s rupee to the Rural Source
              Points in the post-harvest space
            </Paragraph>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
        <Card className="vision-card" hoverable>
          <img src={vision}/>
            <Title level={2} className="vision-title">
              Vision
            </Title>
            <Paragraph className="vision-text">
              We take pride in partnering Government's vision of doubling
              farmer's income using technology. This is our contribution to
              nation-building and making it truly 'Atmanirbhar Bharat'.
            </Paragraph>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Aim;
