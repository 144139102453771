import React, { useEffect, useState } from "react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { fetchUserAttributes, signUp } from "@aws-amplify/auth";
import { useDispatch } from "react-redux";
import { getOrgDetails, orgloginDetails, User } from "./state/slices/mainSlice";
import { AppDispatch } from "./state/store";
import { getOrgDetailsFun } from "./state/api";

const AuthenticatorPage: React.FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { user, signOut } = useAuthenticator(); // useAuthenticator hook provides user info
  const [isLoading, setIsLoading] = useState(true);
  const services = {
    async handleSignUp(input) {
      // Custom username and email
      const { password, options } = input;
      const customEmail = options?.userAttributes?.email.toLowerCase();
      return signUp({
        username: customEmail,
        password,
        options: {
          ...input.options,
          userAttributes: {
            email: customEmail,
            "custom:is_New_Client": "yesIam",
          },
        },
      });
    },
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const userAttributes: any = await fetchUserAttributes();
          const isNewClient = userAttributes["custom:is_New_Client"];

          localStorage.setItem("orgId", JSON.stringify(userAttributes.sub));
          localStorage.setItem(
            "orgEmail",
            JSON.stringify(userAttributes.email)
          );
          if (isNewClient === "yesIam") {
            navigate("/profile");
          } else {
            navigate("/dashboard");
          }
        }
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user) {
      fetchUserData();
    } else {
      setIsLoading(false);
    }
  }, [user, navigate, dispatch]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Authenticator services={services} initialState="signIn">
      {({ signOut }) => <></>}
    </Authenticator>
  );
};

export default AuthenticatorPage;
