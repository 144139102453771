import React, { useMemo } from "react";
import { invoiceAddItem } from "../utilsTypes";

export const useTotals = (ItemListData: invoiceAddItem[]) => {
  const totals = useMemo(() => {
    const totalQuantity = ItemListData?.reduce(
      (sum, item) => sum + item.quantity,
      0
    ).toFixed(2);
    const totalGross = ItemListData?.reduce(
      (sum, item) => sum + item.Amount,
      0
    ).toFixed(3);
    const totalGst = ItemListData?.reduce(
      (sum, item) => sum + item.GSTAmount,
      0
    ).toFixed(3);
    const total = (parseFloat(totalGross) + parseFloat(totalGst)).toFixed(3);
    const totalSgstAmount = parseFloat(totalGst) / 2;
    const totalCgstAmount = parseFloat(totalGst) / 2;
    const totalIgstAmount = parseFloat(totalGst) / 2;

    return {
      totalQuantity,
      totalGross,
      totalGst,
      total,
      totalSgstAmount,
      totalCgstAmount,
      totalIgstAmount,
    };
  }, [ItemListData]);

  const totalsJSX = (
    <>
      <div className="total-item">
        <strong>Total Quantity:</strong>
        <span>{totals.totalQuantity}</span>
      </div>
      <div className="total-item">
        <strong>Total Gross:</strong>
        <span>{totals.totalGross}</span>
      </div>
      <div className="total-item">
        <strong>Total Gst:</strong>
        <span>{totals.totalGst}</span>
      </div>
      <div className="total-item">
        <strong>Total:</strong>
        <span>{totals.total}</span>
      </div>
    </>
  );

  return { totals, totalsJSX };
};
