import React, { useEffect, useState } from "react";
import { Input, Modal, Table, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { useDispatch } from "react-redux";
import { addItem, removeAllItems } from "../state/slices/mainSlice";
import { BASE_URL } from "../state/api";
import { invoiceAddItem } from "../utilsTypes";

interface propsItem {
  docType: string;
  docData: Function;
  setIsSearch: Function;
}

const SearchDocComponent = (props: propsItem) => {
  const { docType, docData, setIsSearch } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  //   const BASE_URL = process.env.REACT_APP_BASE_URL;
  //   const STAGE = process.env.REACT_APP_ENV;
  const orgId: string = localStorage.getItem("orgId")!;

  useEffect(() => {
    setIsSearch(false);
  }, []);

  const handleSearch = async () => {
    const api = `${BASE_URL}/dev/fetchDocData?orgId=${encodeURIComponent(
      orgId
    )}&docType=${encodeURIComponent(docType)}`;
    // const api = `http://localhost:4000/dev/fetchDocData?orgId=${encodeURIComponent(orgId)}&docType=${encodeURIComponent(docType)}`;
    try {
      const response = await axios.get(api);
      setData(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleSelectItem = (record: any) => {
    dispatch(removeAllItems());
    setIsSearch(true);
    docData({
      ...record,
      ...(record.date && { date: moment(record.date) }),
      ...(record.PoDate && { PoDate: moment(record.PoDate) }),
      ...(record.salesInvoiceDate && {
        salesInvoiceDate: moment(record.salesInvoiceDate),
      }),
      ...(record.deliveryDueBy && {
        deliveryDueBy: moment(record.deliveryDueBy),
      }),
      ...(record.revisedOn && { revisedOn: moment(record.revisedOn) }),
      ...(record.supplierInvoiceDate && {
        supplierInvoiceDate: moment(record.supplierInvoiceDate),
      }),
    });
    if (docType != "PAYR") {
      record.ItemListData.forEach((item: invoiceAddItem, index: number) => {
        dispatch(addItem(item));
      });
    }

    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    { title: "User Code", dataIndex: "userCode", key: "userCode" },
    {
      title: "Payment Reciept NO.",
      dataIndex: "sk",
      key: "sk",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: any, record: any) => record.date.split("T")[0],
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Button type="primary" onClick={() => handleSelectItem(record)}>
          Select
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Button
        className="searchBtn"
        icon={<SearchOutlined />}
        onClick={handleSearch}
      >
        Search
      </Button>
      <Modal
        title="Select an Item"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Table
          columns={columns}
          dataSource={data}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{ x: true }}
        />
      </Modal>
    </div>
  );
};

export default SearchDocComponent;
