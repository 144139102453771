// import React from 'react';
// import './footer.scss';
// const Footer = () => {
//   return (
//     <div className="footer">
//       &copy; 2024 Powered By SamparkBindhu Solutions Pvt Limited | All Rights Reserved
//     </div>
//   );
// };
// export default Footer;

import React from "react";

import "./footer.scss";

import footerImg from "../assets/footerimage.jpg";
import { Col, Row, Typography } from "antd";
import {
  LinkedinFilled,
  FacebookFilled,
  TwitterOutlined,
} from "@ant-design/icons";

const { Title } = Typography;

const Footer = () => {
  return (
    <Row justify="space-evenly" className="app-footer-container">
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={4}
        xl={4}
        xxl={4}
        className="companyLgContainer"
      >
        <img className="image" src={footerImg} alt="footer" />
      </Col>
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={6}
        xl={6}
        xxl={6}
        className="addressContainer"
      >
        <Title className="addressTitle">Address</Title>
        <p className="addressPara">
          Samparkbindhu Solutions Pvt Ltd <br />
          C/o Phoenix WorkSpace, #C3, 1st floor Navaratna Gardens,
          Doddakallasandra, Bengaluru - 560062
        </p>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={4}
        lg={4}
        xl={4}
        xxl={4}
        className="linkNameContainer"
      >
        <Title className="linkTitle">Links</Title>
        <ul>
          <li>
            {/* <a href="/contactUs">AboutUs</a> */}
            <a>AboutUs</a>
          </li>
          <li>
            {/* <a href="/team">Team</a> */}
            <a>Team</a>
          </li>
          <li>
            {/* <a href="/updates">Updates</a> */}
            <a>Updates</a>
          </li>
          <li>
            {/* <a href="/legal">Legal</a> */}
            <a>Legal</a>
          </li>
        </ul>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={6}
        lg={6}
        xl={6}
        xxl={6}
        className="socialMediaContainer"
      >
        <Title className="socialTitle">Social Media</Title>
        <ul>
          <li>
            <FacebookFilled
              style={{ fontSize: "16px", color: "#08c" }}
              type="facebook"
            />
            <a>
              {/* <a href="https://www.facebook.com/gundlupet.murthy/" target="_blank"> */}
              FaceBook
            </a>
          </li>
          <li>
            <TwitterOutlined
              style={{ fontSize: "16px", color: "#08c" }}
              type="twitter"
            />
            <a>
              {/* <a href="https://twitter.com/samparkbindhu" target="_blank"> */}
              Twitter
            </a>
          </li>
          <li>
            <LinkedinFilled
              style={{ fontSize: "16px", color: "#08c" }}
              type="linkedin"
            />
            <a
              href="https://www.linkedin.com/company/samparkbindhu/"
              target="_blank"
            />
            <a>LinkedIn</a>
          </li>
        </ul>
      </Col>
    </Row>
  );
};

export default Footer;
