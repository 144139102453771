import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
export const handleGrnPdf = (pdfData) => {
    const generateHTMLContent = (pdfData) => {
        console.log("pdf", pdfData);
        const ItemListData = pdfData.ItemListData;
        const heading = 'Goods Reciept Note'
        const { totals } = pdfData;
        const tableRows = ItemListData.map(
            (item, index) => `
        <tr>
          <td>${index + 1}</td>
          <td>${item.name}</td>
          <td>${item.quantity}</td>
          <td></td>
          <td>${item.rate}</td>
          <td>${item.quantity * item.rate}</td>
        </tr>
      `
        ).join("");

        return `
      <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="grn.css">
    <title>Goods Receipt Note</title>
    <style>
        body {
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    color: #333;
    border: 2px solid black;
}
.header {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: 3px solid black;
}
.headingComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.heading {
    font-size: 15px;
    border-bottom: 3px solid black;
    padding: 10px;
    width: 96.5vw;
    text-align: center;
    margin: 0 auto;
}
.subHeading li {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 530;
    font-size: 30px;
    padding: 5px;
}
#subHeading1 {
    font-size: 20px;
    padding: 6px;
    font-weight: 520;
}
.secondRow {
    display: flex;
    border-bottom: 3px solid black;
    margin: 0;
}
.secondRowList {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px;
    font-size: 13px;
    margin-top: -20px;
    margin-bottom: 0;
    font-weight: 500;
}
.secondRowList li {
    padding: 2px;
    margin: 2px;
    font-weight:300;
}
.secondFirstCol {
    flex: 1;
    font-size: 25px;
    font-weight: 500;
    border-right: 3px solid black;
}
.secondFirstCol h6{
        display: flex;
        justify-content:left;
        align-items: left;
        border-bottom: 3px solid black;
        margin-top: 0;
        padding: 10px;
}
.secondSecondCol {
    flex: 1;
    border-right: 3px solid black;
    font-size: 25px;
    font-weight:700;
}
.secondSecondCol h6{
    display: flex;
    justify-content:right;
    align-items:right;
    border-bottom: 3px solid black;
    margin-top: 0;
    padding: 10px;
}
table{
    width: 100%;
    border-collapse: collapse;
}
th, td {
    border: 2px solid black;
    padding: 8px;
    text-align: left;
    font-weight: 900;
}
td {
    font-size: 13px;
    font-weight:700;
}
th {
    background-color: #fff;
    font-size: 14px;
    font-weight:900;
}
.specBlock {
    width: 40vw;
    font-size: 10px;
    margin: 10px auto;
    text-align: center;
    padding: 10px;
}
.specBlock strong {
    text-align: right;
    font-weight: 700;
    font-size:15px;
}
#firstcol{
    width:53.6%;
    
}
#secondcol{
    width:27%;
}
.secondRowList #secondRowname{
    font-weight: bold;
}
#total {
    text-align: right;
}
.mainsection {
    display: flex;
    border-bottom: 3px solid black;
    border-top:3px solid black;
    margin: 0;
}
h6 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 3px solid black;
    margin-top: 0;
    padding: 10px;
}
.middlesecondRowList h1 {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight:600;
    margin-left:100px;
}
.middlesecondRowList h3 {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px 10px;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0;
    font-weight:600;
    margin-left:200px;
}
.middlesecondRowList li {
    padding: 2px;
    margin: 2px;
}
.middleRowListDocument-Details {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 10px 10px;
    margin-top: -5px;
    margin-bottom: 0;
    font-size: 9px;
    margin-left:30px;
}
.middlesecondRowListDocument-Details li {
    padding: 4px;
    margin: 1px 0;
    font-size: 17px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.middlesecondFirstCol {
    flex: 2;
    font-size: 25px;
    font-weight: 500;
    border-right: 3px solid black;
}
.middlesecondSecondCol {
    flex:2;
    border-right: 3px solid black;
    font-size: 10px;
    font-weight: 500;
}
.middlesecondSecondCol  h6{
    font-size:15px;
    font-weight:600;
    padding: 10px 10px;
}
.middlesecondThirdCol {
    flex:2;
    font-size: 25px;
    font-weight: 500;
}
.middlesecondRowList {
    list-style: none; 
    padding: 0;
    margin: 0;
}
.middlesecondRowList h6 {
    margin:10px 0px;
    padding: 10px 10px;
    font-size: 1.5em;
    border-bottom: 2px solid #000; 
}
.transport-details-columns {
    display: flex;
    width: 100%;
    border-bottom: none;
}
.column {
    flex: 1;
    border-right: 1px solid #000;
}
.column:last-child {
    border-right: none; 
}
.column h6 {
    margin:0px;
    font-weight: 900;
    padding: 10px 10px;
    font-size: 1em; 
    border-bottom: 5px solid #000; 
    width: 100%; 
    box-sizing: border-box; 
}
.middlesecondRowList h5 {
    font-weight: 700;
    padding: 5px 5px;
    font-size: 1.2em;
    text-align: center; 
}
.column p {
    margin: 0;
    padding: 5px 0;
    border-bottom: 2px solid #000; 
    width: 10;
    font-weight: 800;
}
.middleRowListDocument-Details h2{
    margin-left:150px;
}
.middlesecondRowList h1{
    margin-left: 200px;
}
.table-container2 td,
.table-container2 th {
    border-radius: 0;
}
</style>
</head>
<body>
    <div class='header'>
       <div class='headingComponent'>
            <h3 class='heading'>
                ${heading}
            </h3>
            <h3 class='subHeading'>
                <li>${pdfData.organizationName}</li>
                <li id="subHeading1">${pdfData.address}</li>
                <li id="subHeading1">${pdfData.gstin}
                </li>
            </h3>
        </div>
    </div>
    <div class='secondRow'>
        <div class='secondFirstCol'>
            <h6>GRN No:${pdfData.number}</h6>
            <ul class="secondRowList">
                <li id="secondRowname">From:${pdfData.name || pdf.SupplierName
            }</li>
                <li>${pdfData.address}</li>
                <li>${pdfData.pincode}</li>
                <li>GSTIN:${pdfData.gstin}</li>
            </ul>
        </div>
        <div class='secondSecondCol'>
            <h6>Date : 10-10-2022</h6>
            <ul class="secondRowList">
                <li>Invoice No:${pdfData.SupplierInvoiceNo}</li>
                <li>Invoice Date:${pdfData.supplierinvoicedate}</li>
                <li>Reference:${pdfData.Reference}</li>
                <li>Supplier D.C. No:</li>
                <li>Supplier D.C. Date:</li>
                <li>L.R. No:</li>
                <li>Vehicle No:</li>
            </ul>
        </div>
  </div>
  <div class="table-container1">
    <table>
        <thead>
            <tr>
                <th>Sl. No</th>
                <th>Item Name</th>
                <th>Accepted Qty</th>
                <th>Rej. Qty</th>
                <th>Rate:</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
           ${tableRows}
           <tr class="total-row">
                <td id="total" colspan="1">Total:</td>
                 <td></td>
                 <td>${totals.totalQuantity}</td>
                <td></td>
                <td></td>
                <td>${totals.totalGross}</td>
            </tr>
        </tbody>
    </table>
  </div>
  <div class="table-container2">
    <table>
        <tbody>
            <tr>
                <td id="firstcol"></td>
                <td  id="secondcol">Packing Charges</td>
                <td >0.00</td>
            </tr>
            <tr><td></td>
                <td >Discount</td>
                <td >0.00</td>
             
            </tr>
            <tr><td></td>
                <td >Frieght</td>
                <td >0.00</td>
             
            </tr>
            <tr><td></td>
                <td >GST</td>
                <td >29,059.58</td>
             
            </tr>
            <tr><td></td>
                <td >Charges</td>
                <td >0.00</td>
             
            </tr>
            <tr><td></td>
                <td >TCS 0%</td>
                <td >0.00</td>
            </tr>
            <tr><td></td>
                <td >Deduction</td>
                <td >0.00</td>
             </tr>
            <tr><td></td>
                <td >Round Off</td>
                <td >0.00</td>
             </tr>
             <tr class="total-row">
                <td id="firstcol"></td>
                <td colspan="1">Net</td>
                <td>589.097.58</td>
            </tr>
        </tbody>
    </table>
</div>
<div class='mainsection'>
    <div class='middlesecondFirstCol'>
        <div class="middleRowListDocument-Details">
            <h2>Received By</h2>  
        </div>
    </div>
    <div class="middlesecondSecondCol">
       <div class="middlesecondRowList">
            <h6>Inspected By</h6>
             <h5></h5>
        </div>
    </div>
    <div class='middlesecondThirdCol'>
        <div class="middlesecondRowList">
            <h1>Approved By</h1>
        </div>
    </div>
</div>
</body>
</html>
`;
    };
    const htmlContent = generateHTMLContent(pdfData);
    const blob = new Blob([htmlContent], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const iframe = document.createElement("iframe");
    iframe.style.width = "320mm";
    iframe.style.height = "297mm";
    document.body.appendChild(iframe);
    iframe.onload = async () => {
        const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.open();
        iframeDocument.write(htmlContent);
        iframeDocument.close();
        const canvas = await html2canvas(iframeDocument.body, {
            scale: 2, // Increase scale to improve quality
            width: iframeDocument.body.scrollWidth,
            height: iframeDocument.body.scrollHeight,
        });
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(
            imgData,
            "PNG",
            0,
            0,
            210,
            (210 * canvas.height) / canvas.width
        );
        const pdfBlob = pdf.output("blob");
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Grn.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        document.body.removeChild(iframe);
        URL.revokeObjectURL(url);
        URL.revokeObjectURL(pdfUrl);
    };

    iframe.src = url;
};
