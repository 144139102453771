import React, { useEffect, useState, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Modal,
  Table,
  Select,
  message,
  Popconfirm,
  Row,
  Col,
  InputNumber,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import SearchDocComponent from "../searchDocComponent";
import { AppDispatch, RootState } from "../../state/store";
import {
  addItem,
  deleteItem,
  removeAllItems,
} from "../../state/slices/mainSlice";
import {
  saveSupplierOrder,
  saveUpdateInvoices,
  getSequenceNumber,
  getIsIgstApplied,
} from "../../state/api";
import { DeleteFilled } from "@ant-design/icons";
import AddUser from "../../profile/addUser";
import CropItemsTable from "../cropItemsTable";
import { useTotals } from "../invoicesTotals";
import SettingDateComp from "../settingDateComp";
import AddItemModal from "../addItemModal";
import AddUserModal from "../addUserModal";
import { handleBillOfSupplyPdf } from "../salesInvoices/generateBillSupplyPdf";
const { Option } = Select;

interface ItemType {
  key: string;
  name: string;
  category: string;
  variety: string;
  grade: string;
  gst: string;
  hsncode: string;
  uom: string;
}

interface Item {
  id: number;
  slno: number;
  Item_Code: string;
  itemName: string;
  quantity: number;
  rate: number;
  Uom: string;
  Amount: number;
  GST: string;
  GSTAmount: number;
  grossAmount: number;
}

const SupplierOrder: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [userSk, setUserSk] = useState("");
  const [userDetails, setUserDetails] = useState({
    phone: "",
    date: "",
    Name: "",
    address: "",
    PoDate: "",
    pincode: "",
    gstin: "",
    PoNo: "",
    Gst: "",
    customerCode: "",
    ItemListData: [],
  });
  const [number, setNumber] = useState("");
  const [isAddUser, setAddUser] = useState<boolean>(false);
  const [modalForm] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const [isSearch, setIsSearch] = useState(false);
  const [pdfData, setPdfData]: any = useState("");
  const [bsDate, setBsDate] = useState("");
  const [isReload, setIsReload] = useState(false);
  const [isIgst, setIsIgst] = useState(false);
  const [isAddItemVisible, setIsAddItemVisible] = useState(true);
  const [showPrintPdf, setShowPrintPdf] = useState(false);
  const userMasterList = useSelector(
    (state: RootState) => state.main.userMasterListData
  );
  const ItemListData = useSelector((state: RootState) => state.main.itemList);
  const suppliersDetails = useSelector(
    (state: RootState) => state.main.userProfileDetails.suppliers
  );
  const orgProfileData = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  const orgId: string = localStorage.getItem("orgId")!;
  const { totals, totalsJSX } = useTotals(ItemListData);
  const [form] = Form.useForm();
  const docType = "SUP";

  useEffect(() => {
    // Update form values whenever the form fields change
    form.setFieldsValue(userDetails);
    const payload = {
      userPincode: userDetails.pincode,
      orgPincode: orgProfileData.pincode,
    };
    if (userDetails.pincode && orgProfileData.pincode) {
      dispatch(getIsIgstApplied(payload, setIsIgst));
      setIsAddItemVisible(false);
    }
  }, [userDetails]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message);
      }
    };

    fetchSequenceNumber();
  }, [isReload]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleMasterCancel = () => {
    setIsUserModalVisible(false);
  };

  const handleModalFormSubmit = () => {
    modalForm.validateFields().then((values) => {
      const itemFromList = userMasterList.find(
        (item: ItemType) => item.name === values.name
      );
      if (itemFromList) {
        const itemAmount =
          parseFloat(values.quantity) * parseFloat(values.rate);
        const GstAmount =
          (parseFloat(itemFromList.gst.toString()) * itemAmount) / 100;
        const sgstPercent = parseFloat(itemFromList.gst) / 2;
        const cgstPercent = parseFloat(itemFromList.gst) / 2;
        const sgstAmount = GstAmount / 2;
        const cgstAmount = GstAmount / 2;
        const grossAmount = GstAmount + itemAmount;
        const igstPercent = parseFloat(itemFromList.gst) / 2;
        const igst = GstAmount / 2;
        const mergedItem: Item = {
          ...itemFromList,
          slno: ItemListData.length + 1,
          quantity: parseFloat(values.quantity),
          Amount: itemAmount,
          quantityInBags: values.QtyInBags,
          rate: values.rate,
          GSTAmount: GstAmount,
          grossAmount: grossAmount,
          uom: values.uom,
          ...(isIgst ? { igstPerc: igstPercent } : { sgstPerc: sgstPercent }),
          cgstPerc: cgstPercent,
          ...(isIgst ? { igst: igst } : { sgst: sgstAmount }),
          cgst: cgstAmount,
        };
        dispatch(addItem(mergedItem));
        message.success("Item Added Successfully");
        setIsModalVisible(false);
        modalForm.resetFields();
      } else {
        message.error("Item not found in master list");
      }
    });
  };

  const handleSelectItem = (record) => {
    const { sk, userType, institutionSubType, user, ...restRecord } = record;
    setUserDetails(restRecord);
    setIsUserModalVisible(false);
  };

  const confirm = (record: Item) => {
    dispatch(deleteItem(record.id));
    message.success("Click on Yes");
  };

  const dataSource = ItemListData.map((item, index) => ({
    ...item,
    key: item.key,
    slno: index + 1,
    Item_Code: item.Item_Code,
    name: item.name,
  }));

  const handleSaveSupplierOrder = async (values: any) => {
    const payLoad = {
      ...userDetails,
      ...values,
      ...totals,
      ItemListData: ItemListData,
      date: bsDate ? bsDate : values.date,
    };
    let sk = payLoad.sk;
    if (!isSearch) {
      sk = await dispatch(saveSupplierOrder(payLoad));
    } else {
      dispatch(saveUpdateInvoices(payLoad));
    }
    printPdf({ ...payLoad, sk });
    form.resetFields();
    dispatch(removeAllItems());
    setShowPrintPdf(true);
    message.success("All data saved successfully");
    setIsReload(true);
  };

  const printPdf = (payLoad) => {
    const payload2 = {
      orgProfileData,
      ...payLoad,
      docType: "Purchase order",
    };
    handleBillOfSupplyPdf(payload2);
    form.resetFields();
    setIsReload(false);
  };

  return (
    <div className="supplierOrder">
      <SearchDocComponent
        docType={"SUP"}
        docData={setUserDetails}
        setIsSearch={setIsSearch}
      />
      <Form form={form} onFinish={handleSaveSupplierOrder}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input placeholder="No" value={number} readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone",
                },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "phone number must be 10 digits",
                },
              ]}
            >
              <Input readOnly={true} placeholder="Phone Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SettingDateComp setBsDate={setBsDate} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Reference"
              name="reference"
              rules={[
                {
                  required: true,
                  message: "Please input the Reference",
                },
                {
                  pattern: /^[a-zA-Z\0-9s]+$/,
                  message: "Please Enter Reference",
                },
              ]}
            >
              <Input placeholder="Reference" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Revised On"
              name="revisedOn"
              rules={[
                {
                  required: true,
                  message: "Please select the Revised on date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Delivery Due By"
              name="deliveryDueBy"
              rules={[
                {
                  required: true,
                  message: "Please select the Delivery Due By date",
                },
              ]}
            >
              <DatePicker format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Order No"
              name="orderNo"
              rules={[
                {
                  required: true,
                  message: "Please enter the Order No",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please Enter Number only",
                },
              ]}
            >
              <Input placeholder="Order Number" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input readOnly={true} placeholder="User Code" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please Search",
                },
                {
                  pattern: /^[a-zA-Z\s]+$/,
                  message: "Please Search By Name only",
                },
              ]}
            >
              <Input readOnly={true} placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          disabled={isAddItemVisible}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          Add Item
        </Button>
        <Button
          disabled={isSearch ? true : false}
          onClick={() => {
            setIsUserModalVisible(true);
          }}
        >
          Add User
        </Button>
        <AddUserModal
          isUserModalVisible={isUserModalVisible}
          handleMasterCancel={handleMasterCancel}
          customersDetails={suppliersDetails}
          handleSelectItem={handleSelectItem}
        />
        <AddItemModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
          handleSaveForm2={handleModalFormSubmit}
          formTwo={modalForm}
          userMasterList={userMasterList}
        />
        <CropItemsTable
          dataSource={dataSource}
          doctype={false}
          SetDataSource={null}
          handleFunc={confirm}
        />
        <div className="totals">{totalsJSX}</div>
        <Button
          style={{ width: "150px" }}
          disabled={ItemListData.length > 0 ? false : true}
          htmlType="submit"
        >
          Save And Download
        </Button>
      </Form>
    </div>
  );
};
export default SupplierOrder;
