import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { ToWords } from "to-words";

export const handlePaymentRecieptPdf = (pdfData) => {
  const generateHTMLContent = (pdfData) => {
    const { organizationName, address, gstin } = pdfData.orgProfileData;
    const heading = pdfData.taxInvoice ? `TAX Invoice` : `BILL OF SUPPLY`;
    const toWords = new ToWords();
    let words = toWords.convert(pdfData.amountPaid);
    return `
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Receipt</title>
    <style>
        body {
            font-family: 'Arial', sans-serif;
            background-color: #fff;
            color: #333;
            border: 2px solid black;
            margin: 0;
            padding: 0;
        }
        .header {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-bottom: 3px solid black;
        }
        .headingComponent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .heading {
            font-size: 15px;
            border-bottom: 3px solid black;
            padding: 10px;
            width: 100vw;
            text-align: center;
            margin: 0 auto;
        }
        .subHeading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 10px;
        }
        .subHeading img {
            width: 130px; 
            height:80px; 
        }
        .text {
            flex: 1;
            text-align: center;
            font-size: 15px;
            list-style: none;
            padding: 5px;
        }
        #subHeading1 {
            font-size: 12px;
            padding: 6px;
            font-weight: 520;
            color: rgb(69, 57, 57);
        }
        .secondRow {
            display: flex;
            border-bottom: 3px solid black;
            margin: 0;
        }
        .secondRowList {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 10px;
            font-size: 13px;
            margin-top: -20px;
            margin-bottom: 0;
            font-weight: 500;
        }
        .secondRowList li {
            padding: 2px;
            margin: 2px;
            font-weight: 700;
            font-size:15px;
        }
        .secondFirstCol {
            flex: 6;
            font-size: 20px;
            font-weight: 500;
        }
        .secondFirstCol h6 {
            display: flex;
            justify-content: left;
            align-items: left;
            border-bottom: 3px solid black;
            margin-top: 0;
            padding: 10px;
        }
        .secondSecondCol {
            flex: 1;
            border-right: 3px solid black;
            font-size: 25px;
            font-weight: 700;
        }
        .secondSecondCol h6 {
            display: flex;
            justify-content: right;
            align-items: right;
            border-bottom: 3px solid black;
            margin-top: 0;
            padding: 10px;
        }
        table {
            width: 100%;
            border-collapse: collapse;
        }
        th, td {
            padding: 8px;
            text-align: left;
            font-size: larger;
            font-weight: 900;
        }
        td {
            font-size: 13px;
            font-weight: 700;
        }
        th {
            background-color: #fff;
            font-size: 14px;
            font-weight: 900;
        }
        .specBlock {
            width: 40vw;
            font-size: 10px;
            margin: 10px auto;
            text-align: center;
           
        }
        .specBlock strong {
            text-align: right;
            font-weight: 700;
            font-size: 15px;
        }
        #firstcol {
            width: 10%;
        }
        #secondcol {
            width: 27%;
        }
        .secondRowList #secondRowname {
            font-weight: bold;
        }
        #total {
            text-align: right;
        }
    </style>
</head>
<body>
    <div class='header'>
        <div class='headingComponent'>
            <h3 class='heading'>${heading}</h3>
            <div class='subHeading'>
                <div class="text">
                   <li>${organizationName}</li>
                    <li id="subHeading1">${address}</li>
                    <li id="subHeading1">${gstin}</li>
                </div>
            </div>
        </div>
    </div>
    <div class='secondRow'>
        <div class='secondFirstCol'>
            <h6>
             Receipt No:
                ${pdfData.sk}
            </h6>
            <ul class="secondRowList">
                <li>Received with Thanks from  ${pdfData.name}${pdfData.userCode}</li>
                <li>The Sum Of Rupees ${words} only</li>
                <li>Against Invoice No ${pdfData.salesInvoiceNo} as Advance Received towards supply of goods.</li>
            </ul>
        </div>
        <div class='secondFirstCol'>
            <h6>Date:${pdfData.date}</h6>
        </div>
    </div>
    <div class="table-container2">
        <table>
            <tbody>
                <tr>
                    <td>Rs/-${pdfData.amountPaid}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="bottomRow">
        <div class="specBlock">
            <div>
                <strong>This is a system-generated document, no need for signature</strong>
            </div>
        </div>
    </div>
</body>
</html>  `;
  };
  const htmlContent = generateHTMLContent(pdfData);

  const blob = new Blob([htmlContent], { type: "text/html" });
  const url = URL.createObjectURL(blob);

  const iframe = document.createElement("iframe");
  iframe.style.width = "320mm";
  iframe.style.height = "297mm";
  document.body.appendChild(iframe);

  iframe.onload = async () => {
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    iframeDocument.open();
    iframeDocument.write(htmlContent);
    iframeDocument.close();

    const canvas = await html2canvas(iframeDocument.body, {
      scale: 2, // Increase scale to improve quality
      width: iframeDocument.body.scrollWidth,
      height: iframeDocument.body.scrollHeight,
    });

    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(
      imgData,
      "PNG",
      0,
      0,
      210,
      (210 * canvas.height) / canvas.width
    );

    // Open PDF in new tab
    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl);

    // Trigger download
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = `${pdfData.sk}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    document.body.removeChild(iframe);
    URL.revokeObjectURL(url);
    URL.revokeObjectURL(pdfUrl);
  };

  iframe.src = url;
};
