import { Input, message, Popconfirm, PopconfirmProps, Table } from "antd";
import React from "react";
import { DeleteFilled } from "@ant-design/icons";

const cropItemsTable = ({ dataSource, doctype, SetDataSource, handleFunc }) => {
  const cancel: PopconfirmProps["onCancel"] = (e) => {
    message.error("Click on No");
  };

  const columnsOfItems = [
    { title: "SL.No", dataIndex: "slno", key: "slno" },
    { title: "Item Code", dataIndex: "Item_Code", key: "Item_Code" },
    { title: "Hsn Code", dataIndex: "hsncode", key: "hsncode" },
    { title: "ItemName", dataIndex: "name", key: "name" },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, record: any) =>
        doctype ? renderInput(text, record, "quantity") : record.quantity,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text: any, record: any) =>
        doctype ? renderInput(text, record, "rate") : record.rate,
    },
    {
      title: "UOM",
      dataIndex: "uom",
      key: "uom",
      render: (text: any, record: any) =>
        doctype ? renderInput(text, record, "Uom") : record.uom,
    },
    {
      title: "QtyInBags",
      dataIndex: "quantityInBags",
      key: "quantityInBags",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    { title: "GST %", dataIndex: "gst", key: "gst" },
    { title: "GST Amount", dataIndex: "GSTAmount", key: "GSTAmount" },
    { title: "Total", dataIndex: "grossAmount", key: "grossAmount" },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <>
          <Popconfirm
            title="Delete the task"
            onConfirm={() => handleFunc(record)}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <DeleteFilled />
          </Popconfirm>
        </>
      ),
    },
  ];

  const renderInput = (text: any, record: any, column: any) => (
    <Input
      value={text}
      onChange={(e) => handleInputChange(e, record, column)}
    />
  );

  const handleInputChange = (e: any, record: any, column: any) => {
    const value = column === "Uom" ? e.target.value : Number(e.target.value);
    const newData = dataSource.map((item: any) =>
      item.key === record.key
        ? {
            ...item,
            [column]: value,
            Amount:
              column !== "Uom"
                ? column === "quantity"
                  ? value * item.rate
                  : item.quantity * value
                : item.Amount,
            GSTAmount:
              column !== "Uom"
                ? column === "quantity"
                  ? (value * item.rate * item.gst) / 100
                  : (item.quantity * value * item.gst) / 100
                : item.GSTAmount,
          }
        : item
    );
    SetDataSource(newData);
  };

  return (
    <>
      <Table
        columns={columnsOfItems}
        dataSource={dataSource}
        pagination={{ pageSize: 8, position: ["bottomLeft"] }}
        scroll={{ x: 1350 }}
      />
    </>
  );
};

export default cropItemsTable;
