import React, { useEffect, useMemo, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Table,
  Modal,
  Select,
  InputNumber,
  Radio,
  message,
} from "antd";
import SearchDocComponent from "../searchDocComponent";
import { AppDispatch, RootState } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../state/slices/mainSlice";
import AddUser from "../../profile/addUser";
import {
  BASE_URL,
  fetchUserDetails,
  getRemainingAmount,
  getSequenceNumber,
  savePaymentReciept,
  saveUpdateInvoices,
} from "../../state/api";
import SettingDateComp from "../settingDateComp";
import moment from "moment";
import Search from "antd/es/transfer/search";
import { debounce } from "lodash";
import SelectInvoiceBtnSales from "./selectInvoiceBtnSales";
import { handlePaymentRecieptPdf } from "./paymentRecieptPdf";

interface PropsItem {
  taxInvoice: boolean;
}

interface ItemType {
  pk: string;
  itemName: string;
  sk: string;
  Amount: number;
  Category: string;
  Grade: string;
  grossAmount: number;
  GST: string;
  GSTAmount: number;
  HSN_Code: string;
  id: string;
  Item_Code: number;
  Produce: string;
  quantity: number;
  quantityInBags: string;
  rate: string;
  Source_Area: string;
  Uom: string;
  Variety: string;
}
const PaymentReceipt = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfData, setPdfData] = useState([]);
  const [userFormValues, setUserFormValues] = useState({
    phone: "",
    date: "",
    address: "",
    userCode: "",
    name: "",
    total: 0,
    remainingAmount: 0,
    amountPaid: 0,
    salesInvoiceNo: "",
    sk: "",
    salseInvoiceDate: "",
  });
  const [modalSrData, setModalSrData] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [bsDate, setBsDate] = useState("");
  const [inRemainingAmount, setInRemainingAmount] = useState(0);
  const [cropTotalAmount, setCropTotalAmount] = useState(0);
  const [showRemainingAmount, setShowRemainingAmount] = useState(0);
  const [isReload, setIsReload] = useState(false);
  const [isCalcRemAmount, setIsCalcRemAmount] = useState(0);
  const [showPaymentNumber, setShowPaymentNumber] = useState(0);
  const [form] = Form.useForm();
  const [formTwo] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const orgId: string = localStorage.getItem("orgId")!;
  const orgProfileData = useSelector(
    (state: RootState) => state.main.orgProfileDetails
  );
  const docType = "PAYR";

  useEffect(() => {
    dispatch(getUserDetails(orgId));
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue(userFormValues);
  }, [userFormValues]);

  useEffect(() => {
    setCropTotalAmount(userFormValues.total);
    // setInRemainingAmount(userFormValues.remainingAmount);
    const fetchRemainingAmount = async () => {
      try {
        await getRemainingAmount(
          orgId,
          userFormValues.salesInvoiceNo,
          setIsCalcRemAmount,
          setShowPaymentNumber,
          setShowRemainingAmount
        );
      } catch (error) {
        console.error("Error in fetchRemainingAmount:", error);
      }
    };

    if (isSearch) {
      fetchRemainingAmount();
    }
  }, [isSearch]);

  useEffect(() => {
    const fetchSequenceNumber = async () => {
      try {
        await getSequenceNumber(orgId, docType, form);
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchSequenceNumber();
  }, [isReload]);

  const handleModalFormSubmit = (record: any) => {
    const { date, number, ...restRecord } = record;
    form.setFieldsValue({
      ...restRecord,
      ...(date && { salesInvoiceDate: moment(date) }),
    });
    let myRRemainingAmount = record.remainingAmount ?? record.total;
    // let calculateAmount = 0;
    // if (record.returnAmount) {
    //   calculateAmount = myRRemainingAmount - Number(record.returnAmount);
    // } else {
    //   calculateAmount = myRRemainingAmount;
    // }
    setCropTotalAmount(record.total);
    setInRemainingAmount(myRRemainingAmount);
    setShowRemainingAmount(myRRemainingAmount);
    setIsModalVisible(false);
  };

  const handleSelectBs = async () => {
    setIsModalVisible(true);
    // const endPoint = `http://localhost:4000/dev/getSupplierPoData?orgId=${encodeURIComponent(
    //   orgId
    // )}`;
    const endPoint = `${BASE_URL}/dev/getSales?orgId=${encodeURIComponent(
      orgId
    )}`;
    const userDetails = await fetchUserDetails(`${endPoint}`);
    setModalSrData(userDetails);
  };

  const amountChangeFunc = (amount: number | null) => {
    if (amount !== null) {
      const myAmount: any =
        isSearch && showPaymentNumber > 1
          ? Number(isCalcRemAmount) - amount
          : isSearch && showPaymentNumber <= 1
          ? cropTotalAmount - amount
          : Number(inRemainingAmount) - amount;
      const roundedAmount = Math.round(myAmount);
      const formattedAmount = roundedAmount.toLocaleString();
      setShowRemainingAmount(roundedAmount);
    }
  };

  // Debounced version of amountChangeFunc using lodash
  const debouncedAmountChange = useMemo(
    () => debounce(amountChangeFunc, 500),
    [amountChangeFunc]
  );

  const saveAllDetails = async (values: any) => {
    const paymentStatus = showRemainingAmount != 0 ? "pending" : "completed";
    const remainingAmount =
      isCalcRemAmount + userFormValues.amountPaid - values.amountPaid;
    const { sk, ...restValues } = values;
    const payLoad = {
      ...(!isSearch && { ...restValues }),
      ...(isSearch && { ...values }),
      remainingAmount: isSearch ? remainingAmount : showRemainingAmount,
      update: isSearch ? true : false,
      total: cropTotalAmount,
      pk: orgId,
      date: bsDate,
      ...(!isSearch && { salesInvoiceNo: values.sk }),
      paymentStatus,
      amountPaid: values.amountPaid,
    };
    let pdfLoad = payLoad;
    if (!isSearch) {
      pdfLoad = await dispatch(savePaymentReciept(payLoad));
    } else {
      dispatch(saveUpdateInvoices(payLoad));
    }
    printPdf(pdfLoad);
    form.resetFields();
    setIsReload(true);
    setIsSearch(false);
    setUserFormValues({
      phone: "",
      date: "",
      address: "",
      userCode: "",
      name: "",
      total: 0,
      remainingAmount: 0,
      amountPaid: 0,
      salesInvoiceNo: "",
      sk: "",
      salseInvoiceDate: "",
    });
    message.success("All data saved successfully");
  };

  const printPdf = (pdfData) => {
    const payload = {
      ...pdfData,
      orgProfileData,
    };
    handlePaymentRecieptPdf(payload);
    form.resetFields();
    setIsReload(false);
  };

  const handleCancel = () => {
    formTwo.resetFields();
    setIsModalVisible(false);
  };

  return (
    <div className="paymentReceipt">
      <SearchDocComponent
        docType={"PAYR"}
        docData={setUserFormValues}
        setIsSearch={setIsSearch}
      />
      <Form
        layout="horizontal"
        initialValues={userFormValues}
        onFinish={saveAllDetails}
        form={form}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="No"
              name={isSearch ? "sk" : "number"}
              rules={[{ required: true, message: "Please input the number" }]}
            >
              <Input placeholder="No" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Sales Invoice No"
              name={isSearch ? "salesInvoiceNo" : "sk"}
              rules={[
                {
                  required: true,
                  message: "Please enter the Sales Invoice No!",
                },
              ]}
            >
              <Input placeholder="Enter Sales Invoice No" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <SettingDateComp setBsDate={setBsDate} />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Sales Invoice Date"
              name="salesInvoiceDate"
              rules={[
                {
                  required: true,
                  message: "Please select the Sales Invoice Date!",
                },
              ]}
            >
              <DatePicker disabled format="DD-MM-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                { required: true, message: "Please enter the Phone Number!" },
              ]}
            >
              <Input placeholder="Phone Number" readOnly={true} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                { required: true, message: "Please enter the Supplier Name!" },
              ]}
            >
              <Input
                className="customInput"
                disabled={false}
                placeholder={"Name"}
                readOnly={true}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="User Code"
              name="userCode"
              rules={[
                {
                  required: true,
                  message: "Please input the code",
                },
              ]}
            >
              <Input placeholder="Customer Code" readOnly={true} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Amount Paid"
              name="amountPaid"
              rules={[
                { required: true, message: "Please enter the Amount" },
                {
                  validator: (_, value) => {
                    // Determine the maximum allowed amount
                    const maxAmount = isSearch
                      ? isCalcRemAmount
                      : inRemainingAmount;

                    // Handle cases where value might be null/undefined
                    if (value === null || value === undefined) {
                      return Promise.resolve();
                    }

                    // Validate against maximum amount
                    if (value > maxAmount) {
                      return Promise.reject(
                        new Error(
                          `Amount must be less than or equal to ${maxAmount}`
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber
                placeholder="Enter Amount Paid"
                // min={0}
                // style={{ width: "100%" }}
                className="customInput"
                onChange={(value: number | null) =>
                  debouncedAmountChange(value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="paymentMode"
              label="Payment Mode"
              rules={[
                {
                  required: true,
                  message: "Please input Payment Mode!",
                },
              ]}
            >
              <Select
                defaultValue="paymentMode"
                style={{ width: 120 }}
                options={[
                  { value: "cash", label: "Cash" },
                  { value: "cheque", label: "Cheque" },
                  { value: "upi", label: "UPI" },
                ]}
                // mode='tags'//added
              />
            </Form.Item>
          </Col>
        </Row>
        <Button
          disabled={isSearch ? true : false}
          onClick={handleSelectBs}
          type="primary"
        >
          Select Invoice
        </Button>
        <SelectInvoiceBtnSales
          isModalVisible={isModalVisible}
          handleModalFormSubmit={handleModalFormSubmit}
          handleCancel={handleCancel}
          modalSrData={modalSrData}
        />
        <div className="totalamount">
          <strong>Total Amount:{cropTotalAmount}</strong>
          <strong>
            Remaining Amount:
            {showRemainingAmount}
          </strong>
        </div>
        <Button style={{ width: "150px" }} type="primary" htmlType="submit">
          Save And Download
        </Button>
        {/* <Button onClick={printPdf} style={{ width: "150px" }}>
          Download Receipt
        </Button> */}
      </Form>
    </div>
  );
};
export default PaymentReceipt;
